import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import DatePicker from 'components/shared/datepicker/DatePicker';
import { Playbook } from 'models/playbook';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

interface PlaybookFormDialogProps {
  playbookToEdit?: Playbook;
  open: boolean;
  onSubmit: (playbook: Playbook) => void;
  onCancel: () => void;
}

interface PlaybookForm {
  id: number | undefined;
  category: string | undefined;
  date: Date;
}

const PlaybookFormDialog: React.FC<PlaybookFormDialogProps> = ({
  playbookToEdit,
  open,
  onSubmit,
  onCancel
}) => {
  const validationSchema = Yup.object().shape({
    id: Yup.number(),
    category: Yup.string(),
    date: Yup.date().required('Date is required')
  });

  const textFieldStyle = {
    marginTop: '0px',
    marginBottom: '0px'
  };

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<PlaybookForm>({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    if (playbookToEdit) {
      const newDefaultValues = {
        id: playbookToEdit.id,
        category: playbookToEdit.category,
        date: playbookToEdit.date
      };

      reset(newDefaultValues);
    } else {
      resetForm();
    }
  }, [playbookToEdit, reset]);

  const resetForm = () => {
    reset({
      id: undefined,
      category: '',
      date: new Date()
    });
  };

  const submitForm = (data: PlaybookForm) => {
    const item = { ...data } as Playbook;
    resetForm();
    onSubmit(item);
  };

  return (
    <Dialog open={open} className="playbook-form" disableEscapeKeyDown>
      <form noValidate onSubmit={handleSubmit(submitForm)}>
        <DialogTitle>New Playbook</DialogTitle>
        <DialogContent sx={{ paddingTop: '20px !important' }}>
          <Grid2 container spacing={2}>
            <Grid2 xs={12}>
              <DatePicker
                control={control}
                name="date"
                errors={errors}
                label="Select date"
                placeholder="Select date"
              />
            </Grid2>
            <Grid2 xs={12}>
              <TextField
                placeholder="A Fancy title"
                label="Title"
                type="text"
                variant="standard"
                fullWidth
                {...register('category')}
                error={errors.category ? true : false}
                helperText={errors.category && errors.category?.message}
                style={textFieldStyle}
              />
            </Grid2>
          </Grid2>
        </DialogContent>

        <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button onClick={onCancel} variant="contained" color="gray" fullWidth>
              Cancel
            </Button>
            <Button variant="contained" type="submit" fullWidth>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PlaybookFormDialog;

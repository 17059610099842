import React from 'react';

interface CurrencyProps {
  price: number;
}

const Currency: React.FC<CurrencyProps> = ({ price }) => {
  return (
    <React.Fragment>
      {Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }).format(price)}
    </React.Fragment>
  );
};

export default Currency;

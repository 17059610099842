import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Favorite } from 'models/favorite';
import { Tag } from 'models/tag';
import favoriteService from 'services/favoriteService';

export interface FavoritesState {
  favorites: Favorite[];
}

const initialState: FavoritesState = {
  favorites: []
};

export const loadFavorites = createAsyncThunk<Favorite[], { symbol: string | null; tags: Tag[] }>(
  'favorite/load',
  async ({ symbol, tags }) => {
    const response = await favoriteService.loadAll(symbol, tags);
    return response.data;
  }
);

export const saveFavorite = createAsyncThunk<Favorite, { favorite: Favorite }>(
  'favorite/save',
  async ({ favorite }) => {
    const response = await favoriteService.save(favorite);
    return response.data;
  }
);

export const deleteFavorite = createAsyncThunk<{ deletedFavoriteId: number }, { item: Favorite }>(
  'favorite/delete',
  async ({ item }) => {
    const response = await favoriteService.delete(item);
    if (response.status === 200) {
      return { deletedFavoriteId: item.id! };
    }
    throw new Error('Failed to delete playbook item ' + item.id);
  }
);

export const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(saveFavorite.fulfilled, (state, action) => {
        const index = state.favorites.findIndex((favorite) => favorite.id === action.payload.id);
        if (index > -1) {
          state.favorites.splice(index, 1, action.payload);
        } else {
          state.favorites.push(action.payload);
        }
      })
      .addCase(loadFavorites.fulfilled, (state, action) => {
        state.favorites = action.payload;
      })
      .addCase(deleteFavorite.fulfilled, (state, action) => {
        const index = state.favorites.findIndex((c) => c.id === action.payload.deletedFavoriteId);
        if (index > -1) {
          state.favorites.splice(index, 1);
        }
      });
  }
});

export const selectFavorites = (state: { favorite: FavoritesState }) => state.favorite.favorites;

export default favoriteSlice.reducer;

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import DatePicker from 'components/shared/datepicker/DatePicker';
import { PlaybookItem } from 'models/playbookItem';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

interface PlaybookItemFormDialogProps {
  itemToEdit: PlaybookItem | null;
  open: boolean;
  onSubmit: (playbookItem: PlaybookItem) => void;
  onCancel: () => void;
}

interface PlaybookItemForm {
  id: number | undefined;
  symbol: string;
  date: Date;
}

const PlaybookItemFormDialog: React.FC<PlaybookItemFormDialogProps> = ({
  itemToEdit,
  open,
  onSubmit,
  onCancel
}) => {
  const validationSchema = Yup.object().shape({
    id: Yup.number(),
    symbol: Yup.string().required('Symbol is required'),
    date: Yup.date().required('Date is required')
  });

  const textFieldStyle = {
    marginTop: '0px',
    marginBottom: '0px'
  };

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<PlaybookItemForm>({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    if (itemToEdit) {
      const newDefaultValues = {
        id: itemToEdit.id,
        symbol: itemToEdit.symbol,
        date: moment(itemToEdit.date).toDate()
      };

      reset(newDefaultValues);
    } else {
      resetForm();
    }
  }, [itemToEdit, open]);

  const resetForm = () => {
    reset({
      id: undefined,
      symbol: undefined,
      date: new Date()
    });
  };

  const submitForm = (data: PlaybookItemForm) => {
    const item = { ...data } as PlaybookItem;
    resetForm();
    onSubmit(item);
  };

  return (
    <Dialog open={open} className="playbook-form" disableEscapeKeyDown>
      <form noValidate onSubmit={handleSubmit(submitForm)}>
        <DialogTitle>New Playbook Item</DialogTitle>
        <DialogContent sx={{ paddingTop: '20px !important' }}>
          <Grid2 container spacing={2}>
            <Grid2 xs={12}>
              <DatePicker
                control={control}
                name="date"
                errors={errors}
                label="Selecte date"
                placeholder="Selecte date"
              />
            </Grid2>
            <Grid2 xs={12}>
              <TextField
                placeholder="A Fancy title"
                label="Symbol"
                type="text"
                variant="standard"
                fullWidth
                {...register('symbol')}
                error={errors.symbol ? true : false}
                helperText={errors.symbol && errors.symbol?.message}
                style={textFieldStyle}
              />
            </Grid2>
          </Grid2>
        </DialogContent>

        <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button onClick={onCancel} variant="contained" color="gray" fullWidth>
              Cancel
            </Button>
            <Button variant="contained" color="primary" fullWidth type="submit">
              Save
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PlaybookItemFormDialog;

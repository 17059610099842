import { Alert, IconButton, Slide, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { createContext, useContext, useState } from 'react';

interface SnackMessage {
  message: string;
  type: 'success' | 'error' | 'warning';
}

interface SnackbarContextType {
  showSnackbar: (message: SnackMessage) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export function useSnackbar(): SnackbarContextType {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
}

export function SnackbarProvider({ children }: React.PropsWithChildren<{}>) {
  const [snackbar, setSnackbar] = useState<SnackMessage | null>(null);

  const showSnackbar = (message: SnackMessage) => {
    setSnackbar(message);
  };

  const handleClose = () => {
    setSnackbar(null);
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbar !== null}
        onClose={handleClose}
        autoHideDuration={6000}
        key={snackbar?.message}
        action={
          <React.Fragment>
            <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }>
        <Alert onClose={handleClose} severity={snackbar?.type}>
          {snackbar?.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
}

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useState } from 'react';

type FileUploadProps = {
  isOpen: boolean;
  onClose: Function;
  onUpload: Function;
  accept: string;
  title: string;
};

const GappersDialog: React.FC<FileUploadProps> = (props) => {
  const { isOpen, onClose, onUpload, accept, title } = props;
  const [dragActive, setDragActive] = useState(false);
  const [fileNames, setFileNames] = useState('');
  const [files, setFiles] = useState<FileList | null>(null);
  const inputRef = React.useRef(null);

  const handleClose = () => {
    onClose();
    reset();
  };

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  const handleFile = (files: FileList) => {
    const names: string[] = [];
    for (let i = 0; i < files.length; i++) {
      names.push(files[i].name);
    }
    setFileNames(names.join(','));
    setFiles(files);
  };

  const uploadFile = () => {
    if (files && files.length > 0) {
      onUpload(files);
    }
  };

  const reset = () => {
    setFileNames('');
    setFiles(null);
    if (inputRef.current) {
      (inputRef.current as any).value = null;
    }
  };

  return (
    <Dialog className="upload-dialog" open={isOpen} onClose={handleClose} disableEscapeKeyDown>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className="dialog-content" sx={{ minHeight: '200px' }}>
        <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
          <input
            ref={inputRef}
            type="file"
            accept={accept}
            id="input-file-upload"
            multiple={true}
            onChange={handleChange}
          />
          <label
            id="label-file-upload"
            htmlFor="input-file-upload"
            className={dragActive ? 'drag-active' : ''}>
            {fileNames === '' ? 'Drag and drop your file here or click to select file' : fileNames}
          </label>
          {dragActive && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}></div>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="contained" color="gray">
          Cancel
        </Button>
        <Button onClick={uploadFile} variant="contained" color="primary">
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GappersDialog;

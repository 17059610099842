import { Box, Typography } from '@mui/material';
import { BarChartModel } from 'models/barChartModel';
import React, { useEffect, useState } from 'react';
import statsService from 'services/statsService';
import TotalPnlChart from './TotalPnlChart';
import { TabPanelProps } from 'models/props/tabPanelProp';
import { selectCurrentAccount } from 'store/accountSlice';
import { useStoreSelector } from 'store/store';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

const DaysTimesTab: React.FC<TabPanelProps> = ({ value, index, ...other }) => {
  const [hourlyPnl, setHourlyPnl] = useState<BarChartModel[]>([]);
  const [hourlyTrades, setHourlyTrades] = useState<BarChartModel[]>([]);
  const [monthlyPnl, setMonthlyPnl] = useState<BarChartModel[]>([]);
  const [monthlyTrades, setMonthlyTrades] = useState<BarChartModel[]>([]);
  const [pnlByWeekday, setPnlByWeekday] = useState<BarChartModel[]>([]);
  const [tradesByWeekday, setTradesByWeekday] = useState<BarChartModel[]>([]);
  const account = useStoreSelector(selectCurrentAccount);

  useEffect(() => {
    statsService.cumulativeHourlyPnl().then((response) => {
      const data = response.data.map((d) => {
        return { key: d.timePeriod, value: d.pnl };
      });
      const trades = response.data.map((d) => {
        return { key: d.timePeriod, value: d.numberOfTrades };
      });
      setHourlyPnl(data);
      setHourlyTrades(trades);
    });

    statsService.cumulativeMonthlyPnl().then((response) => {
      const data = response.data.map((d) => {
        return { key: monthNames[parseInt(d.timePeriod, 10) - 1], value: d.pnl };
      });
      const trades = response.data.map((d) => {
        return { key: monthNames[parseInt(d.timePeriod, 10) - 1], value: d.numberOfTrades };
      });
      setMonthlyTrades(trades);
      setMonthlyPnl(data);
    });

    statsService.totalPnlByWeekday().then((response) => {
      const data = response.data.map((d) => {
        return { key: weekDays[parseInt(d.timePeriod, 10) - 1], value: d.pnl };
      });
      const trades = response.data.map((d) => {
        return { key: weekDays[parseInt(d.timePeriod, 10) - 1], value: d.numberOfTrades };
      });
      setTradesByWeekday(trades);
      setPnlByWeekday(data);
    });
  }, [account]);

  return (
    <div
      className={value === index ? 'report-tab' : ''}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ paddingTop: '10px' }}>
      {value === index && (
        <React.Fragment>
          <div>
            {hourlyPnl.length > 0 && (
              <TotalPnlChart
                data={hourlyTrades}
                title="Trade Distribution by Hour of Day"
                height={300}
                color="#0197f6b3"
              />
            )}
          </div>
          <div>
            {hourlyPnl.length > 0 && (
              <TotalPnlChart data={hourlyPnl} title="Performance by Hour of Day" height={300} />
            )}
          </div>

          <div>
            {monthlyTrades.length > 0 && (
              <TotalPnlChart
                data={monthlyTrades}
                title="Trade Distribution by Month of Year"
                color="#0197f6b3"
              />
            )}
          </div>
          <div>
            {monthlyPnl.length > 0 && (
              <TotalPnlChart data={monthlyPnl} title="Performance by Month of Year" />
            )}
          </div>

          <div>
            {tradesByWeekday.length > 0 && (
              <TotalPnlChart
                data={tradesByWeekday}
                title="Trade Distribution by Day of Week"
                color="#0197f6b3"
              />
            )}
          </div>

          <div>
            {pnlByWeekday.length > 0 && (
              <TotalPnlChart data={pnlByWeekday} title="Performance by Day of Week" />
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default DaysTimesTab;

import axios, { AxiosResponse } from 'axios';
import { AvgWinLoss } from 'models/avgWinLoss';
import { CumulativePnl } from 'models/cumulativePnl';
import { LargestGainLossPnl } from 'models/largestGainLoss';
import { TotalPnl } from 'models/totalPnl';
import { WeekPnl } from 'models/weekPnl';
import { WinLossRatio } from 'models/winLossRatio';

class StatsService {
  async avgWinLoss(): Promise<AxiosResponse<AvgWinLoss>> {
    return await axios.get<AvgWinLoss>(`${process.env.REACT_APP_API_URL}/stats/avg`);
  }

  async winLossRatio(): Promise<AxiosResponse<WinLossRatio>> {
    return await axios.get<WinLossRatio>(`${process.env.REACT_APP_API_URL}/stats/win-loss-ratio`);
  }

  async weeklyPnl(): Promise<AxiosResponse<WeekPnl[]>> {
    return await axios.get<WeekPnl[]>(`${process.env.REACT_APP_API_URL}/stats/week-pnl`);
  }

  async minMaxPnl(): Promise<AxiosResponse<LargestGainLossPnl>> {
    return await axios.get<LargestGainLossPnl>(
      `${process.env.REACT_APP_API_URL}/stats/min-max-pnl`
    );
  }

  async cumulativePnl(
    fromDate: moment.Moment,
    toDate: moment.Moment
  ): Promise<AxiosResponse<CumulativePnl[]>> {
    const queryParams = {
      from_date: fromDate.format('YYYY-MM-DD'),
      to_date: toDate.format('YYYY-MM-DD')
    };
    return await axios.get<CumulativePnl[]>(
      `${process.env.REACT_APP_API_URL}/stats/cumulative-pnl`,
      {
        params: queryParams
      }
    );
  }

  async pnlToDate(): Promise<AxiosResponse<CumulativePnl[]>> {
    return await axios.get<CumulativePnl[]>(`${process.env.REACT_APP_API_URL}/stats/pnl-to-date`);
  }

  async cumulativeHourlyPnl(): Promise<AxiosResponse<TotalPnl[]>> {
    return await axios.get<TotalPnl[]>(`${process.env.REACT_APP_API_URL}/stats/total-hourly-pnl`);
  }

  async cumulativeMonthlyPnl(): Promise<AxiosResponse<TotalPnl[]>> {
    return await axios.get<TotalPnl[]>(`${process.env.REACT_APP_API_URL}/stats/total-monthly-pnl`);
  }

  async totalPnlByWeekday(): Promise<AxiosResponse<TotalPnl[]>> {
    return await axios.get<TotalPnl[]>(
      `${process.env.REACT_APP_API_URL}/stats/total-pnl-by-weekday`
    );
  }

  async pnlByPriceRange(): Promise<AxiosResponse<TotalPnl[]>> {
    return await axios.get<TotalPnl[]>(`${process.env.REACT_APP_API_URL}/stats/pnl-by-price-range`);
  }
}

export default new StatsService();

import { HeaderCell } from 'components/table-header/tableHeader';

export const headerCells: HeaderCell[] = [
  {
    id: 'date',
    disablePadding: false,
    label: 'Date',
    numeric: false
  },
  {
    id: 'quantity',
    disablePadding: false,
    label: 'Quantity',
    numeric: true
  },
  {
    id: 'price',
    disablePadding: false,
    label: 'Price',
    numeric: true
  },
  {
    id: 'commission',
    disablePadding: false,
    label: 'Commission',
    numeric: true
  },
  {
    id: 'side',
    disablePadding: false,
    label: 'Side',
    numeric: false
  }
];

import React from 'react';
import './Sidebar.scss';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { NavLink } from 'react-router-dom';
import BookIcon from '@mui/icons-material/Book';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TimelineIcon from '@mui/icons-material/Timeline';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import RateReviewIcon from '@mui/icons-material/RateReview';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PivotTableChartIcon from '@mui/icons-material/PivotTableChart';
import FavoriteIcon from '@mui/icons-material/Favorite';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import BusinessIcon from '@mui/icons-material/Business';

const style = {
  width: '100%',
  bgcolor: 'background.transparent'
};

const Sidebar: React.FC = () => {
  return (
    <div className="sidebar">
      <List sx={style} component="nav">
        <ListItem disablePadding>
          <ListItemButton component={NavLink} to="/">
            <ListItemText primary="Dashboard" />
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <Divider />
        <ListItem disablePadding>
          <ListItemButton component={NavLink} to="trades">
            <ListItemText primary="Trades" />
            <ListItemIcon>
              <TimelineIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <Divider light />
        <ListItem disablePadding>
          <ListItemButton component={NavLink} to="journal">
            <ListItemText primary="Daily Journal" />
            <ListItemIcon>
              <AutoStoriesIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        {/* <ListItem disablePadding>
          <ListItemButton component={NavLink} to="playbook">
            <ListItemText primary="Playbook" />
            <ListItemIcon>
              <RateReviewIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem> */}
        <Divider light />
        <ListItem divider disablePadding>
          <ListItemButton component={NavLink} to="reports">
            <ListItemText primary="Reports" />
            <ListItemIcon>
              <SignalCellularAltIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem divider disablePadding>
          <ListItemButton component={NavLink} to="tickers">
            <ListItemText primary="Tickers" />
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem divider disablePadding>
          <ListItemButton component={NavLink} to="watchlist">
            <ListItemText primary="Watchlist" />
            <ListItemIcon>
              <FactCheckIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        {/* <ListItem divider disablePadding>
          <ListItemButton component={NavLink} to="runners">
            <ListItemText primary="Runners" />
            <ListItemIcon>
              <PivotTableChartIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem> */}
        <ListItem divider disablePadding>
          <ListItemButton component={NavLink} to="favorites">
            <ListItemText primary="Favorites" />
            <ListItemIcon>
              <FavoriteIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem divider disablePadding>
          <ListItemButton component={NavLink} to="notebook">
            <ListItemText primary="Notebook" />
            <ListItemIcon>
              <BookIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem divider disablePadding>
          <ListItemButton component={NavLink} to="gappers">
            <ListItemText primary="Gappers" />
            <ListItemIcon>
              <TrendingUpIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
        <ListItem divider disablePadding>
          <ListItemButton component={NavLink} to="filings">
            <ListItemText primary="Filings" />
            <ListItemIcon>
              <PictureAsPdfIcon />
            </ListItemIcon>
          </ListItemButton>
        </ListItem>
      </List>
    </div>
  );
};

export default Sidebar;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Journal } from 'models/journal';
import { JournalEntry } from 'models/journalEntry';
import journalService from 'services/journalService';

export interface TradingJournalState {
  journals: Journal[];
  currentJournal?: Journal;
  currentJournalEntry?: JournalEntry;
}

const initialState: TradingJournalState = {
  journals: [],
  currentJournal: undefined,
  currentJournalEntry: undefined
};

export const loadJournals = createAsyncThunk('journals/load', async () => {
  const response = await journalService.loadJournals();
  return response.data;
});

export const saveJournalNotes = createAsyncThunk<
  JournalEntry,
  { notes: string; entry: JournalEntry }
>('journals/saveJournalNotes', async ({ notes, entry }) => {
  const response = await journalService.saveJournalNotes(notes, entry);
  return response.data;
});

function replaceCurrentJournal(state: TradingJournalState, action: any) {
  const targetJournalIndex = state.journals.findIndex((journal) =>
    journal.journalEntries.some((entry) => entry.id === action.payload.id)
  );

  if (targetJournalIndex === -1) return;

  const targetEntryIndex = state.journals[targetJournalIndex].journalEntries.findIndex(
    (entry) => entry.id === action.payload.id
  );

  if (targetEntryIndex !== -1) {
    state.journals[targetJournalIndex].journalEntries[targetEntryIndex].notes =
      action.payload.notes;
  }

  if (state.currentJournal?.date === state.journals[targetJournalIndex].date) {
    state.currentJournal = state.journals[targetJournalIndex];
  }
}

export const tradingJournalSlice = createSlice({
  name: 'tradingJournal',
  initialState,
  reducers: {
    setCurrentJournal: (state, action) => {
      state.currentJournal = action.payload;
    },
    setCurrentJournalEntry: (state, action) => {
      state.currentJournalEntry = action.payload;
    },
    addNotesToCurrentJournalEntry: (state, action) => {
      if (state.currentJournalEntry) {
        state.currentJournalEntry.notes = action.payload;
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loadJournals.rejected, (state, action) => {
        console.error('Faleid to load journals', action.error);
      })
      .addCase(loadJournals.fulfilled, (state, action) => {
        state.journals = action.payload;
        if (state.journals && state.journals.length > 0) {
          state.currentJournal = state.journals[0];
          if (state.currentJournal.journalEntries.length > 0) {
            state.currentJournalEntry = state.currentJournal.journalEntries[0];
          }
        }
      })
      .addCase(saveJournalNotes.fulfilled, (state, action) => {
        replaceCurrentJournal(state, action);
      });
  }
});

export const selectJournals = (state: { tradingJournal: TradingJournalState }) =>
  state.tradingJournal.journals;
export const selectCurrentJournal = (state: { tradingJournal: TradingJournalState }) =>
  state.tradingJournal.currentJournal;
export const selectCurrentJournalEntry = (state: { tradingJournal: TradingJournalState }) =>
  state.tradingJournal.currentJournalEntry;

export const { setCurrentJournal, setCurrentJournalEntry, addNotesToCurrentJournalEntry } =
  tradingJournalSlice.actions;

export default tradingJournalSlice.reducer;

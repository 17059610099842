import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'providers/SnackbarProvider';
import Fab from 'components/shared/fab/Fab';
import Calendar from 'components/calendar/Calendar';
import { Box, Paper, Typography } from '@mui/material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
} from 'chart.js';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import { loadTradesByMonth, selectTradesByMonth, uploadTrades } from 'store/tradesSlice';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import WeeklyPnl from './WeeklyPnl';
import WinLossRatioChart from './WinLossRatioChart';
import AreaChart from 'components/dashboard/AreaChart';
import AvgWinLossChart from './AvgWinLoss';
import MinMaxPnlChart from './LargestGainLossChart';
import moment from 'moment';
import statsService from 'services/statsService';
import { CumulativePnl } from 'models/cumulativePnl';
import zoomPlugin from 'chartjs-plugin-zoom';
import { selectCurrentAccount } from 'store/accountSlice';
import UploadTradesDialog from 'components/shared/upload-trades/UploadTradesDialog';
import { DropDownOption } from 'components/shared/select-dropdown/SelectDropDown';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  zoomPlugin
);

interface CalendarState {
  month: number;
  year: number;
}

const Dashboard: React.FC = () => {
  const [pnl, setPnl] = useState<CumulativePnl[]>([]);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [calendarState, setCalendarState] = useState<CalendarState | null>(null);
  const tradesByMonth = useStoreSelector(selectTradesByMonth);
  const account = useStoreSelector(selectCurrentAccount);
  const dispatch = useStoreDispatch();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  useEffect(() => {
    let fromDate = moment().subtract(10, 'days');
    let toDate = moment();
    statsService
      .cumulativePnl(fromDate, toDate)
      .then((pnl) => {
        setPnl(pnl.data);
      })
      .catch(() => {
        showSnackbar({ message: 'Failed to load cumulative pnl', type: 'error' });
      });
  }, [account]);

  useEffect(() => {
    if (calendarState) {
      dispatch(loadTradesByMonth({ month: calendarState.month, year: calendarState.year }));
    }
  }, [calendarState, account]);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleUpload = (files: FileList, selectedOption: DropDownOption) => {
    dispatch(uploadTrades({ file: files[0], accountId: Number(selectedOption.key) }))
      .unwrap()
      .then(() => {
        showSnackbar({ message: 'Trades uploaded successfully.', type: 'success' });
        setTimeout(() => {
          navigate('/trades');
        }, 500);
      })
      .catch((error: any) => {
        showSnackbar({ message: 'Failed to upload trades', type: 'error' });
      });
  };

  const handleMonthChanged = (month: number, year: number) => {
    setCalendarState({ month: month + 1, year: year });
  };

  return (
    <React.Fragment>
      <Paper className="page-header" elevation={2}>
        <Typography variant="h6" sx={{ fontWeight: '500' }}>
          Dashboard
        </Typography>
      </Paper>

      <Box className="page-content" sx={{ height: '100%' }}>
        <Grid2 container spacing={2} sx={{ height: '400px' }}>
          <Grid2 xs={3}>
            <WeeklyPnl />
          </Grid2>

          <Grid2 xs={3}>
            <WinLossRatioChart />
          </Grid2>

          <Grid2 xs={3}>
            <AvgWinLossChart />
          </Grid2>

          <Grid2 xs={3}>
            <MinMaxPnlChart />
          </Grid2>

          <Grid2 xs={7}>
            <Calendar tradesByMonth={tradesByMonth} onMonthChanged={handleMonthChanged} />
          </Grid2>

          <Grid2 xs={5}>
            <AreaChart data={pnl} />
          </Grid2>
        </Grid2>

        <Fab onClick={handleOpenDialog}></Fab>
        <UploadTradesDialog
          accept=".csv,.json"
          title="Upload Trades"
          isOpen={isDialogOpen}
          onUpload={handleUpload}
          onClose={handleCloseDialog}
        />
      </Box>
    </React.Fragment>
  );
};

export default Dashboard;

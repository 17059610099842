import { TableHeader } from 'components/table-header/tableHeader';
import { headerCells } from './TradeDetailsTableHeader';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import { loadTradeById, selectCurrentTrade } from 'store/tradesSlice';
import {
  Box,
  Card,
  CardContent,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import moment from 'moment';
import Currency from 'components/currency/currency';
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Execution } from 'models/execution';
import executionsService from 'services/executionsService';
import { useSnackbar } from 'providers/SnackbarProvider';
import CandleStickChart from 'components/tradingview-charts/CandleStickChart';

const TradeDetails: React.FC = () => {
  const header = headerCells;
  const [executions, setExecutions] = useState<Execution[]>([]);
  const trade = useStoreSelector(selectCurrentTrade);
  const snackbar = useSnackbar();
  const dispatch = useStoreDispatch();
  const navigate = useNavigate();
  const { tradeId } = useParams();

  useEffect(() => {
    if (tradeId) {
      executionsService
        .loadExecutions(tradeId)
        .then((response) => {
          setExecutions(response);
        })
        .catch((error) => {
          snackbar.showSnackbar({ message: 'Failed to load executions', type: 'error' });
        });
    }

    if (!trade && tradeId) {
      dispatch(loadTradeById(Number(tradeId)));
    }
  }, []);

  const handleBackButton = () => {
    navigate('/trades');
  };

  return (
    <React.Fragment>
      <Paper className="page-header trades" elevation={2}>
        <IconButton onClick={handleBackButton} aria-label="back">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" sx={{ fontWeight: '500', flex: 1 }}>
          Trade Details: {trade?.symbol}
        </Typography>
      </Paper>

      <Box
        sx={{ display: 'flex', flexDirection: 'column !important', gap: '10px' }}
        className="page-content">
        {executions.length > 0 && trade && (
          <CandleStickChart symbol={trade.symbol} date={trade.date} height="500px" />
        )}

        <Card sx={{ width: '100%' }}>
          <CardContent>
            <TableContainer>
              <Table size="medium">
                <TableHeader headerCells={header} hasActionColumn={false} />
                <TableBody>
                  {executions &&
                    executions.map((execution) => {
                      const formattedDate = moment(execution.date).format('YYYY-MM-DD HH:mm:ss');
                      return (
                        <TableRow key={execution.id} hover>
                          <TableCell>{formattedDate}</TableCell>
                          <TableCell align="right">{execution.quantity}</TableCell>
                          <TableCell align="right">
                            <Currency price={execution.price}></Currency>
                          </TableCell>
                          <TableCell align="right">
                            <Currency price={execution.commission}></Currency>
                          </TableCell>
                          <TableCell>{execution.side}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    </React.Fragment>
  );
};

export default TradeDetails;

import { CandlestickData, HistogramData, LineData } from 'lightweight-charts';
import { ChartData } from 'models/chart/chartData';
import { Execution } from 'models/execution';
import { DateUtils } from 'utils.ts/DateUtils';

class CandlestickDataMapper {
  mapCandlestickData = (chartData: ChartData[]) => {
    return chartData?.map((data) => {
      return {
        close: data.close,
        high: data.high,
        low: data.low,
        open: data.open,
        time: DateUtils.toEasternTimeZone(data.time)
      } as CandlestickData;
    });
  };

  mapVolumeData = (chartData: ChartData[]) => {
    return chartData?.map((data) => {
      return {
        time: DateUtils.toEasternTimeZone(data.time),
        value: data.volume,
        color: data.open > data.close ? '#ef5350' : '#26a69a'
      } as HistogramData;
    });
  };

  mapExecutions = (executions: Execution[]) => {
    const uniqueExecutions = executions?.filter((item, index, array) => {
      return array.findIndex((t) => t.date === item.date) === index;
    });
    return uniqueExecutions?.map((execution) => {
      return {
        time: DateUtils.isoToTimestamp(execution.date),
        value: execution.price
      } as LineData;
    });
  };
}

export default new CandlestickDataMapper();

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Filing } from 'models/filings';
import { FilingsOverview } from 'models/filingsOverview';
import filingsService from 'services/filingsService';
import { RootState } from './store';
import { FilingDocument } from 'models/filingDocument';
import { Message } from 'models/message';
import { SecTicker } from 'models/secTickers';

export interface FilingsState {
  tickers: SecTicker[];
  selectedTicker: SecTicker | null;
  filingsOverview: FilingsOverview | null;
  isLoading: boolean;
  filingDocument: FilingDocument | null;
  messages: Message[];
  isMessageLoading: boolean;
}

const initialState: FilingsState = {
  tickers: [],
  filingsOverview: null,
  isLoading: false,
  selectedTicker: null,
  filingDocument: null,
  messages: [],
  isMessageLoading: false
};

export const loadTickers = createAsyncThunk<
  SecTicker[],
  {
    filter: string;
  }
>('tickers/load', async ({ filter }) => {
  const response = await filingsService.loadTickers(filter);
  return response.data;
});

export const loadMessages = createAsyncThunk<Message[], void, { state: RootState }>(
  'filings/load-messages',
  async (_, { getState }) => {
    const ticker = getState().filings.selectedTicker!.ticker;
    const response = await filingsService.loadMessages(ticker);
    return response.data;
  }
);

export const loadFilings = createAsyncThunk<
  FilingsOverview,
  {
    ticker: SecTicker;
  }
>('filings/load', async ({ ticker }) => {
  const response = await filingsService.loadFilings(ticker);
  return response.data;
});

export const getFiling = createAsyncThunk<
  FilingDocument,
  {
    filing: Filing;
  },
  { state: RootState }
>('tickers/filing/load', async ({ filing }, { getState }) => {
  const ticker = getState().filings.selectedTicker;
  if (!ticker) {
    throw new Error(`No Ticker selected`);
  }
  const response = await filingsService.getFiling(ticker, filing);
  return response.data;
});

export const summarizeCurrentFiling = createAsyncThunk<Message[], void, { state: RootState }>(
  'tickers/summarize-annual-quanrterly',
  async (_, { getState }) => {
    const filing = getState().filings.filingDocument;
    if (!filing) {
      throw new Error(`No Filing selected`);
    }
    const response = await filingsService.summarize(filing.filingId);
    return response.data;
  }
);

export const summarize8KFiling = createAsyncThunk<Message[], void, { state: RootState }>(
  'tickers/summarize-8K',
  async (_, { getState }) => {
    const filing = getState().filings.filingDocument;
    if (!filing) {
      throw new Error(`No Filing selected`);
    }
    const response = await filingsService.summarize8K(filing.filingId);
    return response.data;
  }
);
export const summarize10Bullets = createAsyncThunk<Message[], void, { state: RootState }>(
  'tickers/summarize-10Bullets',
  async (_, { getState }) => {
    const filing = getState().filings.filingDocument;
    if (!filing) {
      throw new Error(`No Filing selected`);
    }
    const response = await filingsService.summarize10Bullets(filing.filingId);
    return response.data;
  }
);
export const summarizeS3Filing = createAsyncThunk<Message[], void, { state: RootState }>(
  'tickers/summarizeS3Filing',
  async (_, { getState }) => {
    const filing = getState().filings.filingDocument;
    if (!filing) {
      throw new Error(`No Filing selected`);
    }
    const response = await filingsService.summarizeS3Filing(filing.filingId);
    return response.data;
  }
);

const filingsSlice = createSlice({
  name: 'filings',
  initialState,
  reducers: {
    setSelectedTicker: (state, action) => {
      state.selectedTicker = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loadTickers.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(loadTickers.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(loadTickers.fulfilled, (state, action) => {
        state.tickers = action.payload;
        state.isLoading = false;
      })
      .addCase(loadFilings.pending, (state, action) => {
        state.isLoading = true;
        state.filingsOverview = null;
      })
      .addCase(loadFilings.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(loadFilings.fulfilled, (state, action) => {
        state.filingsOverview = action.payload;
        state.isLoading = false;
      })
      .addCase(getFiling.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getFiling.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getFiling.fulfilled, (state, action) => {
        state.filingDocument = action.payload;
        state.isLoading = false;
      })
      .addCase(loadMessages.pending, (state, action) => {
        state.isMessageLoading = true;
      })
      .addCase(loadMessages.rejected, (state, action) => {
        state.isMessageLoading = false;
      })
      .addCase(loadMessages.fulfilled, (state, action) => {
        state.messages = action.payload;
        state.isMessageLoading = false;
      })
      .addCase(summarizeCurrentFiling.pending, (state, action) => {
        state.isMessageLoading = true;
      })
      .addCase(summarizeCurrentFiling.rejected, (state, action) => {
        state.isMessageLoading = false;
      })
      .addCase(summarizeCurrentFiling.fulfilled, (state, action) => {
        for (let message of action.payload) {
          const index = state.messages.findIndex((m) => m.content === message.content);
          if (index !== -1) {
            state.messages.push(message);
          }
        }
        state.isMessageLoading = false;
      })
      .addCase(summarize8KFiling.pending, (state, action) => {
        state.isMessageLoading = true;
      })
      .addCase(summarize8KFiling.rejected, (state, action) => {
        state.isMessageLoading = false;
      })
      .addCase(summarize8KFiling.fulfilled, (state, action) => {
        for (let message of action.payload) {
          const index = state.messages.findIndex((m) => m.content === message.content);
          if (index === -1) {
            state.messages.push(message);
          }
        }
        state.isMessageLoading = false;
      })
      .addCase(summarize10Bullets.pending, (state, action) => {
        state.isMessageLoading = true;
      })
      .addCase(summarize10Bullets.rejected, (state, action) => {
        state.isMessageLoading = false;
      })
      .addCase(summarize10Bullets.fulfilled, (state, action) => {
        for (let message of action.payload) {
          const index = state.messages.findIndex((m) => m.content === message.content);
          if (index === -1) {
            state.messages.push(message);
          }
        }
        state.isMessageLoading = false;
      })
      .addCase(summarizeS3Filing.pending, (state, action) => {
        state.isMessageLoading = true;
      })
      .addCase(summarizeS3Filing.rejected, (state, action) => {
        state.isMessageLoading = false;
      })
      .addCase(summarizeS3Filing.fulfilled, (state, action) => {
        for (let message of action.payload) {
          const index = state.messages.findIndex((m) => m.content === message.content);
          if (index === -1) {
            state.messages.push(message);
          }
        }
        state.isMessageLoading = false;
      });
  }
});

export const selectTickers = (state: { filings: FilingsState }) => state.filings.tickers;
export const selectMessages = (state: { filings: FilingsState }) => state.filings.messages;
export const selectCurrentTicker = (state: { filings: FilingsState }) =>
  state.filings.selectedTicker;
export const selectFilings = (state: { filings: FilingsState }) => state.filings.filingsOverview;
export const selectIsTickersLoading = (state: { filings: FilingsState }) => state.filings.isLoading;
export const selectIsMessagesLoading = (state: { filings: FilingsState }) =>
  state.filings.isMessageLoading;
export const selectFilingDocument = (state: { filings: FilingsState }) =>
  state.filings.filingDocument;

export const { setSelectedTicker } = filingsSlice.actions;

export default filingsSlice.reducer;

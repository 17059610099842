import styled from '@emotion/styled';
import { Paper } from '@mui/material';
import { ReactNode } from 'react';

type CardHeaderProps = {
  children: ReactNode;
};

const CardHeader: React.FC<CardHeaderProps> = ({ children }: { children: ReactNode }) => {
  const Header = styled(Paper)(() => ({
    backgroundColor: '#0f8aa9',
    padding: 5,
    color: 'white',
    borderRadius: '4px 4px 0 0'
  }));
  return <Header className="card-header">{children}</Header>;
};

export default CardHeader;

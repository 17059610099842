import axios, { AxiosResponse } from 'axios';
import { Favorite } from 'models/favorite';
import { Tag } from 'models/tag';

class FavoriteService {
  async loadAll(symbol: string | null, tags: Tag[]): Promise<AxiosResponse<Favorite[]>> {
    const tagIds = tags.map((tag) => tag.id);
    const queryParams = {
      symbol: symbol,
      tags: tagIds
    };
    return await axios.get(`${process.env.REACT_APP_API_URL}/favorite`, { params: queryParams });
  }

  async save(favorite: Favorite): Promise<AxiosResponse<Favorite>> {
    if (favorite.id) {
      return await axios.put(`${process.env.REACT_APP_API_URL}/favorite/${favorite.id}`, {
        favorite: favorite
      });
    } else {
      return await axios.post(`${process.env.REACT_APP_API_URL}/favorite`, {
        favorite: favorite
      });
    }
  }

  async delete(favorite: Favorite): Promise<AxiosResponse<void>> {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/favorite/${favorite.id}`);
  }
}

export default new FavoriteService();

import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import CandleStickChart from './CandleStickChart';

interface FullScreenChartProps {
  open: boolean;
  date: Date;
  symbol: string;
  onClose: () => void;
}
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenChart: React.FC<FullScreenChartProps> = ({ open, date, symbol, onClose }) => {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      sx={{ zIndex: 1200 }}>
      <CandleStickChart
        symbol={symbol}
        date={date}
        isFullScreenButtonVisible={false}
        height="48%"
      />
      <CandleStickChart
        symbol={symbol}
        date={date}
        isFullScreenButtonVisible={false}
        height="48%"
        defaultInterval="1d"
      />
    </Dialog>
  );
};

export default FullScreenChart;

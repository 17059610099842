import { Action, Middleware, ThunkDispatch, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HotStock } from 'models/hotStock';
import hotStocksService from 'services/hotStocksService';
import { WatchlistState, loadWatchlist } from './watchlistSlice';
import { RootState } from './store';

export interface HotStocksState {
  hotStocks: HotStock[];
}

const initialState: HotStocksState = {
  hotStocks: []
};

export const loadHotStocks = createAsyncThunk<HotStock[]>('hotStocks/load', async () => {
  const response = await hotStocksService.getAll();
  return response.data;
});

export const createHotStock = createAsyncThunk<HotStock, { symbol: string; date?: Date }>(
  'hotStocks/create',
  async ({ symbol, date }) => {
    const hotStock: HotStock = { symbol: symbol, date: date ? date : new Date() };
    const response = await hotStocksService.create(hotStock);
    return response.data;
  }
);

export const deleteHotStock = createAsyncThunk<HotStock, HotStock>(
  'hotStocks/delete',
  async (hotStock, thunkApi) => {
    const response = await hotStocksService.delete(hotStock.id!);
    if (response.status === 200) {
      return hotStock;
    }
    return thunkApi.rejectWithValue(response.status);
  }
);

const hotStocksSlice = createSlice({
  name: 'hotStocks',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadHotStocks.fulfilled, (state, action) => {
        state.hotStocks = action.payload;
      })
      .addCase(deleteHotStock.fulfilled, (state, action) => {
        state.hotStocks = state.hotStocks.filter((h) => h.id !== action.payload.id);
      });
  }
});
export const hotStocksMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  let result = next(action);

  if (action.type === createHotStock.fulfilled.type) {
    (storeAPI.dispatch as ThunkDispatch<RootState, void, Action>)(loadHotStocks());
    (storeAPI.dispatch as ThunkDispatch<RootState, void, Action>)(loadWatchlist());
  }

  if (action.type === deleteHotStock.fulfilled.type) {
    (storeAPI.dispatch as ThunkDispatch<RootState, void, Action>)(loadWatchlist());
  }

  return result;
};

export const selectHotStocks = (state: { hotStocks: HotStocksState }) => state.hotStocks.hotStocks;

export default hotStocksSlice.reducer;

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { HeaderCell } from 'components/table-header/tableHeader';
import { Filing } from 'models/filings';
import moment from 'moment';
import { useState } from 'react';
import { useStoreDispatch } from 'store/store';
import { getFiling } from 'store/filingsSlice';
import { FilingSection } from 'models/filingSections';
import FilingDialog from './FilingDialog';

interface FilingsTableProps {
  title: string;
  filings?: Filing[];
}

const FilingsTable: React.FC<FilingsTableProps> = ({ title, filings }) => {
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedFiling, setSelectedFiling] = useState<Filing | null>(null);
  const dispatch = useStoreDispatch();

  const headerCells: readonly HeaderCell[] = [
    {
      id: 'form',
      disablePadding: false,
      label: 'Form',
      numeric: false
    },
    {
      id: 'date',
      disablePadding: false,
      label: 'Filed At',
      numeric: false
    }
  ];

  const handleChangePage = (event: any, value: number) => {
    setPage(value);
  };

  const handleOpenDialog = (filing: Filing) => {
    setSelectedFiling(filing);
    dispatch(getFiling({ filing: filing }));
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <Paper elevation={2} sx={{ width: '100%' }} key={title}>
      <div
        className="filings-card-header"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid gray',
          padding: '10px 5px'
        }}>
        <Typography variant="h6" sx={{ fontWeight: '500', fontSize: '18px' }}>
          {title}
        </Typography>
      </div>
      <div className="filings-content">
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {headerCells.map((cell) => (
                  <TableCell
                    key={cell.id}
                    align={cell.numeric ? 'right' : 'left'}
                    padding={cell.disablePadding ? 'none' : 'normal'}>
                    {cell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filings &&
                filings
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((filing) => {
                    return (
                      <TableRow
                        hover
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleOpenDialog(filing)}>
                        <TableCell>{filing.form}</TableCell>
                        <TableCell>{moment(filing.filingDate).format('DD.MM.YYYY')}</TableCell>
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={filings?.length || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
        />
      </div>

      {open && <FilingDialog open={open} close={handleCloseDialog} filing={selectedFiling} />}
    </Paper>
  );
};

export default FilingsTable;

import axios, { AxiosResponse } from 'axios';
import { Ticker } from 'models/ticker';
import Trade from 'models/trade';
import { TradesByMonth } from 'models/tradeByMonth';

class TickerService {
  async uploadTrades(file: File, accountId: number): Promise<AxiosResponse<Trade[]>> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('accountId', String(accountId));
    return await axios.post(`${process.env.REACT_APP_API_URL}/trade/upload`, formData);
  }

  async save(ticker: Ticker): Promise<AxiosResponse<Ticker>> {
    const payload = {
      ticker: ticker
    };
    return await axios.put(`${process.env.REACT_APP_API_URL}/ticker/${ticker.id}`, payload);
  }

  async create(symbol: string): Promise<AxiosResponse<Ticker>> {
    const payload = {
      ticker: symbol
    };
    return await axios.post(`${process.env.REACT_APP_API_URL}/ticker`, payload);
  }

  async loadTickers(symbol?: string, searchString?: string): Promise<AxiosResponse<Ticker[]>> {
    const queryParams = {
      filter: symbol,
      searchString: searchString
    };
    return await axios.get(`${process.env.REACT_APP_API_URL}/ticker`, {
      params: queryParams
    });
  }

  async delete(ticker: Ticker): Promise<AxiosResponse<void>> {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/ticker/${ticker.id}`);
  }
}

export default new TickerService();

import { Paper } from '@mui/material';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { LargestGainLossPnl } from 'models/largestGainLoss';
import { LoadingState } from 'models/loadingState';
import { useSnackbar } from 'providers/SnackbarProvider';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import statsService from 'services/statsService';
import { selectCurrentAccount } from 'store/accountSlice';
import { useStoreSelector } from 'store/store';

const defaultData = {
  labels: ['No Data'],
  datasets: [
    {
      data: [1],
      backgroundColor: ['#cccccc'],
      borderWidth: 0
    }
  ]
};

const MinMaxPnlChart: React.FC = () => {
  const [stats, setStats] = useState<LargestGainLossPnl | null>();
  const [state, setState] = useState<LoadingState>(LoadingState.LOADING);
  const [data, setData] = useState<any>(null);
  const account = useStoreSelector(selectCurrentAccount);
  const snackbar = useSnackbar();

  const cardStyle: CSSProperties = {
    padding: '15px',
    height: '100px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative'
  };

  const options = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            return `$ ${context.formattedValue}`;
          }
        }
      }
    }
  };

  useEffect(() => {
    statsService
      .minMaxPnl()
      .then((response) => {
        setState(LoadingState.SUCCEEDED);
        if (response.data) {
          const data = {
            labels: ['Largest Gain', 'Largest Loss'],
            datasets: [
              {
                data: [response.data.win, response.data.loss],
                backgroundColor: ['rgb(41 171 135 / 92%)', 'rgba(234, 60, 83, 0.9)'],
                borderColor: ['rgb(41 171 135 / 92%)', 'rgba(234, 60, 83, 0.9)'],
                borderWidth: 1
              }
            ]
          };
          setStats(response.data);
          setData(data);
        } else {
          setData(defaultData);
        }
      })
      .catch((err) => {
        setState(LoadingState.FAILED);
        setData(defaultData);
        snackbar.showSnackbar({
          message: 'Failed to load largest gain/loss chart',
          type: 'error'
        });
      });
  }, [account]);

  return (
    <Paper className="win-percent" elevation={2} sx={cardStyle}>
      {state === LoadingState.LOADING && <LoadingSpinner open={state === LoadingState.LOADING} />}
      {state === LoadingState.SUCCEEDED && (
        <React.Fragment>
          <div className="left">
            <div className="title" style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="label">Largest gain: </span>
              <span className="value">{`$ ${stats ? stats?.win.toFixed(2) : 0}`}</span>
            </div>

            <div className="title" style={{ display: 'flex', flexDirection: 'column' }}>
              <span className="label">Largest loss: </span>
              <span className="value">{`$ ${stats ? stats?.loss.toFixed(2) : 0}`}</span>
            </div>
          </div>
          <div className="chart">
            {data && <Pie data={data} options={options} width={100} height={100} />}
          </div>
        </React.Fragment>
      )}
    </Paper>
  );
};

export default MinMaxPnlChart;

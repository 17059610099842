import axios, { AxiosResponse } from 'axios';
import { PlaybookItem } from 'models/playbookItem';

class PlaybookItemService {
  async save(item: PlaybookItem, playbookId: number): Promise<AxiosResponse<PlaybookItem>> {
    try {
      if (item.id) {
        return await axios.put(`${process.env.REACT_APP_API_URL}/playbook/item/${item.id}`, {
          item
        });
      } else {
        return await axios.post(`${process.env.REACT_APP_API_URL}/playbook/${playbookId}/item`, {
          item
        });
      }
    } catch (error) {
      throw error;
    }
  }

  async deleteItem(item: PlaybookItem): Promise<AxiosResponse<void>> {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/playbook/item/${item.id}`);
  }
}

export default new PlaybookItemService();

import { Accordion, Box, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './DailyJournal.scss';
import { JournalEntry } from 'models/journalEntry';
import AppAccordionSummary from './AppAccordionSummary';
import AppAccordionContent from './AppAccordionContent';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import {
  loadJournals,
  saveJournalNotes,
  selectCurrentJournal,
  selectCurrentJournalEntry,
  setCurrentJournalEntry
} from 'store/tradingJournalSlice';
import JournalList from './JournalList';
import { useSnackbar } from 'providers/SnackbarProvider';
import Currency from 'components/currency/currency';
import { selectCurrentAccount } from 'store/accountSlice';

const DailyJournal: React.FC = () => {
  const selectedJournEntry = useStoreSelector(selectCurrentJournalEntry);
  const selectedJournal = useStoreSelector(selectCurrentJournal);
  const account = useStoreSelector(selectCurrentAccount);
  const snackbar = useSnackbar();
  const dispatch = useStoreDispatch();

  useEffect(() => {
    dispatch(loadJournals());
  }, [account]);

  const handleChange =
    (entry: JournalEntry) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      const newEntry = isExpanded ? entry : null;
      dispatch(setCurrentJournalEntry(newEntry));
    };

  const onSaveNotes = (value: string) => {
    dispatch(saveJournalNotes({ notes: value, entry: selectedJournEntry! }))
      .unwrap()
      .then(() => {
        snackbar.showSnackbar({ message: 'Journal notes saved successfully', type: 'success' });
      })
      .catch((error) => {
        snackbar.showSnackbar({ message: 'Failed to save journal', type: 'error' });
      });
  };

  return (
    <React.Fragment>
      <Paper className="page-header" elevation={2}>
        <Typography variant="h6" sx={{ fontWeight: '500', flex: 1 }}>
          Daily Journal
        </Typography>
      </Paper>
      <Box
        className="journal page-content"
        sx={{
          display: 'flex',
          flexDirection: 'row'
        }}>
        <JournalList />
        <Box
          className="journal-details"
          sx={{
            width: '100%',
            padding: '5px',
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5
          }}>
          {selectedJournal?.journalEntries &&
            selectedJournal?.journalEntries.map((entry) => {
              const journalDate = new Date(selectedJournal.date);
              const netPnl = entry.trade.tradePerformances.reduce((sum, tradePerformance) => {
                sum += tradePerformance.netPnl;
                return sum;
              }, 0);
              return (
                <Accordion
                  key={entry.id}
                  sx={{ position: 'inherit' }}
                  expanded={entry.id === selectedJournEntry?.id}
                  onChange={handleChange(entry)}
                  TransitionProps={{ unmountOnExit: true }}>
                  <AppAccordionSummary
                    title={<Typography variant="subtitle1">{entry.trade.symbol}</Typography>}
                    subTitle={
                      <Typography sx={{ fontSize: '12px' }}>
                        Trades: {entry.trade.tradePerformances.length}
                      </Typography>
                    }
                    rightElement={
                      <Typography
                        variant="h5"
                        sx={{
                          color: `${netPnl > 0 ? 'green' : 'red'}`
                        }}>
                        <Currency price={netPnl}></Currency>
                      </Typography>
                    }
                  />
                  <AppAccordionContent
                    date={journalDate}
                    symbol={entry.trade.symbol}
                    notes={selectedJournEntry?.notes}
                    saveNotes={onSaveNotes}
                  />
                </Accordion>
              );
            })}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default DailyJournal;

import AreaChart from 'components/dashboard/AreaChart';
import { CumulativePnl } from 'models/cumulativePnl';
import { TabPanelProps } from 'models/props/tabPanelProp';
import { useSnackbar } from 'providers/SnackbarProvider';
import React, { useEffect, useState } from 'react';
import statsService from 'services/statsService';
import { selectCurrentAccount } from 'store/accountSlice';
import { useStoreSelector } from 'store/store';

const PnlToDateTab: React.FC<TabPanelProps> = ({ value, index, ...other }) => {
  const [pnl, setPnl] = useState<CumulativePnl[]>([]);
  const { showSnackbar } = useSnackbar();
  const account = useStoreSelector(selectCurrentAccount);

  useEffect(() => {
    statsService
      .pnlToDate()
      .then((pnl) => setPnl(pnl.data))
      .catch((error) => {
        showSnackbar({ message: 'Failed to load pnl to date', type: 'error' });
      });
  }, [account]);

  return (
    <div
      className={`full-width ${value === index ? 'report-tab' : ''}`}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      style={{ paddingTop: '10px' }}>
      {value === index && (
        <React.Fragment>
          <AreaChart data={pnl} maintainAspectRatio={false} />
        </React.Fragment>
      )}
    </div>
  );
};

export default PnlToDateTab;

import {
  Box,
  Card,
  CardContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import CardHeader from 'components/shared/CardHeader';
import { HeaderCell, TableHeader } from 'components/table-header/tableHeader';
import { Account } from 'models/account';
import moment from 'moment';
import { useSnackbar } from 'providers/SnackbarProvider';
import React, { useEffect, useState } from 'react';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import { selectUser } from 'store/userSlice';
import ProfileFormDialog from './ProfileFormDialog';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { green, red } from '@mui/material/colors';
import './Profile.scss';
import ConfirmationDialog from 'components/shared/confirmation-dialog/ConfirmationDialog';
import { loadAccounts, saveAccount, selectAccounts } from 'store/accountSlice';

const Profile: React.FC = () => {
  const accounts = useStoreSelector(selectAccounts);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const [accountToEdit, setAccountToEdit] = useState<Account | null>(null);
  const [accountToDelete, setAccountToDelete] = useState<Account | null>(null);
  const user = useStoreSelector(selectUser);
  const { showSnackbar } = useSnackbar();
  const dispatch = useStoreDispatch();
  const headerCells: readonly HeaderCell[] = [
    {
      id: 'name',
      disablePadding: false,
      label: 'Name',
      numeric: false
    },
    {
      id: 'description',
      disablePadding: false,
      label: 'Description',
      numeric: false
    },
    {
      id: 'isDefault',
      disablePadding: false,
      label: 'Default',
      numeric: false
    },
    {
      id: 'isActive',
      disablePadding: false,
      label: 'Active',
      numeric: false
    },
    {
      id: 'createdAt',
      disablePadding: false,
      label: 'Created At',
      numeric: true
    }
  ];

  useEffect(() => {
    dispatch(loadAccounts());
  }, []);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCancel = () => {
    setDialogOpen(false);
    setAccountToEdit(null);
  };

  const handleSaveAccount = (account: Account) => {
    setDialogOpen(false);
    setAccountToEdit(null);
    dispatch(saveAccount(account));
  };

  const handleEdit = (account: Account) => {
    setAccountToEdit(account);
    setDialogOpen(true);
  };

  const handleDelete = (account: Account) => {
    setAccountToDelete(account);
    setConfirmationDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setAccountToDelete(null);
    setConfirmationDialogOpen(false);
  };

  const handleDeleteConfirm = () => {};

  return (
    <React.Fragment>
      <Paper className="page-header" elevation={2}>
        <Typography variant="h6" sx={{ fontWeight: '500' }}>
          Profile - {user?.firstName} {user?.lastName}
        </Typography>
      </Paper>

      <Box className="page-content profile">
        <Grid2 container>
          <Grid2 xs={12}>
            <Card>
              <CardHeader>
                <Typography variant="body1">Accounts</Typography>
                <IconButton onClick={handleOpenDialog}>
                  <AddIcon />
                </IconButton>
              </CardHeader>
              <CardContent>
                <TableContainer>
                  <Table size="medium">
                    <TableHeader headerCells={headerCells} hasActionColumn={true} />
                    <TableBody>
                      {accounts &&
                        accounts.map((account) => {
                          const formattedDate = moment(account.createdAt).format('YYYY-MM-DD');
                          return (
                            <TableRow key={account.name}>
                              <TableCell>{account.name}</TableCell>
                              <TableCell>{account.description}</TableCell>
                              <TableCell>
                                {account.isDefault ? (
                                  <CheckCircleIcon style={{ color: green[500] }} />
                                ) : (
                                  <CancelIcon style={{ color: red[500] }} />
                                )}
                              </TableCell>
                              <TableCell>
                                {account.isActive ? (
                                  <CheckCircleIcon style={{ color: green[500] }} />
                                ) : (
                                  <CancelIcon style={{ color: red[500] }} />
                                )}
                              </TableCell>
                              <TableCell align="right">{formattedDate}</TableCell>
                              <TableCell align="right" style={{ width: '20%' }}>
                                <IconButton onClick={() => handleEdit(account)} title="Edit">
                                  <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDelete(account)} title="Delete">
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>
      </Box>

      <ProfileFormDialog
        open={isDialogOpen}
        accountToEdit={accountToEdit}
        onSubmit={handleSaveAccount}
        onCancel={handleCancel}
      />

      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Delete image"
        text="Are you sure you want to deactivate this account? The account will not be deleted, and you can always activate it again."
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />
    </React.Fragment>
  );
};

export default Profile;

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import AppAutocomplete from 'components/shared/autocomplete/Autocomplete';
import DatePicker from 'components/shared/datepicker/DatePicker';
import { Favorite } from 'models/favorite';
import { Tag } from 'models/tag';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useStoreSelector } from 'store/store';
import { selectTags } from 'store/tagsSlice';
import * as Yup from 'yup';

interface FavoriteFormDialogProps {
  favoriteToEdit?: Favorite;
  open: boolean;
  onSubmit: (favorite: Favorite) => void;
  onCancel: () => void;
}

interface FavoriteForm {
  id: number | undefined;
  symbol: string;
  date: Date;
  tags: Tag[] | undefined;
}

const FavoriteFormDialog: React.FC<FavoriteFormDialogProps> = ({
  favoriteToEdit,
  open,
  onSubmit,
  onCancel
}) => {
  const tags: Tag[] = useStoreSelector(selectTags);
  const validationSchema = Yup.object().shape({
    id: Yup.number(),
    symbol: Yup.string().required('Symbol is required'),
    date: Yup.date().required('Date is required'),
    tags: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().required(),
        name: Yup.string().required()
      })
    )
  });

  const textFieldStyle = {
    marginTop: '0px',
    marginBottom: '0px'
  };

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<FavoriteForm>({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    if (favoriteToEdit) {
      const newDefaultValues = {
        id: favoriteToEdit.id,
        symbol: favoriteToEdit.symbol,
        tags: favoriteToEdit.tags,
        date: new Date(favoriteToEdit.date)
      };

      reset(newDefaultValues);
    } else {
      resetForm();
    }
  }, [favoriteToEdit, reset]);

  const resetForm = () => {
    reset({
      id: undefined,
      symbol: '',
      date: new Date()
    });
  };

  const submitForm = (data: FavoriteForm) => {
    const item = { ...data } as Favorite;
    resetForm();
    onSubmit(item);
  };

  return (
    <Dialog open={open} className="playbook-form" disableEscapeKeyDown>
      <form noValidate onSubmit={handleSubmit(submitForm)}>
        <DialogTitle>Favorite</DialogTitle>
        <DialogContent sx={{ paddingTop: '20px !important' }}>
          <Grid2 container spacing={2}>
            <Grid2 xs={12}>
              <TextField
                placeholder="AAPL"
                label="Symbol"
                type="text"
                variant="standard"
                fullWidth
                {...register('symbol')}
                error={errors.symbol ? true : false}
                helperText={errors.symbol && errors.symbol?.message}
                style={textFieldStyle}
                autoFocus
              />
            </Grid2>

            <Grid2 xs={12}>
              <DatePicker
                control={control}
                name="date"
                errors={errors}
                label="Select date"
                placeholder="Select date"
              />
            </Grid2>

            <Grid2 xs={12}>
              <AppAutocomplete name="tags" label="Tags" control={control} tags={tags} />
            </Grid2>
          </Grid2>
        </DialogContent>

        <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button onClick={onCancel} variant="contained" color="gray" fullWidth>
              Cancel
            </Button>
            <Button variant="contained" type="submit" fullWidth>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FavoriteFormDialog;

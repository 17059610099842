import { Navigate, createBrowserRouter, useLocation } from 'react-router-dom';
import Login from 'components/login/Login';
import MainLayout from 'components/layouts/MainLayout';
import Reports from 'components/reports/Reports';
import Trades from 'components/trades/Trades';
import AuthLayout from 'components/layouts/AuthLayout';
import Dashboard from 'components/dashboard/Dashboard';
import React from 'react';
import TradeDetails from 'components/trades/TradeDetails';
import DailyJournal from 'components/daily-journal/DailyJournal';
import PrivacyPolicy from 'components/Footer/privacy-policy/PrivacyPolicy';
import Imprint from 'components/Footer/imprint/Imprint';
import DailyWatchlist from 'components/daily-watchlist/DailyWatchlist';
import ErrorPage from './ErrorPage';
import PlaybookComponent from 'components/playbook/Playbook';
import Profile from 'components/profile/Profile';
import Runners from 'components/setup/Runners';
import Favorites from 'components/favorites/Favorites';
import Tags from 'components/tags/Tags';
import UploadData from 'components/upload-data/UploadData';
import Notebook from 'components/notebook/Notebook';
import Gappers from 'components/gappers/Gappers';
import Filings from 'components/filings/Filings';
import Tickers from 'components/tickers/Tickers';
import { useAuth } from 'providers/AuthProvider';

const ProtectedElement: React.FC<{ children: React.ReactElement }> = ({ children }) => {
  const auth = useAuth();

  if (!auth?.isLoggedIn) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthLayout />,
    children: [
      {
        path: '/login',
        element: <Login />
      },
      {
        path: 'privacy',
        element: <PrivacyPolicy />
      },
      {
        path: 'imprint',
        element: <Imprint />
      }
      // {
      //   path: 'register',
      //   element: <Registration />
      // }
    ]
  },
  {
    path: '/',
    element: (
      <ProtectedElement>
        <MainLayout />
      </ProtectedElement>
    ),
    children: [
      {
        index: true,
        element: <Dashboard />
      },
      {
        path: 'trades',
        element: <Trades />
      },
      {
        path: 'trades/details/:tradeId',
        element: <TradeDetails />
      },
      {
        path: 'journal',
        element: <DailyJournal />
      },
      {
        path: 'reports',
        element: <Reports />
      },
      {
        path: 'watchlist',
        element: <DailyWatchlist />
      },
      {
        path: 'playbook',
        element: <PlaybookComponent />
      },
      {
        path: 'profile',
        element: <Profile />
      },
      {
        path: 'runners',
        element: <Runners />
      },
      {
        path: 'favorites',
        element: <Favorites />
      },
      {
        path: 'tags',
        element: <Tags />
      },
      {
        path: 'upload-data',
        element: <UploadData />
      },
      {
        path: 'notebook',
        element: <Notebook />
      },
      {
        path: 'gappers',
        element: <Gappers />
      },
      {
        path: 'filings',
        element: <Filings />
      },
      {
        path: 'tickers',
        element: <Tickers />
      }
    ]
  },
  {
    path: '*',
    element: <ErrorPage />
  }
]);

export default router;

import { IconButton, List, ListItemButton, ListItemText } from '@mui/material';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  deleteWatchlistItem,
  selectCurrentStock,
  selectCurrentWatchlist,
  setCurrentStock
} from 'store/watchlistSlice';
import React, { useState } from 'react';
import { Ticker } from 'models/ticker';
import ConfirmationDialog from 'components/shared/confirmation-dialog/ConfirmationDialog';

const WatchlistStocks: React.FC = () => {
  const [stockToDelete, setStockToDelete] = useState<Ticker | null>(null);
  const selectedStock = useStoreSelector(selectCurrentStock);
  const watchlist = useStoreSelector(selectCurrentWatchlist);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const dispatch = useStoreDispatch();

  const handleDelete = (event: React.MouseEvent<HTMLElement>, stock: Ticker) => {
    event.preventDefault();
    event.stopPropagation();
    setStockToDelete(stock);
    setConfirmDialogOpen(true);
  };

  const handleSelect = (stock: Ticker) => {
    dispatch(setCurrentStock(stock));
  };

  const handleDeleteCancel = () => {
    setConfirmDialogOpen(false);
    setStockToDelete(null);
  };

  const handleDeleteConfirm = () => {
    if (stockToDelete) {
      dispatch(deleteWatchlistItem({ item: stockToDelete })).unwrap();
    }
    setConfirmDialogOpen(false);
  };

  return (
    <React.Fragment>
      <List
        className="watchlist-stocks"
        sx={{ height: '100%', overflow: 'auto', paddingTop: 0, paddingBottom: 0 }}>
        {watchlist &&
          watchlist.watchlistStocks.map((stock) => {
            return (
              <ListItemButton
                disableRipple
                key={stock.id}
                onClick={() => handleSelect(stock)}
                className={selectedStock === stock ? 'selected' : ''}
                divider={false}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  flexGrow: 0,
                  padding: '0 6px 0 6px'
                }}>
                <ListItemText primary={stock.ticker}></ListItemText>
                <IconButton
                  size="small"
                  onClick={(event) => handleDelete(event, stock)}
                  aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </ListItemButton>
            );
          })}
      </List>

      <ConfirmationDialog
        open={isConfirmDialogOpen}
        title="Delete ticker"
        text="Are you sure you want to permanently delete this ticker? This action cannot be undone."
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />
    </React.Fragment>
  );
};

export default WatchlistStocks;

import { Box } from '@mui/material';
import { CSSProperties } from 'react';

const PrivacyPolicy: React.FC = () => {
  const listStyle: CSSProperties = {
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '10px'
  };

  const textStyle: CSSProperties = {
    marginBottom: '10px'
  };

  return (
    <Box>
      <ol>
        <ol>
          <li style={listStyle}>Rechtsgrundlage</li>
          <div style={textStyle} className="text">
            Der Schutz von personenbezogenen Daten ist uns wichtig und auch gesetzlich gefordert.
            Die EU-Datenschutzgrundverordnung, das Datenschutzgesetz sowie das
            Telekommunikationsgesetz dienen dem Recht auf Schutz personenbezogener Daten. Wir
            verarbeiten Ihre Daten ausschließlich auf Grundlage dieser gesetzlichen Bestimmungen in
            der jeweils geltenden Fassung (DSGVO, DSG, TKG 2003).
          </div>

          <li style={listStyle}>Verantwortlicher</li>
          <div style={textStyle} className="text">
            <span>Farid Rahimi</span>
            <br />
            <span>Margarethen 49a, 4020 Linz</span>
            <br />
            <span>privacy@faridrahimi.at</span>
            <br />
          </div>

          <li style={listStyle}>Erhebung und Verarbeitung persönlicher Daten</li>
          <div style={textStyle}>
            Wenn Sie uns zum ersten Mal kontaktieren oder unsere Dienste nutzen, erfassen wir
            möglicherweise Informationen, die für die Nutzung unserer Dienste relevant sind,
            einschließlich:
            <br />
            <br />
            <ul>
              <li>Kontaktinformationen</li>
              <li>Trade-Informationen</li>
            </ul>
          </div>

          <li style={listStyle}>Zweck der Datenverarbeitung</li>
          <div style={textStyle}>
            Ihre Daten werden zum Zweck der Bereitstellung und Optimierung unserer Dienstleistungen,
            einschließlich Statistiken und Analysen Ihrer Trades, verwendet.
          </div>

          <li style={listStyle}>Speicherdauer</li>
          <div style={textStyle}>
            Wir speichern Ihre Daten nur so lange, wie es für die Bereitstellung unserer
            Dienstleistungen erforderlich ist oder gesetzliche Aufbewahrungsfristen dies erfordern.
          </div>

          <li style={listStyle}>Ihre Rechte</li>
          <div style={textStyle}>
            Sie haben das Recht auf Auskunft, Berichtigung, Löschung, Einschränkung der
            Verarbeitung, Datenübertragbarkeit und Widerspruch gegen die Verarbeitung Ihrer Daten.
            Sie können auch Ihre Einwilligung jederzeit widerrufen.
          </div>

          <li style={listStyle}>Datensicherheit</li>
          <div style={textStyle}>
            Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre Daten gegen
            Manipulationen, Verlust, Zerstörung und Zugriff durch Dritte zu schützen.
          </div>

          <li style={listStyle}>Datenübermittlung an Dritte</li>
          <div style={textStyle}>
            Wir geben Ihre Daten nicht ohne Ihre ausdrückliche Zustimmung an Dritte weiter, es sei
            denn, wir sind gesetzlich dazu verpflichtet.
          </div>

          <li style={listStyle}>Kontakt</li>
          <div style={textStyle}>
            Bei Fragen zur Datenschutzerklärung oder Ihren Rechten können Sie sich gerne per E-Mail
            an uns wenden: privacy@faridrahimi.at
          </div>
        </ol>
      </ol>
    </Box>
  );
};

export default PrivacyPolicy;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Account } from 'models/account';
import accountService from 'services/accountService';

export interface AccountState {
  currentAccount: Account | null;
  accounts: Account[];
}

const initialState: AccountState = {
  accounts: [],
  currentAccount: null
};

export const loadAccounts = createAsyncThunk('accounts/load', async () => {
  const response = await accountService.loadAccounts();
  return response.data;
});

export const saveAccount = createAsyncThunk<Account, Account>('accounts/save', async (account) => {
  const response = await accountService.saveAccount(account);
  return response.data;
});

export const changeDefaultAccount = createAsyncThunk<string, string>(
  'accounts/changeDefault',
  async (accountId, thunkApi) => {
    const response = await accountService.changeDefaultAccount(accountId);
    if (response.status === 200) {
      return accountId;
    } else {
      return thunkApi.rejectWithValue(response.status);
    }
  }
);

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadAccounts.fulfilled, (state, action) => {
        state.accounts = action.payload;
        if (state.accounts && state.accounts.length > 0) {
          const index = state.accounts.findIndex((account) => account.isDefault);
          state.currentAccount = index > -1 ? state.accounts[index] : null;
        }
      })
      .addCase(changeDefaultAccount.fulfilled, (state, action) => {
        if (action.payload === 'all') {
          state.currentAccount = null;
        } else {
          const index = state.accounts.findIndex(
            (account) => account.id === Number(action.payload)
          );
          if (index !== -1) {
            state.currentAccount = state.accounts[index];
          }
          state.accounts = state.accounts.map((account, mapIndex) => {
            if (mapIndex === index) {
              return { ...account, isDefault: true };
            } else {
              return { ...account, isDefault: false };
            }
          });
        }
      })
      .addCase(saveAccount.fulfilled, (state, action) => {
        const index = state.accounts.findIndex((a) => a.id === action.payload.id);

        if (index !== -1) {
          state.accounts.splice(index, 1, action.payload);
        } else {
          state.accounts.push(action.payload);
        }
        if (action.payload.isDefault) {
          state.currentAccount = action.payload;
        }
      });
  }
});

export const selectAccounts = (state: { account: AccountState }) => state.account.accounts;
export const selectCurrentAccount = (state: { account: AccountState }) =>
  state.account.currentAccount;

export default accountSlice.reducer;

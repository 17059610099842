import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  IconButton,
  Paper,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ReadMoreRoundedIcon from '@mui/icons-material/ReadMoreRounded';
import { HeaderCell, Order, TableHeader } from 'components/table-header/tableHeader';
import Trade from 'models/trade';
import { AvgWinLoss } from 'models/avgWinLoss';
import ReportIcon from '@mui/icons-material/Report';
import moment from 'moment';
import { useSnackbar } from 'providers/SnackbarProvider';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Currency from 'components/currency/currency';
import { useNavigate } from 'react-router-dom';
import { loadTrades, selectTrades, setCurrentTrade, updateTrade } from 'store/tradesSlice';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import './Trades.scss';
import statsService from 'services/statsService';
import TradeFormDialog from './TradeFormDialog';
import { selectCurrentAccount } from 'store/accountSlice';

const Trades: React.FC = () => {
  const trades = useStoreSelector(selectTrades);
  const [tradeToEdit, setTradeToEdit] = useState<Trade | null>(null);
  const [isTradeDialogOpen, setTradeDialogOpen] = useState(false);
  const [avg, setAvg] = useState<AvgWinLoss | null>(null);
  const [sortOrder, setSortOrder] = React.useState<Order>('desc');
  const [symbol, setSymbol] = React.useState<string>('');
  const [sortBy, setSortBy] = React.useState<string>('date');
  const { showSnackbar } = useSnackbar();
  const account = useStoreSelector(selectCurrentAccount);
  const navigate = useNavigate();
  const dispatch = useStoreDispatch();

  const headerCells: readonly HeaderCell[] = [
    {
      id: 'symbol',
      disablePadding: false,
      label: 'Symbol',
      numeric: false
    },
    {
      id: 'date',
      disablePadding: false,
      label: 'Date',
      numeric: false
    },
    {
      id: 'netPnl',
      disablePadding: false,
      label: 'PNL',
      numeric: false
    },
    {
      id: 'dailyVolume',
      disablePadding: false,
      label: 'Daily Volume',
      numeric: true
    },
    {
      id: 'premarketVolume',
      disablePadding: false,
      label: 'Premarket Volume',
      numeric: true
    },
    {
      id: 'float',
      disablePadding: false,
      label: 'Float',
      numeric: true
    },
    {
      id: 'trades',
      disablePadding: false,
      label: 'Trades',
      numeric: true
    }
  ];

  const loadAvg = () => {
    statsService
      .avgWinLoss()
      .then((response) => setAvg(response.data))
      .catch((error) => showSnackbar({ message: 'Faled to load trades', type: 'error' }));
  };

  useEffect(() => {
    dispatch(loadTrades({ symbol: symbol, sortBy: sortBy, sortOrder: sortOrder }));
    loadAvg();
  }, [symbol, sortOrder, sortBy, account]);

  const handleSortRequest = (event: React.MouseEvent, property: string) => {
    const isAsc = sortBy === property && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortBy(property);
  };

  const handleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setSymbol(event.target.value);
  };

  const handleDetailsButton = (trade: Trade) => {
    dispatch(setCurrentTrade(trade));
    navigate(`details/${trade.id}`);
  };

  const handleEditTrade = (trade: Trade) => {
    setTradeToEdit(trade);
    setTradeDialogOpen(true);
  };

  const handleCancleEdit = () => {
    setTradeToEdit(null);
    setTradeDialogOpen(false);
  };
  const handleSubmitTrade = (trade: Trade) => {
    dispatch(updateTrade(trade))
      .unwrap()
      .then(() => {
        setTradeDialogOpen(false);
      })
      .catch(() => {
        showSnackbar({ message: 'Failed to update trade', type: 'error' });
      });
  };

  return (
    <React.Fragment>
      <Paper className="page-header trades" elevation={2}>
        <Typography variant="h6" sx={{ fontWeight: '500', flex: 1 }}>
          Trades
        </Typography>

        <Grid2
          container
          spacing={1}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flex: 3,
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}>
          <Grid2 xs={4} display="flex" justifyContent="center" alignItems="flex-start" padding={0}>
            <TextField
              id="standard-basic"
              label="Filter"
              variant="standard"
              onChange={handleFilter}
            />
          </Grid2>
          <Grid2 container xs={4}>
            <Grid2 xs={6}>
              Avg. Win:{' '}
              <span className="green">
                <Currency price={avg?.avgWin || 0}></Currency>
              </span>
            </Grid2>
            <Grid2 xs={6}>
              Avg. Loss:{' '}
              <span className="red">
                <Currency price={avg?.avgLoss || 0}></Currency>
              </span>
            </Grid2>
          </Grid2>
        </Grid2>
      </Paper>
      <div className="page-content">
        <Card sx={{ width: '100%' }}>
          <CardContent>
            <TableContainer>
              <Table size="medium">
                <TableHeader
                  headerCells={headerCells}
                  order={sortOrder}
                  orderBy={sortBy}
                  onRequestSort={handleSortRequest}
                  hasActionColumn={true}
                />
                <TableBody>
                  {trades &&
                    trades.map((trade, index) => {
                      const formattedDate = moment(trade.date).format('YYYY-MM-DD');
                      const pnl = trade.tradePerformances.reduce((acc, tradePerformance) => {
                        return (acc += tradePerformance.netPnl);
                      }, 0);
                      return (
                        <TableRow key={trade.id} hover>
                          <TableCell>{trade.symbol}</TableCell>
                          <TableCell>{formattedDate}</TableCell>
                          <TableCell>
                            <Currency price={pnl}></Currency>
                          </TableCell>
                          <TableCell align="right">{trade.dailyVolume}</TableCell>
                          <TableCell align="right">{trade.premarketVolume}</TableCell>
                          <TableCell align="right">
                            {trade.float ? trade.float : <ReportIcon color="warning" />}
                          </TableCell>
                          <TableCell align="right">{trade.tradePerformances.length}</TableCell>
                          <TableCell style={{ width: '10%' }}>
                            <IconButton
                              onClick={() => handleDetailsButton(trade)}
                              color="primary"
                              aria-label="details"
                              title="Details">
                              <ReadMoreRoundedIcon />
                            </IconButton>
                            <IconButton onClick={() => handleEditTrade(trade)} color="default">
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  {trades.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={8}>No trades found</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>

        <TradeFormDialog
          onCancel={handleCancleEdit}
          onSubmit={handleSubmitTrade}
          open={isTradeDialogOpen}
          trade={tradeToEdit}
        />
      </div>
    </React.Fragment>
  );
};

export default Trades;

import { AxiosResponse } from 'axios';
import axios from '../config/axiosConfig';
import UserRegistration from 'models/userRegistration';

class UserService {
  register(user: UserRegistration): Promise<AxiosResponse<any>> {
    try {
      return axios.post(`${process.env.REACT_APP_API_URL}/register`, user);
    } catch (error) {
      console.error('Failed to create user', error);
      throw error;
    }
  }
}

export default new UserService();

import {
  Box,
  Card,
  CardContent,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import Fab from 'components/shared/fab/Fab';
import React, { useEffect, useState } from 'react';
import SetupsDialog from './RunnersDialog';
import { Runner } from 'models/runner';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import {
  deleteRunner,
  loadRunners,
  saveRunner,
  selectRunners,
  setCurrentSetup
} from 'store/runnerSlice';
import { HeaderCell, TableHeader } from 'components/table-header/tableHeader';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import ConfirmationDialog from 'components/shared/confirmation-dialog/ConfirmationDialog';
import CandleStickChart from 'components/tradingview-charts/CandleStickChart';
import { useDebouncedCallback } from 'use-debounce';
import './Runners.scss';

interface RowProps {
  setup: Runner;
  onEdit: (setup: Runner) => void;
  onDelete: (setup: Runner) => void;
  saveSetup: (setup: Runner) => void;
}

function Row(props: RowProps) {
  const { setup, onEdit, onDelete, saveSetup } = props;
  const formattedDate = moment(setup.date).format('YYYY-MM-DD');
  const [open, setOpen] = React.useState(false);
  const dispatch = useStoreDispatch();

  // const { quill, quillRef } = useQuill({ placeholder: 'Write your first note ...' });
  const debounces = useDebouncedCallback((value) => {
    saveSetup({ ...setup, comments: value });
  }, 1000);

  // useEffect(() => {
  //   if (quill) {
  //     if (setup.comments) {
  //       quill.clipboard.dangerouslyPasteHTML(setup.comments);
  //     }
  //     quill.on('text-change', () => {
  //       debounces(quill.root.innerHTML);
  //     });
  //   }
  // }, [quill]);

  const handleOpenClicked = (setup: Runner) => {
    setOpen(!open);
    if (!open) {
      dispatch(setCurrentSetup(setup));
    } else {
      dispatch(setCurrentSetup(null));
    }
  };

  return (
    <React.Fragment>
      <TableRow key={setup?.id} hover>
        <TableCell sx={{ width: '10px', padding: '3px 3px' }}>
          <IconButton aria-label="expand row" size="small" onClick={() => handleOpenClicked(setup)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{setup.symbol}</TableCell>
        <TableCell>{formattedDate}</TableCell>
        <TableCell align="right">{setup.float && `${setup.float} M`}</TableCell>
        <TableCell align="right">{setup.premarketGap && `${setup.premarketGap} %`}</TableCell>
        <TableCell align="right">{setup.premarketVolume && `${setup.premarketVolume} M`}</TableCell>
        <TableCell align="right">{setup.dailyVolume && `${setup.dailyVolume} M`}</TableCell>
        <TableCell align="right">{setup.marketCap && `${setup.marketCap} M`}</TableCell>
        <TableCell align="right">{setup.day}</TableCell>
        <TableCell>{setup.news}</TableCell>
        <TableCell style={{ width: '80px', padding: '3px 3px' }}>
          <IconButton onClick={() => onEdit(setup)} title="Edit">
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => onDelete(setup)} color="default">
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <CandleStickChart symbol={setup.symbol} date={setup.date} height="500px" />
            </Box>
            <Box sx={{ marginTop: '10px', marginBottom: '10px' }}>
              {/* <div style={{ padding: '5px' }} ref={quillRef}></div> */}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Runners: React.FC = () => {
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<Runner | null>(null);
  const [itemToDelete, setItemToDelete] = useState<Runner | null>(null);
  const runners = useStoreSelector(selectRunners);
  const dispatch = useStoreDispatch();

  const headerCells: readonly HeaderCell[] = [
    {
      id: 'symbol',
      disablePadding: false,
      label: 'Symbol',
      numeric: false
    },
    {
      id: 'date',
      disablePadding: false,
      label: 'Date',
      numeric: false
    },
    {
      id: 'float',
      disablePadding: false,
      label: 'Float',
      numeric: true
    },
    {
      id: 'premarketGap',
      disablePadding: false,
      label: 'Gap',
      numeric: true
    },
    {
      id: 'premarketVolume',
      disablePadding: false,
      label: 'Prem. Vol.',
      numeric: true
    },
    {
      id: 'dailyVolume',
      disablePadding: false,
      label: 'Volume',
      numeric: true
    },
    {
      id: 'marketCap',
      disablePadding: false,
      label: 'Market Cap.',
      numeric: true
    },
    {
      id: 'day',
      disablePadding: false,
      label: 'Day',
      numeric: true
    },
    {
      id: 'news',
      disablePadding: false,
      label: 'News',
      numeric: false
    }
  ];

  useEffect(() => {
    dispatch(loadRunners());
  }, []);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleSaveSetup = (setup: Runner) => {
    dispatch(saveRunner(setup));
    setDialogOpen(false);
  };
  const handleCancel = () => {
    setDialogOpen(false);
    setItemToEdit(null);
  };

  const handleEdit = (setup: Runner) => {
    setItemToEdit(setup);
    setDialogOpen(true);
  };

  const handleDelete = (setup: Runner) => {
    setItemToDelete(setup);
    setConfirmDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setConfirmDialogOpen(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirm = () => {
    setConfirmDialogOpen(false);
    if (itemToDelete) {
      dispatch(deleteRunner(itemToDelete));
    }
  };

  return (
    <React.Fragment>
      <Paper className="page-header setups-page" elevation={2}>
        <Typography variant="h6" sx={{ fontWeight: '500' }}>
          Setups
        </Typography>
      </Paper>
      <Box className="page-content">
        <Card sx={{ width: '100%' }}>
          <CardContent>
            <TableContainer>
              <Table size="small">
                <TableHeader headerCells={headerCells} hasActionColumn={true} collapsible={true} />
                <TableBody>
                  {runners &&
                    runners.map((setup) => (
                      <Row
                        key={setup.id}
                        setup={setup}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        saveSetup={handleSaveSetup}
                      />
                    ))}

                  {runners.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={10}>No stocks found</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>

      <SetupsDialog
        itemToEdit={itemToEdit}
        open={isDialogOpen}
        onSubmit={handleSaveSetup}
        onCancel={handleCancel}
      />

      <ConfirmationDialog
        open={isConfirmDialogOpen}
        title="Delete Stock"
        text="Are you sure you want to permanently delete this stock? This action cannot be undone."
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />

      <Fab onClick={handleOpenDialog}></Fab>
    </React.Fragment>
  );
};

export default Runners;

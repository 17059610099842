import axios, { AxiosResponse } from 'axios';
import { Tag } from 'models/tag';

class TagsService {
  async loadAll(): Promise<AxiosResponse<Tag[]>> {
    return await axios.get(`${process.env.REACT_APP_API_URL}/tags`);
  }

  async save(tag: Tag): Promise<AxiosResponse<Tag>> {
    if (tag.id) {
      return await axios.put(`${process.env.REACT_APP_API_URL}/tags/${tag.id}`, {
        tag: tag
      });
    } else {
      return await axios.post(`${process.env.REACT_APP_API_URL}/tags`, {
        tag: tag
      });
    }
  }

  async delete(tag: Tag): Promise<AxiosResponse<void>> {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/tags/${tag.id}`);
  }
}

export default new TagsService();

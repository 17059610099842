import ReactQuill from 'react-quill';
import { useDebouncedCallback } from 'use-debounce';

interface TextEditorProps {
  onSave: (notes: string) => void;
  editingStarted?: () => void;
  editingFinished?: () => void;
  notes?: string;
  editorKey: any;
}

const TextEditor: React.FC<TextEditorProps> = ({
  notes,
  onSave,
  editingStarted,
  editingFinished,
  editorKey
}) => {
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ size: [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image', 'video'],
      ['clean']
    ],
    clipboard: {
      matchVisual: false
    }
  };
  const quillTextChangeHandler = (value: string, delta: any, source: string, editor: any) => {
    if (source === 'user') {
      debounces(value);
    }
  };

  const debounces = useDebouncedCallback((value) => {
    if (value) {
      onSave(value);
    }
  }, 1000);

  const handleOnFocus = () => {
    if (editingStarted) {
      editingStarted();
    }
  };

  const handleOnBlur = () => {
    if (editingFinished) {
      editingFinished();
    }
  };

  return (
    <ReactQuill
      key={editorKey}
      theme="snow"
      defaultValue={notes}
      onChange={quillTextChangeHandler}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      modules={modules}
      placeholder="Write your first note ..."
    />
  );
};

export default TextEditor;

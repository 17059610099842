import axios, { AxiosResponse } from 'axios';
import { Account } from 'models/account';

class AccountService {
  async loadAccounts(): Promise<AxiosResponse<Account[]>> {
    return axios.get(`${process.env.REACT_APP_API_URL}/accounts`);
  }

  async saveAccount(account: Account): Promise<AxiosResponse<Account>> {
    if (account.id) {
      return axios.put(`${process.env.REACT_APP_API_URL}/accounts/${account.id}`, { account });
    } else {
      return axios.post(`${process.env.REACT_APP_API_URL}/accounts`, { account });
    }
  }

  async changeDefaultAccount(accountId: string): Promise<AxiosResponse<Account>> {
    return axios.post(`${process.env.REACT_APP_API_URL}/accounts/change-default`, { accountId });
  }
}

export default new AccountService();

import axios, { AxiosResponse } from 'axios';

class DataService {
  async loadData() {
    await axios.get(`${process.env.REACT_APP_API_URL}/data`);
  }

  async uploadData(file: File, interval: string, symbol: string): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('interval', interval);
    formData.append('symbol', symbol);
    return await axios.post(`${process.env.REACT_APP_API_URL}/data`, formData);
  }
}

export default new DataService();

import axios, { AxiosResponse } from 'axios';
import { Ticker } from 'models/ticker';
import { WatchlistModel } from 'models/watchlist';
import { WatchlistEntry } from 'models/watchlistEntry';
import moment from 'moment';

class WatchlistService {
  async create(): Promise<AxiosResponse<WatchlistModel>> {
    return await axios.post(`${process.env.REACT_APP_API_URL}/watchlist`);
  }

  async loadWatchlist(symbol?: string, date?: string): Promise<AxiosResponse<WatchlistModel[]>> {
    const queryParams = {
      symbol: symbol,
      date: date
    };
    return await axios.get(`${process.env.REACT_APP_API_URL}/watchlist`, {
      params: queryParams
    });
  }

  async createWatchlistItem(
    item: string,
    watchlist: WatchlistModel
  ): Promise<AxiosResponse<Ticker>> {
    const payload = {
      ticker: item
    };
    return await axios.post(
      `${process.env.REACT_APP_API_URL}/watchlist/${watchlist.id}/stock`,
      payload
    );
  }

  async saveStock(ticker: Ticker): Promise<AxiosResponse<Ticker>> {
    const payload = {
      ticker: ticker
    };
    return await axios.put(`${process.env.REACT_APP_API_URL}/watchlist/stock`, payload);
  }

  async deleteWatchlistItem(item: Ticker, watchlistId: number): Promise<AxiosResponse<void>> {
    return await axios.delete(
      `${process.env.REACT_APP_API_URL}/watchlist/${watchlistId}/ticker/${item.id}`
    );
  }
}

export default new WatchlistService();

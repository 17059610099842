import { Box, Paper, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import './Tickers.scss';
import { useStoreDispatch } from 'store/store';
import { loadTickers } from 'store/tickersSlice';
import TickersList from './TickersList';
import TickerNotes from './TickerNotes';

const Tickers: React.FC = () => {
  const [symbol, setSymbol] = useState<string | undefined>(undefined);
  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const dispatch = useStoreDispatch();

  useEffect(() => {
    dispatch(loadTickers({ symbol: symbol, searchString: searchString }));
  }, [symbol, searchString]);

  const handleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setSymbol(event.target.value);
  };
  const handleFullTextSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
  };

  return (
    <React.Fragment>
      <Paper className="page-header tickers-header" elevation={2}>
        <Typography variant="h6" sx={{ fontWeight: '500', flex: 1 }}>
          Tickers
        </Typography>
        <div className="right-panel">
          <TextField
            id="standard-basic"
            label="Symbol"
            placeholder="AAPL"
            variant="standard"
            onChange={handleFilter}
          />

          <TextField
            id="standard-basic"
            label="Full Text Search"
            placeholder="S-1"
            variant="standard"
            onChange={handleFullTextSearch}
          />
        </div>
      </Paper>
      <Box className="tickers page-content">
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            overflow: 'hidden'
          }}>
          <TickersList></TickersList>
          <TickerNotes></TickerNotes>
        </Paper>
      </Box>
    </React.Fragment>
  );
};

export default Tickers;

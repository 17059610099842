import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Trade from 'models/trade';
import moment from 'moment';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

interface TradeFormDialogProps {
  trade: Trade | null;
  open: boolean;
  onSubmit: (trade: Trade) => void;
  onCancel: () => void;
}

interface TradeForm {
  date: string;
  symbol: string;
  float: number;
}

const TradeFormDialog: React.FC<TradeFormDialogProps> = ({ open, trade, onSubmit, onCancel }) => {
  const validationSchema = Yup.object().shape({
    symbol: Yup.string().required('Symbol is required'),
    date: Yup.string().required('Date is required'),
    float: Yup.number().required('Float is required')
  });

  const textFieldStyle = {
    marginTop: '0px',
    marginBottom: '0px'
  };

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<TradeForm>({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    if (trade) {
      const newDefaultValues = {
        id: trade.id,
        symbol: trade.symbol,
        date: moment(trade.date).format('YYYY-MM-DD')
      };

      reset(newDefaultValues);
    }
  }, [trade, reset]);

  const submitForm = (data: TradeForm) => {
    const tradeWithFloat = { ...trade, float: data.float } as Trade;
    onSubmit(tradeWithFloat);
  };

  return (
    <Dialog open={open} className="playbook-form" disableEscapeKeyDown>
      <form noValidate onSubmit={handleSubmit(submitForm)}>
        <DialogTitle>Edit Trade</DialogTitle>
        <DialogContent sx={{ paddingTop: '20px !important' }}>
          <Grid2 container spacing={2}>
            <Grid2 xs={12}>
              <TextField
                id="float"
                {...register('float')}
                fullWidth
                error={errors.float ? true : false}
                helperText={errors.float && errors.float?.message}
                style={textFieldStyle}
                label="Float"
                type="number"
                variant="standard"
                placeholder="Enter a number"
                InputProps={{
                  endAdornment: <InputAdornment position="end">in millions</InputAdornment>
                }}
              />
            </Grid2>
            <Grid2 xs={12}>
              <TextField
                label="Symbol"
                type="text"
                variant="standard"
                fullWidth
                disabled
                {...register('symbol')}
                style={textFieldStyle}
              />
            </Grid2>
            <Grid2 xs={12}>
              <TextField
                label="Date"
                type="text"
                variant="standard"
                disabled
                fullWidth
                {...register('date')}
                style={textFieldStyle}
              />
            </Grid2>
          </Grid2>
        </DialogContent>

        <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button variant="contained" type="submit">
              Save
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TradeFormDialog;

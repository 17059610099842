import CheckIcon from '@mui/icons-material/Check';
import {
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import AppAutocomplete from 'components/shared/autocomplete/Autocomplete';
import DatePicker from 'components/shared/datepicker/DatePicker';
import { Tag } from 'models/tag';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import { loadTags, selectTags } from 'store/tagsSlice';
import { useDebouncedCallback } from 'use-debounce';
import { FilterModel } from './FilterModel';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';

interface GapperFilterProps {
  initialValue?: FilterModel;
  onChange?: (filter: FilterModel) => void;
  onFilter?: (filter: FilterModel) => void;
}

const GapperFilter: React.FC<GapperFilterProps> = ({ initialValue, onChange, onFilter }) => {
  const {
    control,
    formState: { errors }
  } = useForm();
  const tags = useStoreSelector(selectTags);
  const [filterTags, setFilterTags] = useState<Tag[]>([]);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const [symbol, setSymbol] = useState<string | null>(null);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [gap, setGap] = useState<number | null>(null);
  const [maxPercent, setMaxPercent] = useState<number | null>(null);
  const [maxPercentPrem, setMaxPercentPrem] = useState<number | null>(null);
  const [volume, setVolume] = useState<number | null>(null);
  const [premarketVolume, setPremarketVolume] = useState<number | null>(null);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const dispatch = useStoreDispatch();

  useEffect(() => {
    dispatch(loadTags());
  }, []);

  const debounces = useDebouncedCallback((value) => {
    setSymbol(value);
  }, 300);

  const handleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    debounces(event.target.value);
  };

  const handleAutocompleteChanged = (tags: Tag[]) => {
    setFilterTags(tags);
  };

  const handleGapPercentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setGap(Number(event.target.value));
  };
  const handleMaxPercentChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMaxPercent(Number(event.target.value));
  };

  const handleMaxPercentPremChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMaxPercentPrem(Number(event.target.value));
  };

  const handlePremarketVolumeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPremarketVolume(Number(event.target.value));
  };
  const handleVolumeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setVolume(Number(event.target.value));
  };

  const handleFilterClick = () => {
    if (onFilter) {
      onFilter({
        ...initialValue,
        symbol,
        favorites: isFavorite,
        tags: filterTags,
        fromDate: fromDate,
        toDate: toDate,
        gap: gap,
        volume: volume,
        premarketVolume: premarketVolume,
        maxPercent: maxPercent,
        sortOrder: sortOrder,
        maxPercentPremarket: maxPercentPrem
      });
    }
  };

  const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: 'asc' | 'desc') => {
    setSortOrder(newAlignment);
  };

  return (
    <React.Fragment>
      <TextField
        label="Max. %"
        type="number"
        variant="standard"
        fullWidth
        InputProps={{
          sx: {
            '& input': {
              textAlign: 'right'
            }
          }
        }}
        onChange={handleMaxPercentChange}
      />

      <TextField
        label="Max. Prem. %"
        type="number"
        variant="standard"
        fullWidth
        InputProps={{
          sx: {
            '& input': {
              textAlign: 'right'
            }
          }
        }}
        onChange={handleMaxPercentPremChange}
      />

      <TextField
        label="Gap %"
        type="number"
        variant="standard"
        fullWidth
        InputProps={{
          sx: {
            '& input': {
              textAlign: 'right'
            }
          }
        }}
        onChange={handleGapPercentChange}
      />
      <TextField
        label="Prem. Volume"
        type="number"
        variant="standard"
        fullWidth
        InputProps={{
          sx: {
            '& input': {
              textAlign: 'right'
            }
          }
        }}
        onChange={handlePremarketVolumeChange}
      />
      <TextField
        label="Volume"
        type="number"
        variant="standard"
        fullWidth
        InputProps={{
          sx: {
            '& input': {
              textAlign: 'right'
            }
          }
        }}
        onChange={handleVolumeChange}
      />
      <DatePicker
        control={control}
        name="fromDate"
        errors={errors}
        onChange={setFromDate}
        label="From Date"
      />
      <DatePicker
        control={control}
        name="toDate"
        errors={errors}
        onChange={setToDate}
        label="To Date"
      />
      <AppAutocomplete
        control={control}
        name="tag-filter"
        label="Tags"
        size={'small' as 'small | medium'}
        tags={tags}
        onChange={handleAutocompleteChanged}
      />
      <TextField
        id="standard-basic"
        label="Search"
        fullWidth
        placeholder="AAPL"
        variant="standard"
        onChange={handleFilter}
      />
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value="start"
          control={
            <Switch
              checked={isFavorite}
              color="primary"
              onChange={(event) => setIsFavorite(event.target.checked)}
            />
          }
          label="Favorite"
          labelPlacement="start"
        />
      </FormGroup>
      <ToggleButtonGroup
        value={sortOrder}
        exclusive
        onChange={handleAlignment}
        aria-label="text alignment">
        <ToggleButton value="asc">
          <SortByAlphaIcon />
        </ToggleButton>
      </ToggleButtonGroup>
      <IconButton onClick={handleFilterClick} color="primary">
        <CheckIcon />
      </IconButton>
    </React.Fragment>
  );
};

export default GapperFilter;

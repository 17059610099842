import axios, { AxiosResponse } from 'axios';
import { Sector } from 'models/sector';

class SectorService {
  async load(): Promise<AxiosResponse<Sector[]>> {
    return await axios.get(`${process.env.REACT_APP_API_URL}/sectors`);
  }
}

export default new SectorService();

import { createTheme } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    gray: Palette['primary'];
  }

  interface PaletteOptions {
    gray?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    gray: true;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#0f6684'
    },
    gray: {
      main: '#888888',
      light: '#888888c9',
      dark: '#646464'
    }
  },
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          backgroundColor: '#0f8aa9',
          color: 'white'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#224660',
          color: 'white'
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          backgroundColor: '#0f8aa9',
          color: 'white'
        }
      }
    }
  }
});

export default theme;

import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  TypographyProps
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import { NotebookSection } from 'models/notebook-section';
import React, { CSSProperties, useRef, useState } from 'react';
import moment from 'moment';

interface SectionItemProps {
  section: NotebookSection;
  onSave: (section: NotebookSection) => void;
  onDelete: (section: NotebookSection) => void;
}

const SectionItem: React.FC<SectionItemProps> = ({ section, onSave, onDelete }) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const createdOn = moment(section.createdAt).format('YYYY-MM-DD');

  const secondaryProps = {
    fontSize: 13
  };

  const handleEdit = () => {
    setEditMode(true);
    handleClose();
  };
  const handleDelete = () => {
    onDelete(section);
    handleClose();
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const handleSave = () => {
    if (textFieldRef.current) {
      onSave({ ...section, title: textFieldRef.current.value });
      setEditMode(false);
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      {editMode ? (
        <React.Fragment>
          <Box sx={{ flex: 1 }} className="input">
            <TextField
              inputRef={textFieldRef}
              id="section-title"
              label="Title"
              variant="standard"
              fullWidth
              inputProps={{ style: { fontSize: 15 } }}
              defaultValue={section?.title}
            />
          </Box>
          <Box sx={{ display: 'flex' }} className="action-buttons">
            <IconButton aria-label="save" size="small" onClick={handleSave}>
              <SaveIcon />
            </IconButton>

            <IconButton aria-label="cancel" size="small" onClick={handleCancelEdit}>
              <CancelIcon />
            </IconButton>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ListItemText
            primary={section.title}
            secondary={createdOn}
            onDoubleClick={handleEdit}
            secondaryTypographyProps={secondaryProps}></ListItemText>
          <div className="menu">
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              <MenuItem onClick={handleEdit}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleDelete}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            </Menu>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default SectionItem;

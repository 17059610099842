import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  deletePlaybookItem,
  loadPlaybooks,
  savePlaybook,
  savePlaybookItem,
  selectCurrentPlaybook,
  selectCurrentPlaybookItem,
  setCurrentPlaybookItem
} from 'store/playbookSlice';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import PlaybookList from './PlaybookList';
import {
  Accordion,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { PlaybookItem } from 'models/playbookItem';
import AppAccordionContent from 'components/daily-journal/AppAccordionContent';
import PlaybookFormDialog from './PlaybookFormDialog';
import { useSnackbar } from 'providers/SnackbarProvider';
import playbookItemService from 'services/playbookItemService';
import { Playbook } from 'models/playbook';
import Fab from 'components/shared/fab/Fab';
import PlaybookItemFormDialog from './PlaybookItemFormDialog';
import AppAccordionSummary from 'components/daily-journal/AppAccordionSummary';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from 'components/shared/confirmation-dialog/ConfirmationDialog';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './Playbook.scss';

const PlaybookComponent: React.FC = () => {
  const [itemToEdit, setItemToEdit] = useState<PlaybookItem | null>(null);
  const [itemToDelete, setItemToDelete] = useState<PlaybookItem | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isPlaybookDialogOpen, setPlaybookDialogOpen] = useState(false);
  const [isPlaybookItemDialogOpen, setPlaybookItemDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [symbol, setSymbol] = useState<string | null>(null);
  const dispatch = useStoreDispatch();
  const snackbar = useSnackbar();
  const selectedPlaybookItem = useStoreSelector(selectCurrentPlaybookItem);
  const selectedPlaybook = useStoreSelector(selectCurrentPlaybook);
  const open = Boolean(anchorEl);

  useEffect(() => {
    dispatch(loadPlaybooks({ symbol }));
  }, [symbol]);

  const handleChange =
    (item: PlaybookItem) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      const newEntry = isExpanded ? item : null;
      dispatch(setCurrentPlaybookItem(newEntry));
    };

  const onSaveNotes = (value: string) => {
    const item = { ...selectedPlaybookItem, notes: value } as PlaybookItem;
    dispatch(savePlaybookItem({ item: item, playbookId: selectedPlaybook!.id }))
      .unwrap()
      .then(() => {
        snackbar.showSnackbar({ message: 'Journal notes saved successfully', type: 'success' });
      });
  };

  const handleCreateNewPlaybook = (playbook: Playbook) => {
    dispatch(savePlaybook({ playbook }))
      .unwrap()
      .then(() => {
        setPlaybookDialogOpen(false);
        setItemToEdit(null);
        setItemToDelete(null);
        snackbar.showSnackbar({ message: 'Playbook saved successfully', type: 'success' });
      });
  };

  const handleSavePlaybookItem = (item: PlaybookItem) => {
    dispatch(savePlaybookItem({ item: item, playbookId: selectedPlaybook!.id }))
      .unwrap()
      .then(() => {
        setItemToEdit(null);
        setItemToDelete(null);
        setPlaybookItemDialogOpen(false);
        snackbar.showSnackbar({ message: 'Playbook Item saved successfully', type: 'success' });
      });
  };

  const handleDeleteItem = (item: PlaybookItem) => {
    setConfirmDialogOpen(true);
    setItemToDelete(item);
  };

  const handleDeleteCancel = () => {
    setConfirmDialogOpen(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirm = () => {
    if (itemToDelete) {
      dispatch(deletePlaybookItem({ item: itemToDelete }))
        .unwrap()
        .then(() => {
          snackbar.showSnackbar({
            message: 'Playbook Item deleted successfully',
            type: 'success'
          });
        });
    }
    setConfirmDialogOpen(false);
  };

  const handleEditItem = (item: PlaybookItem) => {
    setPlaybookItemDialogOpen(true);
    setItemToEdit(item);
  };

  const handleItemCancel = () => {
    setPlaybookItemDialogOpen(false);
    setItemToEdit(null);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenPlaybookDialog = () => {
    setPlaybookDialogOpen(true);
    setAnchorEl(null);
  };

  const handleOpenPlaybookItemDialog = () => {
    setPlaybookItemDialogOpen(true);
    setAnchorEl(null);
  };

  const handleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setSymbol(event.target.value);
  };

  return (
    <React.Fragment>
      <Paper className="page-header playbook-header" elevation={2}>
        <Typography variant="h6" sx={{ fontWeight: '500', flex: 1 }}>
          Playbook
        </Typography>
        <div className="right-panel">
          <TextField
            id="standard-basic"
            label="Search"
            placeholder="AAPL"
            variant="standard"
            onChange={handleFilter}
          />
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}>
            <MenuItem onClick={handleOpenPlaybookDialog}>New Playbook</MenuItem>
            <MenuItem onClick={handleOpenPlaybookItemDialog}>New Stock</MenuItem>
          </Menu>
        </div>
      </Paper>
      <Box
        className="playbook page-content"
        sx={{
          display: 'flex',
          flexDirection: 'row'
        }}>
        <PlaybookList />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5
          }}>
          {selectedPlaybook?.items &&
            selectedPlaybook?.items.map((item) => {
              const date = moment(item.date).format('LL');
              return (
                <Accordion
                  key={item.id}
                  sx={{ position: 'inherit' }}
                  expanded={selectedPlaybookItem?.id === item.id}
                  onChange={handleChange(item)}
                  TransitionProps={{ unmountOnExit: true }}>
                  <AppAccordionSummary
                    title={<Typography variant="subtitle1">{item.symbol}</Typography>}
                    subTitle={<Typography sx={{ fontSize: '12px' }}>{date}</Typography>}
                    rightElement={
                      <Box>
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            handleEditItem(item);
                          }}>
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDeleteItem(item);
                          }}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    }
                  />
                  <AppAccordionContent
                    date={item.date}
                    symbol={item.symbol}
                    notes={selectedPlaybookItem?.notes}
                    saveNotes={onSaveNotes}
                  />
                </Accordion>
              );
            })}
        </Box>

        <PlaybookFormDialog
          open={isPlaybookDialogOpen}
          onCancel={() => setPlaybookDialogOpen(false)}
          onSubmit={handleCreateNewPlaybook}
        />

        <PlaybookItemFormDialog
          itemToEdit={itemToEdit}
          open={isPlaybookItemDialogOpen}
          onCancel={handleItemCancel}
          onSubmit={handleSavePlaybookItem}
        />

        <ConfirmationDialog
          open={isConfirmDialogOpen}
          title="Delete image"
          text="Are you sure you want to permanently delete this image? This action cannot be undone."
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
        />

        <Fab onClick={() => setPlaybookItemDialogOpen(true)}></Fab>
      </Box>
    </React.Fragment>
  );
};

export default PlaybookComponent;

import { Paper } from '@mui/material';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import Currency from 'components/currency/currency';
import { AvgWinLoss } from 'models/avgWinLoss';
import { LoadingState } from 'models/loadingState';
import { useSnackbar } from 'providers/SnackbarProvider';
import React, { CSSProperties, useEffect, useState } from 'react';
import statsService from 'services/statsService';
import { selectCurrentAccount } from 'store/accountSlice';
import { useStoreSelector } from 'store/store';

const AvgWinLossChart: React.FC = () => {
  const [avg, setAvg] = useState<AvgWinLoss | null>(null);
  const [avgWinPercent, setAvgWinPercent] = useState(0);
  const [state, setState] = useState<LoadingState>(LoadingState.LOADING);
  const account = useStoreSelector(selectCurrentAccount);
  const snackbar = useSnackbar();

  const cardStyle: CSSProperties = {
    padding: '15px',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative'
  };

  useEffect(() => {
    statsService
      .avgWinLoss()
      .then((result) => {
        setState(LoadingState.SUCCEEDED);
        setAvg(result.data);
        if (result.data) {
          const win = Math.abs(result.data.avgWin);
          const loss = Math.abs(result.data.avgLoss);
          const avgWinPercent = (win / (loss + win)) * 100;
          setAvgWinPercent(avgWinPercent);
        }
      })
      .catch((error) => {
        setState(LoadingState.FAILED);
        snackbar.showSnackbar({ message: 'Failed to load Avg-Win-Loss Chart', type: 'error' });
      });
  }, [account]);

  return (
    <Paper className="avg-win-loss" elevation={2} sx={cardStyle}>
      {state === LoadingState.LOADING && <LoadingSpinner open={state === LoadingState.LOADING} />}
      {state === LoadingState.SUCCEEDED && (
        <React.Fragment>
          <div className="line">
            <div className="title">Avg. gain/loss</div>
          </div>
          <div className="line">
            {avg && (
              <React.Fragment>
                <div className="bar-container">
                  <div
                    className={`bar red  ${avg.avgWin === 0 ? 'round-corners' : ''}`}
                    style={{ width: `${100 - avgWinPercent}%` }}></div>
                  <div
                    className={`bar green } ${avg.avgLoss === 0 ? 'round-corners' : ''}`}
                    style={{ width: `${avgWinPercent}%` }}></div>
                </div>
                <div className="bar-labels">
                  <span>
                    <Currency price={avg.avgLoss}></Currency>
                  </span>
                  <span>
                    <Currency price={avg.avgWin}></Currency>
                  </span>
                </div>
              </React.Fragment>
            )}
          </div>
        </React.Fragment>
      )}
    </Paper>
  );
};

export default AvgWinLossChart;

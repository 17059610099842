import { Divider, IconButton, Paper, Popover, Typography } from '@mui/material';
import { Gapper } from 'models/gapper';
import moment from 'moment';
import React, { useState } from 'react';
import BarChartIcon from '@mui/icons-material/BarChart';
import './GapperDetails.scss';
import FullScreenChart from 'components/tradingview-charts/FullScreenChart';
import InfoIcon from '@mui/icons-material/Info';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import StarIcon from '@mui/icons-material/Star';

interface GapperDetailsProps {
  gapper: Gapper;
  markAsFavourite: (item: Gapper) => void;
}

const GapperDetails: React.FC<GapperDetailsProps> = ({ gapper, markAsFavourite }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const [chartOpen, setChartOpen] = useState<boolean>(false);
  const date = moment(gapper.date).format('LL');

  const handleOpenPR = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <Paper
        elevation={2}
        sx={{
          borderLeft: '5px solid',
          borderLeftColor: gapper.hodPercentage >= 100 ? 'green' : 'red'
        }}>
        <div
          className="gapper-card-header"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderBottom: '1px solid gray'
          }}>
          <div className="title">
            {gapper.symbol}
            <span style={{ fontSize: '11px', marginLeft: '5px' }}>{date}</span>
          </div>
          <div className="actions">
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                markAsFavourite(gapper);
              }}>
              <StarIcon sx={{ color: gapper.isFavorite ? '#FFD700' : '' }} />
            </IconButton>
            <IconButton>
              <InfoIcon />
            </IconButton>
            <IconButton onClick={() => setChartOpen(true)}>
              <BarChartIcon />
            </IconButton>
          </div>
        </div>
        <div className="gapper-content">
          <div className="summary">
            <div className={`circle ${gapper.news ? 'green' : 'default'}`} onClick={handleOpenPR}>
              PR
            </div>
            <div className={`circle ${gapper.hodPercentage > 100 ? 'green' : 'default'}`}>HOD</div>
            <div className={`circle ${gapper.gap > 100 ? 'green' : 'default'}`}>GAP</div>
          </div>
          <Divider orientation="vertical" flexItem />
          <Grid2 container spacing={1}>
            <Grid2 xs={3}>
              <div className="label">Gap:</div>
            </Grid2>
            <Grid2 xs={3}>{`${gapper.gap ? gapper.gap : 0}%`}</Grid2>

            <Grid2 xs={3}>
              {' '}
              <div className="label">Pr. Vol.:</div>
            </Grid2>
            <Grid2 xs={3}>{`${gapper.volumePremarket} m.`}</Grid2>
            <Grid2 xs={12}>
              <Divider />
            </Grid2>

            <Grid2 xs={3}>
              <div className="label">Volume:</div>
            </Grid2>
            <Grid2 xs={3}>{`${gapper.volume} m`}</Grid2>

            <Grid2 xs={3}>
              <div className="label">MarketCap:</div>
            </Grid2>
            <Grid2 xs={3}>{` ${gapper.marketCap} m`}</Grid2>
            <Grid2 xs={12}>
              <Divider />
            </Grid2>

            <Grid2 xs={3}>
              <div className="label">Float:</div>
            </Grid2>
            <Grid2 xs={3}>{`${gapper.float} m`}</Grid2>

            <Grid2 xs={3}>
              <div className="label">HOD %:</div>
            </Grid2>
            <Grid2 xs={3}>{`${gapper.hodPercentage ? gapper.hodPercentage : 'n/a'}`}</Grid2>
          </Grid2>
        </div>
      </Paper>

      <Popover
        id={String(gapper.id)}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}>
        <Typography sx={{ p: 2 }}>{gapper.news ? gapper.news : 'No News'}</Typography>
      </Popover>

      <FullScreenChart
        open={chartOpen}
        symbol={gapper.symbol}
        date={gapper.date}
        onClose={() => setChartOpen(false)}
      />
    </React.Fragment>
  );
};

export default GapperDetails;

import { Action, Middleware, ThunkDispatch, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { watch } from 'fs';
import { Sector } from 'models/sector';
import { WatchlistModel } from 'models/watchlist';
import { WatchlistEntry } from 'models/watchlistEntry';
import sectorService from 'services/sectorService';
import watchlistService from 'services/watchlistService';
import { RootState } from './store';
import moment from 'moment';
import { Ticker } from 'models/ticker';

interface WatchlistFilter {
  symbol?: string;
  date?: string;
}

export interface WatchlistState {
  watchlistList: WatchlistModel[];
  currentWatchlist: WatchlistModel | undefined;
  currentStock: Ticker | undefined;
  editingStock?: Ticker;
  sectors: Sector[];
  isLoading: boolean;
  filter: WatchlistFilter;
}

const initialState: WatchlistState = {
  watchlistList: [],
  currentWatchlist: undefined,
  currentStock: undefined,
  isLoading: false,
  sectors: [],
  filter: {
    symbol: '',
    date: ''
  }
};

export const loadSectors = createAsyncThunk('sectors/load', async () => {
  const response = await sectorService.load();
  return response.data;
});

export const loadWatchlist = createAsyncThunk<WatchlistModel[], void, { state: RootState }>(
  'watchlist/load',
  async (_, { getState }) => {
    const state = getState();
    const response = await watchlistService.loadWatchlist(
      state.watchlist.filter.symbol,
      state.watchlist.filter.date
    );
    return response.data;
  }
);

export const createWatchlist = createAsyncThunk('watchlist/create', async () => {
  const response = await watchlistService.create();
  return response.data;
});

export const createNewWatchlistItem = createAsyncThunk<
  Ticker,
  { item: string },
  { state: RootState }
>('watchlist/item/create', async ({ item }, { getState }) => {
  const watchlist = getState().watchlist.currentWatchlist;
  const response = await watchlistService.createWatchlistItem(item, watchlist!);
  return response.data;
});

export const saveWatchlistStock = createAsyncThunk<Ticker | null, Ticker, { state: RootState }>(
  'watchlist/item/save',
  async (ticker, { getState }) => {
    const { watchlist } = getState();
    if (watchlist.editingStock) {
      const tickerToSave = { ...watchlist.editingStock, note: ticker.note };
      const response = await watchlistService.saveStock(tickerToSave);
      return response.data;
    } else {
      return null;
    }
  }
);

export const deleteWatchlistItem = createAsyncThunk<
  { deletedItemId: number },
  { item: Ticker },
  { state: RootState }
>('watchlist/item/delete', async ({ item }, { getState }) => {
  const watchlist = getState().watchlist.currentWatchlist;
  const response = await watchlistService.deleteWatchlistItem(item, watchlist!.id);
  if (response.status === 200) {
    return { deletedItemId: item.id };
  }
  throw new Error('Failed to delete watchlist item ' + item.id);
});

export const watchlistSlice = createSlice({
  name: 'watchlist',
  initialState,
  reducers: {
    setCurrentWatchlist: (state, action) => {
      state.currentWatchlist = action.payload;
      if (state.currentWatchlist) {
        state.currentStock = state.currentWatchlist.watchlistStocks.at(0);
      } else {
        state.currentStock = undefined;
      }
    },
    setSymbol: (state, action) => {
      state.filter = { ...state.filter, symbol: action.payload };
    },
    setDate: (state, action) => {
      state.filter = { ...state.filter, date: action.payload };
    },
    setCurrentStock: (state, action) => {
      state.currentStock = action.payload;
    },
    setEditingStock: (state, action) => {
      state.editingStock = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(createWatchlist.fulfilled, (state, action) => {
        if (action.payload) {
          state.watchlistList.splice(0, 0, action.payload);
        }
      })
      .addCase(loadSectors.fulfilled, (state, action) => {
        if (action.payload) {
          state.sectors = action.payload;
        }
      })
      .addCase(createWatchlist.rejected, (state, action) => {
        console.log(action.error);
      })
      .addCase(loadWatchlist.fulfilled, (state, action) => {
        state.watchlistList = action.payload;
        if (state.watchlistList && state.watchlistList.length > 0) {
          state.currentWatchlist = state.watchlistList[0];
          state.currentStock = state.currentWatchlist.watchlistStocks.at(0);
        } else {
          state.currentWatchlist = undefined;
        }
      })
      .addCase(createNewWatchlistItem.fulfilled, (state, action) => {
        const watchlist = state.watchlistList.find((w) => w.id === state.currentWatchlist?.id);

        if (watchlist) {
          const index = watchlist.watchlistStocks.findIndex((s) => s.id === action.payload.id);
          if (index !== -1) {
            watchlist.watchlistStocks.splice(index, 1, action.payload);
          } else {
            watchlist.watchlistStocks.push(action.payload);
          }
        }

        if (state.currentWatchlist?.id === watchlist?.id) {
          state.currentWatchlist = watchlist;
        }

        state.currentStock = action.payload;
      })
      .addCase(deleteWatchlistItem.fulfilled, (state, action) => {
        const watchlist = state.currentWatchlist;
        const index = watchlist?.watchlistStocks.findIndex(
          (item) => item.id === action.payload.deletedItemId
        );
        if (watchlist !== undefined && index !== undefined && index > -1) {
          watchlist?.watchlistStocks.splice(index, 1);
          const watchlistIndex = state.watchlistList.findIndex((w) => w.id === watchlist?.id);
          state.watchlistList.splice(watchlistIndex, 1, watchlist);
        }

        if (state.currentStock?.id === action.payload.deletedItemId) {
          state.currentStock = undefined;
        }
      })
      .addCase(saveWatchlistStock.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        const watchlist = state.currentWatchlist;
        const index = watchlist?.watchlistStocks.findIndex(
          (item) => item.id === action?.payload?.id
        );

        if (watchlist !== undefined && index !== undefined) {
          if (index !== -1) {
            watchlist?.watchlistStocks.splice(index, 1, action?.payload);
          } else {
            watchlist.watchlistStocks.push(action.payload);
          }

          if (action.payload.id === state.currentStock?.id) {
            state.currentStock = action.payload;
          }
        }
      });
  }
});

export const selectWatchlistList = (state: { watchlist: WatchlistState }) =>
  state.watchlist.watchlistList;
export const selectCurrentWatchlist = (state: { watchlist: WatchlistState }) =>
  state.watchlist.currentWatchlist;
export const selectSectors = (state: { watchlist: WatchlistState }) => state.watchlist.sectors;

export const selectSymbol = (state: { watchlist: WatchlistState }) => state.watchlist.filter.symbol;

export const selectDate = (state: { watchlist: WatchlistState }) => state.watchlist.filter.date;

export const selectCurrentStock = (state: { watchlist: WatchlistState }) =>
  state.watchlist.currentStock;

export const { setCurrentWatchlist, setSymbol, setDate, setCurrentStock, setEditingStock } =
  watchlistSlice.actions;

export const watchlistMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  let result = next(action);

  if (action.type === 'watchlist/setSymbol' || action.type === 'watchlist/setDate') {
    (storeAPI.dispatch as ThunkDispatch<RootState, void, Action>)(loadWatchlist());
  }

  return result;
};

export default watchlistSlice.reducer;

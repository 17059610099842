import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { useRef } from 'react';
import deAT from 'date-fns/locale/de-AT';

registerLocale('de-AT', deAT);

interface DatePickerProps {
  control: any;
  name: string;
  errors: any;
  label?: string;
  placeholder?: string;
  showTime?: boolean;
  onChange?: (date: Date | null) => void;
}

const DatePicker: React.FC<DatePickerProps> = ({
  control,
  name,
  errors,
  label,
  placeholder,
  showTime = false,
  onChange
}) => {
  const datePickerRef = useRef<ReactDatePicker | null>(null);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <ReactDatePicker
          ref={datePickerRef}
          showTimeSelect={showTime}
          locale="de-AT"
          selected={field.value}
          timeIntervals={5}
          dateFormat={showTime ? 'MM/dd/yyyy HH:mm' : 'MM/dd/yyyy'}
          onChange={(date) => {
            field.onChange(date);
            if (onChange) {
              onChange(date);
            }
          }}
          isClearable
          className="datepicker"
          wrapperClassName="datepicker"
          placeholderText={placeholder || 'Select a date'}
          customInput={
            <TextField
              value={field.value}
              onClick={() => datePickerRef.current?.setOpen(true)}
              variant="standard"
              label={label || 'Select date'}
              error={errors[name] ? true : false}
              helperText={errors[name] && errors[name].message}
            />
          }
        />
      )}
    />
  );
};

export default DatePicker;

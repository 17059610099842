import { AccordionDetails, Grid } from '@mui/material';
import { useEffect } from 'react';
import 'quill/dist/quill.snow.css';
import { useDebouncedCallback } from 'use-debounce';
import CandleStickChart from 'components/tradingview-charts/CandleStickChart';
import TextEditor from 'components/shared/text-editor/TextEditor';

interface AppAccordionContentProps {
  date: Date;
  symbol: string;
  notes?: string;
  saveNotes?: (value: string) => void;
}

const AppAccordionContent: React.FC<AppAccordionContentProps> = ({
  date,
  symbol,
  notes,
  saveNotes
}) => {
  const onSave = (note: string) => {
    if (saveNotes) {
      saveNotes(note);
    }
  };

  return (
    <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Grid container spacing={2} sx={{ width: '100%', margin: 0 }}>
        <Grid item xs={12} sx={{ padding: '0 !important' }}>
          <CandleStickChart symbol={symbol} date={date} height="500px" />
        </Grid>
      </Grid>
      <TextEditor editorKey={date} onSave={onSave} notes={notes} />
    </AccordionDetails>
  );
};

export default AppAccordionContent;

import { Box, Card, CardContent, IconButton, Paper, Typography } from '@mui/material';
import moment from 'moment';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import './Calendar.scss';
import React, { useEffect, useState } from 'react';
import { DateUtils } from 'utils.ts/DateUtils';
import { CalendarModel } from 'models/calendar';
import { TradesByMonth } from 'models/tradeByMonth';
import Currency from 'components/currency/currency';

type GroupedDays = {
  [key: number]: CalendarModel[];
};

interface CalendarProps {
  tradesByMonth: TradesByMonth[];
  onMonthChanged: (month: number, year: number) => void;
}

const groupDaysByWeek = (days: CalendarModel[]): GroupedDays => {
  return days.reduce((acc, day) => {
    if (!acc[day.weekNumber]) {
      acc[day.weekNumber] = [];
    }
    acc[day.weekNumber].push(day);
    return acc;
  }, {} as GroupedDays);
};

const createDaysOfMonth = (year: number, month: number) => {
  const firstDayOfMonth = (new Date(year, month, 1).getDay() || 7) - 1;
  const lastDayOfMonth = (new Date(year, month + 1, 0).getDay() || 7) - 1;
  const daysFromNextMonthCount = (7 - (lastDayOfMonth + 1)) % 7;

  const daysFromPevMonth =
    firstDayOfMonth > 0 ? DateUtils.getDaysFromPreviousMonth(year, month, firstDayOfMonth) : [];
  const daysOfCurrentMonth = DateUtils.getDaysOfCurrentMonth(year, month);
  const daysOfNextMonth =
    daysFromNextMonthCount > 0
      ? DateUtils.getDaysFromNextMonth(year, month, daysFromNextMonthCount, lastDayOfMonth)
      : [];
  const days = [...daysFromPevMonth, ...daysOfCurrentMonth, ...daysOfNextMonth];
  return groupDaysByWeek(days);
};

const Calendar: React.FC<CalendarProps> = ({ tradesByMonth, onMonthChanged }) => {
  const [monthPnl, setMonthPnl] = useState<number | null>(null);
  const [days, setDays] = useState<GroupedDays | null>(null);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [month, setMonth] = useState<number>(new Date().getMonth());

  useEffect(() => {
    setDays(createDaysOfMonth(year, month));
    onMonthChanged(month, year);
  }, [year, month]);

  useEffect(() => {
    if (tradesByMonth) {
      setMonthPnl(tradesByMonth.reduce((acc, month) => acc + (month.totalNetPnl || 0), 0));
    }
  }, [tradesByMonth]);

  const handleNextMonth = () => {
    if (month + 1 > 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const handlePreviousMonth = () => {
    if (month - 1 < 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  return (
    <Paper className="trade-calendar" elevation={2} sx={{ padding: '5px' }}>
      <div
        className="title"
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <div
          className="month"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}>
          <div className="actions">
            <IconButton onClick={handlePreviousMonth} aria-label="previous">
              <ArrowLeftIcon fontSize="large" />
            </IconButton>
            <IconButton onClick={handleNextMonth} aria-label="next">
              <ArrowRightIcon fontSize="large" />
            </IconButton>
          </div>
          <h2 className="date">{moment([year, month, 1]).format('MMMM YYYY')}</h2>
        </div>
        <div className={`month-pnl ${monthPnl ? (monthPnl > 0 ? 'positive' : 'negative') : ''}`}>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '20px' }}>
            {monthPnl ? <Currency price={monthPnl}></Currency> : ''}
          </Typography>
        </div>
      </div>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr) 0.9fr',
          gap: '5px'
        }}>
        {DateUtils.daysOfWeek.map((day) => (
          <Paper elevation={1} key={day}>
            <Typography
              variant="subtitle1"
              key={day}
              sx={{ textAlign: 'center', fontWeight: 'bold' }}>
              {day}
            </Typography>
          </Paper>
        ))}
        <Paper elevation={1} sx={{ backgroundColor: '#eeededc2' }}>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: '12px' }}>
            Weekly Totals
          </Typography>
        </Paper>
      </div>
      <div
        style={{
          marginTop: '5px',
          display: 'grid',
          gridTemplateColumns: 'repeat(7, 1fr) 0.9fr',
          gap: '5px'
        }}>
        {days &&
          Object.entries(days).map(([weekNumber, weekDays]) => {
            const weekTotalPnl = tradesByMonth
              .filter((t) => moment(t.date).isoWeek() === Number(weekNumber))
              .reduce((acc, t) => acc + (t.totalNetPnl || 0), 0);

            const weekTotalTrades = tradesByMonth
              .filter((t) => moment(t.date).isoWeek() === Number(weekNumber))
              .reduce((acc, t) => acc + (t.tradeCount || 0), 0);

            return (
              <React.Fragment>
                {weekDays.map((day) => {
                  const trade = tradesByMonth.find((t) => {
                    const formattedTradeDate = moment(t.date).format('YYYY-MM-DD');
                    const formattedDayDate = moment(day.date).format('YYYY-MM-DD');
                    return formattedTradeDate === formattedDayDate;
                  });

                  return (
                    <React.Fragment>
                      <Card
                        className={`day ${
                          trade?.totalNetPnl
                            ? trade?.totalNetPnl > 0
                              ? 'positive'
                              : 'negative'
                            : ''
                        }`}
                        key={day.dayNumber + day.dayOfWeek}
                        sx={{ height: '80px' }}>
                        <CardContent
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'space-between',
                            padding: '10px !important'
                          }}>
                          <div className="day-number" key={`${day.dayNumber}-${day.isOutside}`}>
                            {day.isOutside ? '' : day.dayNumber}
                          </div>
                          {trade?.totalNetPnl && (
                            <Box sx={{ textAlign: 'right' }}>
                              <div className="pnl">
                                <Currency price={trade.totalNetPnl}></Currency>
                              </div>
                              <div className="trade-count">Trades: {trade.tradeCount}</div>
                            </Box>
                          )}
                        </CardContent>
                      </Card>
                    </React.Fragment>
                  );
                })}
                {
                  <Card
                    className={'week'}
                    key={weekNumber}
                    sx={{ height: '80px', backgroundColor: '#eeededc2' }}>
                    <CardContent
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'space-between',
                        padding: '10px !important'
                      }}>
                      {weekTotalTrades > 0 ? (
                        <Box sx={{ textAlign: 'right' }}>
                          <div
                            className={`pnl ${
                              weekTotalPnl ? (weekTotalPnl > 0 ? 'positive' : 'negative') : ''
                            }`}>
                            <Currency price={weekTotalPnl}></Currency>
                          </div>
                          <div className="trade-count">Trades: {weekTotalTrades}</div>
                        </Box>
                      ) : (
                        ''
                      )}
                    </CardContent>
                  </Card>
                }
              </React.Fragment>
            );
          })}
        {/* {days && Object.entries(days).map(([weekNumber, weekDays]) => {
          const trade = tradesByMonth.find((t) => {
            const formattedTradeDate = moment(t.date).format('YYYY-MM-DD');
            const formattedDayDate = moment(day.date).format('YYYY-MM-DD');
            return formattedTradeDate === formattedDayDate;
          });
          return (
            <Card
              className={`day ${
                trade?.totalNetPnl ? (trade?.totalNetPnl > 0 ? 'positive' : 'negative') : ''
              }`}
              key={day.dayNumber + day.dayOfWeek}
              sx={{ height: '80px' }}>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'space-between',
                  padding: '10px !important'
                }}>
                <div
                  className="day-number"
                  key={`${day.dayNumber}-${day.isOutside}`}
                  style={{ opacity: day.isOutside ? 0.5 : 1 }}>
                  {day.dayNumber}
                </div>
                {trade?.totalNetPnl && (
                  <Box sx={{ textAlign: 'right' }}>
                    <div className="pnl">
                      <Currency price={trade.totalNetPnl}></Currency>
                    </div>
                    <div className="trade-count">Trades: {trade.tradeCount}</div>
                  </Box>
                )}
              </CardContent>
            </Card>
          );
        })} */}
      </div>
    </Paper>
  );
};

export default Calendar;

import axios, { AxiosResponse } from 'axios';
import { Playbook } from 'models/playbook';

class PlaybookService {
  async loadPlaybooks(symbol: string | null): Promise<AxiosResponse<Playbook[]>> {
    const queryParams = {
      symbol: symbol
    };
    return await axios.get(`${process.env.REACT_APP_API_URL}/playbook`, {
      params: queryParams
    });
  }

  async save(playbook: Playbook): Promise<AxiosResponse<Playbook>> {
    try {
      if (playbook.id) {
        return await axios.put(`${process.env.REACT_APP_API_URL}/playbook/${playbook.id}`, {
          playbook: playbook
        });
      } else {
        return await axios.post(`${process.env.REACT_APP_API_URL}/playbook`, {
          playbook: playbook
        });
      }
    } catch (error) {
      throw error;
    }
  }
}

export default new PlaybookService();

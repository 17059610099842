import {
  AppBar,
  Box,
  Divider,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Toolbar,
  useTheme
} from '@mui/material';
import Typography from '@mui/material/Typography';
import TagIcon from '@mui/icons-material/Tag';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Menu from '@mui/material/Menu';
import React, { CSSProperties } from 'react';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import { selectUser, logout } from 'store/userSlice';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.svg';
import { changeDefaultAccount, selectAccounts, selectCurrentAccount } from 'store/accountSlice';
import './Header.scss';
import dataService from 'services/dataService';

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

const Header: React.FC = () => {
  const user = useStoreSelector(selectUser);
  const accounts = useStoreSelector(selectAccounts);
  const currentAccount = useStoreSelector(selectCurrentAccount);
  const dispatch = useStoreDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout())
      .unwrap()
      .then(() => navigate('/login'))
      .catch((error) => console.error('Logout failed', error));
  };

  const handleProfile = () => {
    navigate('/profile');
    handleClose();
  };

  const handleTags = () => {
    navigate('/tags');
    handleClose();
  };

  const handleChangeCurrentAccount = (event: SelectChangeEvent) => {
    dispatch(changeDefaultAccount(event.target.value));
  };

  const handleLoadData = () => {
    dataService.loadData().then(() => console.log('Data loaded'));
  };

  const handleUploadData = () => {
    navigate('/upload-data');
    handleClose();
  };

  const logoStyle: CSSProperties = {
    width: '40px',
    height: '40px'
  };

  return (
    <header className="header">
      <AppBar position="static">
        <Toolbar
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            minHeight: '50px !important',
            paddingLeft: '5px !important'
          }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
            <img src={logo} style={logoStyle} />
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Trade Tracker
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            {accounts && (
              <FormControl variant="standard" sx={{ m: 1, width: 150, color: 'white' }}>
                <InputLabel id="account-select-label">Account</InputLabel>
                <Select
                  labelId="account-select-label"
                  id="account-select"
                  value={currentAccount ? String(currentAccount.id) : 'all'}
                  onChange={handleChangeCurrentAccount}
                  label="Account">
                  <MenuItem value="all">
                    <em>All</em>
                  </MenuItem>
                  {accounts.map((account) => (
                    <MenuItem key={account.id} value={account.id}>
                      {account.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {user && (
              <Box>
                <IconButton
                  size="large"
                  aria-label="user account"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit">
                  <AccountCircle />
                </IconButton>

                <Menu
                  id="menu-appbar"
                  style={{ width: '200px' }}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}>
                  <MenuItem disableRipple style={{ cursor: 'default' }}>
                    {user.firstName} {user.lastName}
                  </MenuItem>
                  <Divider />
                  <MenuItem>
                    <ListItemIcon>
                      <SettingsIcon />
                    </ListItemIcon>
                    <ListItemText>Settings</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleProfile}>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText>Profile</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleTags}>
                    <ListItemIcon>
                      <TagIcon />
                    </ListItemIcon>
                    <ListItemText>Tags</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={colorMode.toggleColorMode}>
                    <ListItemIcon>
                      {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                    </ListItemIcon>
                    <ListItemText>
                      {theme.palette.mode === 'dark' ? 'Dark' : 'Light'} Mode
                    </ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleLoadData}>
                    <ListItemIcon>
                      <CloudDownloadIcon />
                    </ListItemIcon>
                    <ListItemText>Load Data</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleUploadData}>
                    <ListItemIcon>
                      <CloudUploadIcon />
                    </ListItemIcon>
                    <ListItemText>Upload Data</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </header>
  );
};

export default Header;

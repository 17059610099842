import { Button, Divider, List, ListItemButton } from '@mui/material';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import {
  createPage,
  deletePage,
  savePage,
  selectCurrentPage,
  selectPages,
  setCurrentPage
} from 'store/notebookSlice';
import { NotebookPage } from 'models/notebook-page';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PageItem from './PageItem';

const NotebookPages: React.FC = () => {
  const pages = useStoreSelector(selectPages);
  const selectedPage = useStoreSelector(selectCurrentPage);

  const dispatch = useStoreDispatch();

  const handleCreateNewPage = () => {
    dispatch(createPage());
  };

  const handleSavePage = (page: NotebookPage) => {
    dispatch(savePage(page));
  };

  const handleDeletePage = (page: NotebookPage) => {
    dispatch(deletePage(page));
  };

  const handleSelectPage = (page: NotebookPage) => {
    dispatch(setCurrentPage(page));
  };

  return (
    <List
      className="notebook-pages"
      sx={{ height: '100%', overflow: 'auto', paddingTop: 0, paddingBottom: 0 }}>
      <div className="notebook-pages-header">
        <Button
          variant="text"
          size="small"
          startIcon={<NoteAddIcon />}
          onClick={handleCreateNewPage}>
          New Page
        </Button>
        <Divider />
      </div>
      {pages &&
        pages.map((page) => {
          return (
            <ListItemButton
              disableRipple
              key={page.id}
              onClick={() => handleSelectPage(page)}
              className={selectedPage === page ? 'selected' : ''}
              divider={false}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                flexGrow: 0,
                padding: '0 6px 0 6px'
              }}>
              <PageItem page={page} onDelete={handleDeletePage} onSave={handleSavePage} />
            </ListItemButton>
          );
        })}
    </List>
  );
};

export default NotebookPages;

import React, { useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarElement
} from 'chart.js';
import './Reports.scss';
import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';
import DaysTimesTab from './DaysTimesTab';
import PriceVolumeTab from './PriceVolumeTab';
import PnlToDateTab from './PnlToDateTab';

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const Reports: React.FC = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const a11yProps = (index: number) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`
    };
  };

  return (
    <React.Fragment>
      <Paper className="page-header reports-page" elevation={2}>
        <Typography variant="h6" sx={{ fontWeight: '500' }}>
          Reports
        </Typography>
      </Paper>
      <Box className="page-content">
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="PNL To Date" {...a11yProps(0)} />
            <Tab label="Days/Times" {...a11yProps(1)} />
            <Tab label="Price/Volume" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <PnlToDateTab value={value} index={0} />
        <DaysTimesTab value={value} index={1} />
        <PriceVolumeTab value={value} index={2} />
      </Box>
    </React.Fragment>
  );
};

export default Reports;

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Tag } from 'models/tag';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

interface TagsFormDialogProps {
  tagToEdit?: Tag | null;
  open: boolean;
  onSubmit: (tag: Tag) => void;
  onCancel: () => void;
}

interface TagForm {
  id: number | undefined;
  name: string;
}

const TagsFormDialog: React.FC<TagsFormDialogProps> = ({ tagToEdit, open, onSubmit, onCancel }) => {
  const validationSchema = Yup.object().shape({
    id: Yup.number(),
    name: Yup.string().required('Name is required')
  });

  const textFieldStyle = {
    marginTop: '0px',
    marginBottom: '0px'
  };

  useEffect(() => {
    if (tagToEdit) {
      const defalutValues = {
        id: tagToEdit.id,
        name: tagToEdit.name
      };
      reset(defalutValues);
    } else {
      resetForm();
    }
  }, [tagToEdit]);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<TagForm>({
    resolver: yupResolver(validationSchema)
  });

  const submitForm = (data: TagForm) => {
    const item = { ...data } as Tag;
    resetForm();
    onSubmit(item);
  };

  const resetForm = () => {
    reset({
      id: undefined,
      name: ''
    });
  };
  return (
    <Dialog open={open} className="profile-form" disableEscapeKeyDown>
      <form noValidate onSubmit={handleSubmit(submitForm)}>
        <DialogTitle>Tag</DialogTitle>
        <DialogContent>
          <Grid2 container columnSpacing={2}>
            <Grid2 xs={12}>
              <TextField
                placeholder="Name"
                label="Name"
                type="text"
                variant="standard"
                fullWidth
                {...register('name')}
                error={errors.name ? true : false}
                helperText={errors.name && errors.name?.message}
                style={textFieldStyle}
                autoFocus
              />
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              onClick={onCancel}
              variant="contained"
              color="gray"
              fullWidth
              sx={{ flexGrow: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" fullWidth sx={{ flexGrow: 1 }}>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TagsFormDialog;

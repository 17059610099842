import { Box, Paper, Typography } from '@mui/material';
import Fab from 'components/shared/fab/Fab';
import { DropDownOption } from 'components/shared/select-dropdown/SelectDropDown';
import React, { useState } from 'react';
import UploadDataDialog from './UploadDataDialog';
import dataService from 'services/dataService';
import { useSnackbar } from 'providers/SnackbarProvider';

const UploadData: React.FC = () => {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const { showSnackbar } = useSnackbar();

  const handleUpload = (files: FileList, interval: string, symbol: string) => {
    dataService
      .uploadData(files[0], interval, symbol)
      .then(() => {
        setDialogOpen(false);
        showSnackbar({ message: 'Data uploaded successfully.', type: 'success' });
      })
      .catch(() => {
        showSnackbar({ message: 'Failed to upload data', type: 'error' });
      });
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Paper className="page-header" elevation={2}>
        <Typography variant="h6" sx={{ fontWeight: '500' }}>
          Upload Data
        </Typography>
      </Paper>

      <Box className="page-content profile"></Box>

      <Fab onClick={handleOpenDialog}></Fab>
      <UploadDataDialog
        accept=".csv,.json"
        title="Upload Trades"
        isOpen={isDialogOpen}
        onUpload={handleUpload}
        onClose={handleCloseDialog}
      />
    </React.Fragment>
  );
};

export default UploadData;

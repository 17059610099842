import { IconButton, ListItemText, Rating } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { Ticker } from 'models/ticker';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

interface TickerListItemProps {
  ticker: Ticker;
  onDelete: (ticker: Ticker, event: React.MouseEvent) => void;
  onSetHot: (ticker: Ticker, event: React.MouseEvent) => void;
}

const TickerListItem: React.FC<TickerListItemProps> = ({ ticker, onDelete, onSetHot }) => {
  const secondaryProps = {
    fontSize: 11
  };

  const handleDelete = (event: React.MouseEvent) => {
    onDelete(ticker, event);
  };

  const handleSetHot = (event: React.MouseEvent) => {
    onSetHot({ ...ticker, hot: !ticker.hot }, event);
  };

  return (
    <React.Fragment>
      <ListItemText
        primary={ticker.ticker}
        secondaryTypographyProps={secondaryProps}></ListItemText>
      <Rating value={ticker.rating} size="small" readOnly></Rating>
      <div className="menu">
        <IconButton onClick={handleDelete}>
          <DeleteIcon fontSize="small" />
        </IconButton>
      </div>
      <div className="hot-ticker">
        <IconButton onClick={handleSetHot}>
          {ticker.hot && (
            <React.Fragment>
              <svg width={0} height={0}>
                <linearGradient id="fireGradient" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" style={{ stopColor: '#FF0000' }} />
                  <stop offset="40%" style={{ stopColor: '#FF4500' }} />
                  <stop offset="70%" style={{ stopColor: '#FF7F00' }} />
                  <stop offset="90%" style={{ stopColor: '#FFA500' }} />
                  <stop offset="100%" style={{ stopColor: '#FFD700' }} />
                </linearGradient>
              </svg>
              <LocalFireDepartmentIcon sx={{ fill: 'url(#fireGradient)' }} />
            </React.Fragment>
          )}
          {!ticker.hot && <LocalFireDepartmentIcon />}
        </IconButton>
      </div>
    </React.Fragment>
  );
};

export default TickerListItem;

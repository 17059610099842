import {
  FormControl,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

interface GapperPaginationProps {
  lastPage: number | undefined;
  defaultRowsPerPage: string;
  rowsPerPageChanged: (rows: string) => void;
  pageChanged: (page: number) => void;
}

const GapperPagination: React.FC<GapperPaginationProps> = ({
  lastPage,
  defaultRowsPerPage,
  rowsPerPageChanged,
  pageChanged
}) => {
  const handleRowsPerPageChange = (event: SelectChangeEvent) => {
    rowsPerPageChanged(event.target.value);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    pageChanged(value);
  };
  return (
    <React.Fragment>
      <FormControl
        sx={{
          m: 1,
          minWidth: 80,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        Rows per page:
        <Select
          id="pagination-rows-per-page"
          value={defaultRowsPerPage}
          onChange={handleRowsPerPageChange}
          autoWidth
          variant="outlined"
          label="Rows per page"
          sx={{ border: 'none', '& fieldset': { border: 'none' } }}>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </FormControl>
      <Pagination
        count={lastPage}
        showFirstButton
        showLastButton
        siblingCount={5}
        onChange={handleChangePage}
        renderItem={(item) => (
          <PaginationItem
            component={Link}
            to={`/gappers${item.page === 1 ? '' : `?page=${item.page}`}`}
            {...item}></PaginationItem>
        )}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      />
    </React.Fragment>
  );
};

export default GapperPagination;

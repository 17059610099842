import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Order } from 'components/table-header/tableHeader';
import Trade from 'models/trade';
import { TradesByMonth as TradesByMonth } from 'models/tradeByMonth';
import tradesService from 'services/tradesService';

export interface TradesState {
  trades: Trade[];
  currentTrade?: Trade;
  tradesByMonth: TradesByMonth[];
}

const initialState: TradesState = {
  trades: [],
  currentTrade: undefined,
  tradesByMonth: []
};

export const loadTrades = createAsyncThunk<
  Trade[],
  { symbol: string; sortBy: string; sortOrder: Order }
>('trades/load', async ({ symbol, sortBy, sortOrder }, thunkApi) => {
  try {
    const response = await tradesService.loadTrades(symbol, sortBy, sortOrder);
    return response.data;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

export const loadTradeById = createAsyncThunk<Trade, number>('trades/loadById', async (tradeId) => {
  const response = await tradesService.loadTradeById(tradeId);
  return response.data;
});

export const uploadTrades = createAsyncThunk<Trade[], { file: File; accountId: number }>(
  'trades/upload',
  async ({ file, accountId }) => {
    const response = await tradesService.uploadTrades(file, accountId);
    return response.data;
  }
);

export const loadTradesByMonth = createAsyncThunk<TradesByMonth[], { month: number; year: number }>(
  'trades/byMonth',
  async ({ month, year }) => {
    const response = await tradesService.loadTradesByMonth(year, month);
    return response.data;
  }
);

export const updateTrade = createAsyncThunk<Trade, Trade>('trades/update', async (trade: Trade) => {
  const response = await tradesService.update(trade);
  return response.data;
});

export const tradesSlice = createSlice({
  name: 'trades',
  initialState,
  reducers: {
    setCurrentTrade: (state, action) => {
      state.currentTrade = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loadTrades.fulfilled, (state, action) => {
        state.trades = action.payload;
      })
      .addCase(loadTradeById.fulfilled, (state, action) => {
        state.currentTrade = action.payload;
      })
      .addCase(loadTrades.rejected, (state, action) => {
        console.log('rejected', action);
      })
      .addCase(loadTradesByMonth.fulfilled, (state, action) => {
        state.tradesByMonth = action.payload;
      })
      .addCase(loadTradesByMonth.rejected, (state, action) => {
        console.log('trade by month', action);
      })
      .addCase(updateTrade.fulfilled, (state, action) => {
        const index = state.trades.findIndex((tr) => tr.id === action.payload.id);
        if (index !== -1) {
          state.trades.splice(index, 1, action.payload);
        }
      });
  }
});

export const selectTrades = (state: { trades: TradesState }) => state.trades.trades;
export const selectCurrentTrade = (state: { trades: TradesState }) => state.trades.currentTrade;
export const selectTradesByMonth = (state: { trades: TradesState }) => state.trades.tradesByMonth;

export const { setCurrentTrade } = tradesSlice.actions;

export default tradesSlice.reducer;

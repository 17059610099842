import { Backdrop, CircularProgress, Paper } from '@mui/material';
import React, { CSSProperties, useEffect, useState } from 'react';
import './WeeklyPnl.scss';
import { DateUtils } from 'utils.ts/DateUtils';
import moment from 'moment';
import Currency from 'components/currency/currency';
import { Pie } from 'react-chartjs-2';
import statsService from 'services/statsService';
import { WeekPnl } from 'models/weekPnl';
import { useSnackbar } from 'providers/SnackbarProvider';
import { LoadingState } from 'models/loadingState';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { useStoreSelector } from 'store/store';
import { selectCurrentAccount } from 'store/accountSlice';

const backgroundColor = [
  'rgba(255, 99, 132, 0.5)',
  'rgba(54, 162, 235, 0.5)',
  'rgba(255, 206, 86, 0.5)',
  'rgba(75, 192, 192, 0.5)',
  'rgba(153, 102, 255, 0.5)',
  'rgba(255, 159, 64, 0.5)'
];

const borderColor = [
  'rgba(255, 99, 132, 1)',
  'rgba(54, 162, 235, 1)',
  'rgba(255, 206, 86, 1)',
  'rgba(75, 192, 192, 1)',
  'rgba(153, 102, 255, 1)',
  'rgba(255, 159, 64, 1)'
];

const defaultData = {
  datasets: [
    {
      data: [1],
      backgroundColor: ['#cccccc'],
      borderWidth: 0
    }
  ]
};

const WeeklyPnl: React.FC = () => {
  const { start, end } = DateUtils.getCurrentWeek();
  const [stats, setStats] = useState<WeekPnl[]>([]);
  const [price, setPrice] = useState(0);
  const [data, setData] = useState<any>(null);
  const [state, setState] = useState<LoadingState>(LoadingState.LOADING);
  const snackbar = useSnackbar();
  const account = useStoreSelector(selectCurrentAccount);

  const options = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: function (context: any) {
            if (stats && stats.length > 0) {
              return moment(stats[context[0].dataIndex].date).format('DD.MM.YYYY');
            }
            return 'No Data';
          },
          label: function (context: any) {
            if (stats && stats.length > 0) {
              return `$ ${stats[context.dataIndex].pnl}`;
            }
            return '';
          }
        }
      }
    }
  };

  const cardStyle: CSSProperties = {
    padding: '15px',
    height: '100px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative'
  };

  const setPriceAndData = (pnl: WeekPnl[]) => {
    const price = pnl.reduce((sum, stat) => {
      return sum + stat.pnl;
    }, 0);

    const data = {
      labels: pnl.map((stat) => stat.date),
      datasets: [
        {
          data: pnl.map((stat) => stat.pnl),
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          borderWidth: 0
        }
      ]
    };
    setData(data);
    setPrice(price);
  };

  useEffect(() => {
    statsService
      .weeklyPnl()
      .then((response) => {
        setState(LoadingState.SUCCEEDED);
        setStats(response.data);
        if (response.data && response.data.length > 0) {
          setPriceAndData(response.data);
        } else {
          setData(null);
        }
      })
      .catch((err) => {
        setState(LoadingState.FAILED);
        setData(defaultData);
        snackbar.showSnackbar({ message: 'Failed to load weekly pnl', type: 'error' });
      });
  }, [account]);

  return (
    <Paper className="weekly-pnl" elevation={2} sx={cardStyle}>
      {state === LoadingState.LOADING && <LoadingSpinner open={state === LoadingState.LOADING} />}
      {state === LoadingState.SUCCEEDED && (
        <React.Fragment>
          <div className="left">
            <div className="title">Weeks Net P&L</div>
            <div className="subtitle">
              {moment(start).format('DD.MM')} - {moment(end).format('DD.MM.YYYY')}
            </div>
            <div className={`pnl ${price > 0 ? 'green' : 'red'}`}>
              <Currency price={price}></Currency>
            </div>
          </div>
          <div className="chart">
            {data ? (
              <Pie data={data} options={options} width={100} height={100} />
            ) : (
              <div
                className="default-pie-chart"
                style={{
                  width: '100px',
                  height: '100px',
                  backgroundColor: '#cccccc',
                  borderRadius: '50%'
                }}
              />
            )}
          </div>
        </React.Fragment>
      )}
    </Paper>
  );
};

export default WeeklyPnl;

import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

export interface HeaderCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

export type Order = 'asc' | 'desc';

interface TableProps {
  onRequestSort?: (event: React.MouseEvent, property: string) => void;
  order?: Order;
  orderBy?: string;
  headerCells: readonly HeaderCell[];
  hasActionColumn?: boolean;
  collapsible?: boolean;
}

export const TableHeader = (props: TableProps) => {
  const { order, orderBy, headerCells, hasActionColumn, collapsible = false } = props;

  const sortHandler = (property: string) => (event: React.MouseEvent) => {
    if (props.onRequestSort) {
      props.onRequestSort(event, property);
    }
  };

  return (
    <TableHead>
      <TableRow>
        {collapsible && <TableCell />}
        {headerCells.map((cell) => (
          <TableCell
            key={cell.id}
            align={cell.numeric ? 'right' : 'left'}
            padding={cell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === cell.id ? order : false}>
            <TableSortLabel
              active={orderBy === cell.id}
              direction={orderBy === cell.id ? order : 'asc'}
              onClick={sortHandler(cell.id)}>
              {cell.label}
              {orderBy === cell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {hasActionColumn && <TableCell></TableCell>}
      </TableRow>
    </TableHead>
  );
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Notebook } from 'models/notebook';
import { NotebookPage } from 'models/notebook-page';
import { NotebookSection } from 'models/notebook-section';
import notebookService from 'services/notebookService';
import { RootState } from './store';
import { Stock } from 'models/stock';

export interface NotebookState {
  notebook?: Notebook;
  pages: NotebookPage[];
  currentSection?: NotebookSection;
  currentPage?: NotebookPage;
  editingPage?: NotebookPage;
}

const initialState: NotebookState = {
  notebook: undefined,
  pages: [],
  currentSection: undefined,
  editingPage: undefined
};

export const loadNotebook = createAsyncThunk<Notebook, { symbol: string | null }>(
  'notebook/load',
  async ({ symbol }) => {
    const response = await notebookService.loadNotebook(symbol);
    return response.data;
  }
);

export const saveSection = createAsyncThunk<NotebookSection, NotebookSection>(
  'notebook/section/update',
  async (section) => {
    const response = await notebookService.updateSection(section);
    return response.data;
  }
);

export const createSection = createAsyncThunk<NotebookSection>(
  'notebook/section/create',
  async () => {
    const response = await notebookService.createSection();
    return response.data;
  }
);

export const deleteSection = createAsyncThunk<NotebookSection, NotebookSection>(
  'notebook/section/delete',
  async (section) => {
    const response = await notebookService.deleteSection(section);
    if (response.status === 200) {
      return section;
    }
    throw new Error('Failed to delete playbook item ' + section.id);
  }
);

export const createPage = createAsyncThunk<NotebookPage, void, { state: RootState }>(
  'notebook/section/page/create',
  async (_, { getState }) => {
    const state = getState();
    const response = await notebookService.createPage(state.notebook.currentSection!.id);
    return response.data;
  }
);

export const savePage = createAsyncThunk<NotebookPage, NotebookPage>(
  'notebook/section/page/save',
  async (page) => {
    const response = await notebookService.savePage(page);
    return response.data;
  }
);

export const saveNote = createAsyncThunk<NotebookPage | null, string, { state: RootState }>(
  'notebook/section/page/save-note',
  async (note, { getState }) => {
    const { notebook } = getState();
    if (notebook.editingPage) {
      const page = { ...notebook.editingPage, note: note };
      const response = await notebookService.savePage(page);
      return response.data;
    } else {
      return null;
    }
  }
);

export const deletePage = createAsyncThunk<NotebookPage, NotebookPage>(
  'notebook/section/page/delete',
  async (page) => {
    const response = await notebookService.deletePage(page.id);
    if (response.status === 200) {
      return page;
    }
    throw new Error('Failed to delete playbook item ' + page.id);
  }
);

export const loadPages = createAsyncThunk<NotebookPage[], void, { state: RootState }>(
  'notebook/section/page/load',
  async (_, { getState }) => {
    const { notebook } = getState();
    const response = await notebookService.loadPages(notebook.currentSection!.id);
    return response.data;
  }
);

export const saveStock = createAsyncThunk<Stock, Stock, { state: RootState }>(
  'notebook/section/page/stock/save',
  async (stock, { getState }) => {
    const { notebook } = getState();
    const response = await notebookService.saveStock(stock, notebook.currentPage!.id);
    return response.data;
  }
);

export const deleteStock = createAsyncThunk<Stock, Stock>(
  'notebook/section/page/stock/delete',
  async (stock) => {
    const response = await notebookService.deleteStock(stock.id);
    if (response.status === 200) {
      return stock;
    }
    throw new Error('Failed to delete playbook item ' + stock.id);
  }
);

const updateSection = (state: NotebookState, section: NotebookSection) => {
  if (state.notebook) {
    const index = state.notebook.sections.findIndex((s) => s.id === section.id);
    if (index >= 0) {
      state.notebook = {
        ...state.notebook,
        sections: [
          ...state.notebook.sections.slice(0, index),
          section,
          ...state.notebook.sections.slice(index + 1)
        ]
      };
    } else {
      state.notebook = {
        ...state.notebook,
        sections: [...state.notebook.sections, section]
      };
    }
  }
};

export const notebookSlice = createSlice({
  name: 'notebook',
  initialState,
  reducers: {
    setCurrentSection: (state, action) => {
      state.currentSection = action.payload;
      state.currentPage = undefined;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setEditingPage: (state, action) => {
      state.editingPage = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loadNotebook.fulfilled, (state, action) => {
        state.notebook = action.payload;
        if (state.notebook.sections && state.notebook.sections.length > 0) {
          state.currentSection = state.notebook.sections[0];
        }
      })
      .addCase(loadPages.fulfilled, (state, action) => {
        state.pages = action.payload;
        if (action.payload.length > 0) {
          state.currentPage = action.payload[0];
        }
      })
      .addCase(createPage.fulfilled, (state, action) => {
        if (!state.pages) {
          state.pages = [];
        }
        state.pages.splice(0, 0, action.payload);

        if (state.pages.length === 1) {
          state.currentPage = action.payload;
        }
      })
      .addCase(savePage.fulfilled, (state, action) => {
        const pageIndex = state.pages.findIndex((p) => p.id === action.payload.id);
        if (pageIndex > -1) {
          state.pages.splice(pageIndex, 1, action.payload);
        }
        if (state.currentPage?.id === action.payload.id) {
          state.currentPage = action.payload;
        }
      })
      .addCase(saveNote.fulfilled, (state, action) => {
        if (action.payload) {
          const pageIndex = state.pages.findIndex((p) => p.id === action.payload?.id);
          if (pageIndex > -1) {
            state.pages.splice(pageIndex, 1, action.payload);
          }
          if (state.currentPage?.id === action.payload.id) {
            state.currentPage = action.payload;
          }
        }
      })
      .addCase(deletePage.fulfilled, (state, action) => {
        const pageIndex = state.pages.findIndex((p) => p.id === action.payload.id);
        if (pageIndex > -1) {
          state.pages.splice(pageIndex, 1);
        } else {
          state.pages.push(action.payload);
        }

        if (state.currentPage?.id === action.payload.id) {
          state.currentPage = action.payload;
        }
      })
      .addCase(saveSection.fulfilled, (state, action) => {
        updateSection(state, action.payload);
      })
      .addCase(createSection.fulfilled, (state, action) => {
        if (state.notebook && !state.notebook?.sections) {
          state.notebook.sections = [];
        }
        state.notebook?.sections.push(action.payload);
      })
      .addCase(deleteSection.fulfilled, (state, action) => {
        if (state.notebook) {
          const index = state.notebook.sections.findIndex(
            (section) => section.id === action.payload.id
          );

          if (index >= 0) {
            const sections = state.notebook.sections;
            sections.splice(index, 1);
            state.notebook = {
              ...state.notebook,
              sections: [...sections]
            };
          }
        }
      })
      .addCase(saveStock.fulfilled, (state, action) => {
        const index = state.currentPage?.stocks.findIndex((s) => s.id === action.payload.id);
        if (index !== undefined && index !== -1) {
          state.currentPage?.stocks.splice(index, 1, action.payload);
        } else {
          state.currentPage?.stocks.push(action.payload);
        }

        const pageIndex = state.pages.findIndex((p) => p.id === state.currentPage?.id);
        if (pageIndex !== -1) {
          state.pages.splice(pageIndex, 1, state.currentPage!);
        }
      })
      .addCase(deleteStock.fulfilled, (state, action) => {
        const index = state.currentPage?.stocks.findIndex((s) => s.id === action.payload.id);
        if (index !== undefined && index !== -1) {
          state.currentPage?.stocks.splice(index, 1);
        }

        const pageIndex = state.pages.findIndex((p) => p.id === state.currentPage?.id);
        if (pageIndex !== -1) {
          state.pages.splice(pageIndex, 1, state.currentPage!);
        }
      });
  }
});

export const selectNotebook = (state: { notebook: NotebookState }) => state.notebook.notebook;
export const selectCurrentSection = (state: { notebook: NotebookState }) =>
  state.notebook.currentSection;
export const selectCurrentPage = (state: { notebook: NotebookState }) => state.notebook.currentPage;
export const selectPages = (state: { notebook: NotebookState }) => state.notebook.pages;

export const { setCurrentSection, setCurrentPage, setEditingPage } = notebookSlice.actions;

export default notebookSlice.reducer;

import axios, { AxiosResponse } from 'axios';
import { FilterModel } from 'components/gappers/FilterModel';
import { GapperResponse } from 'models/GapperResponse';
import { Gapper } from 'models/gapper';
import { Tag } from 'models/tag';
import moment from 'moment';

class GappersService {
  async loadAll(
    filter: FilterModel | null,
    page: number,
    rowsPerPage: string
  ): Promise<AxiosResponse<GapperResponse>> {
    const tagIds = filter?.tags.map((tag) => tag.id);
    let queryParams = {
      symbol: filter?.symbol,
      tags: tagIds,
      favorites: filter?.favorites,
      page: page,
      page_size: rowsPerPage,
      fromDate: filter?.fromDate ? moment(filter.fromDate).format('YYYY-MM-DD') : null,
      toDate: filter?.toDate ? moment(filter.toDate).format('YYYY-MM-DD') : null,
      gap: filter?.gap,
      volume: filter?.volume,
      premarketVolume: filter?.premarketVolume,
      maxPercent: filter?.maxPercent,
      maxPercentPremarket: filter?.maxPercentPremarket,
      sortOrder: filter?.sortOrder
    };
    return await axios.get(`${process.env.REACT_APP_API_URL}/gappers`, { params: queryParams });
  }

  async upload(file: File): Promise<AxiosResponse<any>> {
    const formData = new FormData();
    formData.append('file', file);
    return await axios.post(`${process.env.REACT_APP_API_URL}/gappers/upload`, formData);
  }

  async save(gapper: Gapper): Promise<AxiosResponse<Gapper>> {
    return await axios.put(`${process.env.REACT_APP_API_URL}/gappers/${gapper.id}`, {
      gapper: gapper
    });
  }

  async delete(gapper: Gapper): Promise<AxiosResponse<void>> {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/gappers/${gapper.id}`);
  }
}

export default new GappersService();

import { AccordionSummary, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactNode } from 'react';
import { Tag } from 'models/tag';

interface AccordionSummaryProps {
  tags?: ReactNode;
  title: ReactNode;
  subTitle: ReactNode;
  rightElement?: ReactNode;
}

const AppAccordionSummary: React.FC<AccordionSummaryProps> = ({
  tags,
  title,
  subTitle,
  rightElement
}) => {
  return (
    <AccordionSummary sx={{ flexDirection: 'row-reverse', gap: 1 }} expandIcon={<ExpandMoreIcon />}>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <Box>
            {title}
            {subTitle}
          </Box>
          <Box sx={{ display: 'flex', gap: '5px' }}>{tags}</Box>
        </Box>
        {rightElement}
      </Box>
    </AccordionSummary>
  );
};

export default AppAccordionSummary;

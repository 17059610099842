import React, { ChangeEvent, useEffect, useState } from 'react';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import { Accordion, Box, Chip, IconButton, Paper, TextField, Typography } from '@mui/material';
import AppAccordionContent from 'components/daily-journal/AppAccordionContent';
import { useSnackbar } from 'providers/SnackbarProvider';
import Fab from 'components/shared/fab/Fab';
import AppAccordionSummary from 'components/daily-journal/AppAccordionSummary';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmationDialog from 'components/shared/confirmation-dialog/ConfirmationDialog';
import './Favorites.scss';
import { deleteFavorite, loadFavorites, saveFavorite, selectFavorites } from 'store/favoritesSlice';
import { Favorite } from 'models/favorite';
import FavoriteFormDialog from './FavoriteFormDialog';
import AppAutocomplete from 'components/shared/autocomplete/Autocomplete';
import { useForm } from 'react-hook-form';
import { Tag } from 'models/tag';
import { loadTags, selectTags } from 'store/tagsSlice';

const Favorites: React.FC = () => {
  const [itemToDelete, setItemToDelete] = useState<Favorite | null>(null);
  const [selectedFavorite, setSelectedFavorite] = useState<Favorite | null>(null);
  const [itemToEdit, setItemToEdit] = useState<Favorite | undefined>(undefined);
  const [isFavoriteDialogOpen, setFavoriteDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [symbol, setSymbol] = useState<string | null>(null);
  const [filterTags, setFilterTags] = useState<Tag[]>([]);
  const dispatch = useStoreDispatch();
  const snackbar = useSnackbar();
  const favorites = useStoreSelector(selectFavorites);
  const tags = useStoreSelector(selectTags);
  const { control } = useForm();

  useEffect(() => {
    dispatch(loadFavorites({ symbol, tags: filterTags }));
  }, [symbol, filterTags]);

  const handleChange = (item: Favorite) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    const newEntry = isExpanded ? item : null;
    setSelectedFavorite(newEntry);
  };

  const onSaveNotes = (value: string) => {
    const item = { ...selectedFavorite, notes: value } as Favorite;
    dispatch(saveFavorite({ favorite: item }))
      .unwrap()
      .then(() => {
        snackbar.showSnackbar({ message: 'Favorite notes saved successfully', type: 'success' });
      });
  };

  const handleCreateNewFavorite = (favorite: Favorite) => {
    dispatch(saveFavorite({ favorite }))
      .unwrap()
      .then(() => {
        setFavoriteDialogOpen(false);
        setItemToDelete(null);
        snackbar.showSnackbar({ message: 'Favorite saved successfully', type: 'success' });
      });
  };

  const handleDeleteItem = (item: Favorite) => {
    setConfirmDialogOpen(true);
    setItemToDelete(item);
  };

  const handleDeleteCancel = () => {
    setConfirmDialogOpen(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirm = () => {
    if (itemToDelete) {
      dispatch(deleteFavorite({ item: itemToDelete }))
        .unwrap()
        .then(() => {
          snackbar.showSnackbar({
            message: 'Favorite Item deleted successfully',
            type: 'success'
          });
        });
    }
    setConfirmDialogOpen(false);
  };

  const handleEditItem = (item: Favorite) => {
    setFavoriteDialogOpen(true);
    setItemToEdit(item);
  };

  const handleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setSymbol(event.target.value);
  };

  const handleCreateNew = () => {
    setFavoriteDialogOpen(true);
    setItemToEdit(undefined);
  };

  const handleAutocompleteChanged = (tags: Tag[]) => {
    setFilterTags(tags);
  };

  return (
    <React.Fragment>
      <Paper className="page-header favorite-header" elevation={2}>
        <Typography variant="h6" sx={{ fontWeight: '500', flex: 1 }}>
          Favorites
        </Typography>
        <div className="right-panel">
          <AppAutocomplete
            control={control}
            name="tag-filter"
            label="Tags"
            size={'small' as 'small | medium'}
            tags={tags}
            onChange={handleAutocompleteChanged}
          />
          <TextField
            id="standard-basic"
            label="Search"
            placeholder="AAPL"
            variant="standard"
            onChange={handleFilter}
          />
        </div>
      </Paper>
      <Box
        className="playbook page-content"
        sx={{
          display: 'flex',
          flexDirection: 'row'
        }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 0.5,
            marginLeft: '10px'
          }}>
          {favorites &&
            favorites.map((item) => {
              const date = moment(item.date).format('LL');
              return (
                <Accordion
                  key={item.id}
                  sx={{ position: 'inherit' }}
                  expanded={selectedFavorite?.id === item.id}
                  onChange={handleChange(item)}
                  TransitionProps={{ unmountOnExit: true }}>
                  <AppAccordionSummary
                    title={<Typography variant="subtitle1">{item.symbol}</Typography>}
                    subTitle={<Typography sx={{ fontSize: '12px' }}>{date}</Typography>}
                    rightElement={
                      <Box>
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            handleEditItem(item);
                          }}>
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDeleteItem(item);
                          }}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    }
                    tags={
                      item.tags &&
                      item.tags.map((tag) => {
                        return <Chip label={tag.name} size="small" color="secondary" />;
                      })
                    }
                  />
                  <AppAccordionContent
                    date={item.date}
                    symbol={item.symbol}
                    notes={selectedFavorite?.notes}
                    saveNotes={onSaveNotes}
                  />
                </Accordion>
              );
            })}
        </Box>

        <FavoriteFormDialog
          favoriteToEdit={itemToEdit}
          open={isFavoriteDialogOpen}
          onCancel={() => setFavoriteDialogOpen(false)}
          onSubmit={handleCreateNewFavorite}
        />

        <ConfirmationDialog
          open={isConfirmDialogOpen}
          title="Delete image"
          text="Are you sure you want to permanently delete this image? This action cannot be undone."
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
        />

        <Fab onClick={handleCreateNew}></Fab>
      </Box>
    </React.Fragment>
  );
};

export default Favorites;

import axios, { AxiosResponse } from 'axios';
import { HotStock } from 'models/hotStock';

class HotStocksService {
  async getAll(): Promise<AxiosResponse<HotStock[]>> {
    return await axios.get(`${process.env.REACT_APP_API_URL}/hot-stocks`);
  }

  async create(hotStock: HotStock): Promise<AxiosResponse<HotStock>> {
    return await axios.post(`${process.env.REACT_APP_API_URL}/hot-stocks`, hotStock);
  }

  async update(id: number, hotStock: HotStock): Promise<AxiosResponse<HotStock>> {
    return await axios.put(`${process.env.REACT_APP_API_URL}/hot-stocks/${id}`, hotStock);
  }

  async delete(id: number): Promise<AxiosResponse<void>> {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/hot-stocks/${id}`);
  }
}

export default new HotStocksService();

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import userReducer, { UserState, userMiddleware } from 'store/userSlice';
import tradesReducer, { TradesState } from 'store/tradesSlice';
import tradingJournalReducer, { TradingJournalState } from './tradingJournalSlice';
import watchlistReducer, { WatchlistState, watchlistMiddleware } from './watchlistSlice';
import playbookReducer, { PlaybookState } from './playbookSlice';
import hotStocksReducer, { HotStocksState, hotStocksMiddleware } from './hotStocksSlice';
import favoritesSlice, { FavoritesState, favoriteSlice } from './favoritesSlice';
import notebookSlice, { NotebookState } from './notebookSlice';
import tagsSlice from './tagsSlice';
import accountReducer from './accountSlice';
import runnerReducer from './runnerSlice';
import gapperSlice from './gapperSlice';
import { AccountState } from './accountSlice';
import { RunnerState } from './runnerSlice';
import { TagsState } from './tagsSlice';
import { GappersState } from './gapperSlice';
import filingsSlice, { FilingsState } from './filingsSlice';
import tickersSlice, { TickersState } from './tickersSlice';

export interface RootState {
  account: AccountState;
  user: UserState;
  trades: TradesState;
  tradingJournal: TradingJournalState;
  watchlist: WatchlistState;
  playbook: PlaybookState;
  hotStocks: HotStocksState;
  runner: RunnerState;
  favorite: FavoritesState;
  tag: TagsState;
  notebook: NotebookState;
  gappers: GappersState;
  filings: FilingsState;
  tickers: TickersState;
}

const rootReducer = combineReducers({
  account: accountReducer,
  user: userReducer,
  trades: tradesReducer,
  tradingJournal: tradingJournalReducer,
  watchlist: watchlistReducer,
  playbook: playbookReducer,
  hotStocks: hotStocksReducer,
  runner: runnerReducer,
  favorite: favoritesSlice,
  tag: tagsSlice,
  notebook: notebookSlice,
  gappers: gapperSlice,
  filings: filingsSlice,
  tickers: tickersSlice
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(hotStocksMiddleware, watchlistMiddleware, userMiddleware),
  devTools: true
});

export const useStoreDispatch = () => useDispatch<typeof store.dispatch>();
export const useStoreSelector: TypedUseSelectorHook<ReturnType<typeof store.getState>> =
  useSelector;

export default store;

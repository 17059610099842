import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { FilterModel } from 'components/gappers/FilterModel';
import { GapperResponse } from 'models/GapperResponse';
import { Pagination } from 'models/Pagination';
import { Gapper } from 'models/gapper';
import { Tag } from 'models/tag';
import gappersService from 'services/gappersService';

export interface GappersState {
  gappers: Gapper[];
  pagination?: Pagination;
  isLoading: boolean;
}

const initialState: GappersState = {
  gappers: [],

  isLoading: false
};

export const loadGappers = createAsyncThunk<
  GapperResponse,
  {
    filter: FilterModel | null;
    page: number;
    rowsPerPage: string;
  }
>('gappers/load', async ({ filter, page, rowsPerPage }) => {
  const response = await gappersService.loadAll(filter, page, rowsPerPage);
  return response.data;
});

export const saveGapper = createAsyncThunk<Gapper, { gapper: Gapper }>(
  'gappers/save',
  async ({ gapper }) => {
    const response = await gappersService.save(gapper);
    return response.data;
  }
);

export const deleteGapper = createAsyncThunk<Gapper, { gapper: Gapper }>(
  'gappers/delete',
  async ({ gapper }) => {
    const response = await gappersService.delete(gapper);
    if (response.status === 200) {
      return gapper;
    }
    throw new Error('Failed to delete watchlist item ' + gapper.id);
  }
);

export const gapperSlice = createSlice({
  name: 'gappers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(loadGappers.pending, (state, action) => {
        if (action.meta.arg.page === 0) {
          state.gappers = [];
        }
        state.isLoading = true;
      })
      .addCase(loadGappers.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(loadGappers.fulfilled, (state, action) => {
        state.gappers = action.payload.data;
        state.pagination = action.payload.pagination;
        state.isLoading = false;
      })
      .addCase(saveGapper.fulfilled, (state, action) => {
        const index = state.gappers?.findIndex((gapper) => gapper.id === action.payload.id);
        if (index > -1) {
          state.gappers?.splice(index, 1, action.payload);
        } else {
          state.gappers?.push(action.payload);
        }
      })
      .addCase(deleteGapper.fulfilled, (state, action) => {
        const index = state.gappers?.findIndex((gapper) => gapper.id === action.payload.id);
        if (index > -1) {
          state.gappers?.splice(index, 1);
        }
      });
  }
});

export const selectGappers = (state: { gappers: GappersState }) => state.gappers.gappers;
export const selectIsLoading = (state: { gappers: GappersState }) => state.gappers.isLoading;
export const selectPagination = (state: { gappers: GappersState }) => state.gappers.pagination;
export default gapperSlice.reducer;

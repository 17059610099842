import React, { useState } from 'react';
import './Login.scss';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  TextField
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useStoreDispatch } from 'store/store';
import { login } from 'store/userSlice';
import { useForm } from 'react-hook-form';

const textFieldStyle = {
  marginTop: '1em',
  marginBottom: '1em'
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useStoreDispatch();
  const loginSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    password: Yup.string().required('Password is required')
  });
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(loginSchema)
  });

  const onLogin = (data: any) => {
    dispatch(login({ email: data.email, password: data.password }));
  };

  const navigateToRegistration = () => {
    navigate('/register');
  };

  return (
    <div className="login">
      <Card sx={{ maxWidth: 400 }}>
        <form noValidate onSubmit={handleSubmit(onLogin)}>
          <CardHeader title="Login" />
          <CardContent>
            <TextField
              placeholder="email@domain.com"
              label="E-Mail"
              type="email"
              variant="standard"
              fullWidth
              {...register('email')}
              error={errors.email ? true : false}
              helperText={errors.email && errors.email?.message}
              style={textFieldStyle}
            />

            <TextField
              label="Password"
              variant="standard"
              type="password"
              fullWidth
              required
              {...register('password')}
              error={errors.password ? true : false}
              helperText={errors.password && errors.password.message}
              style={textFieldStyle}
            />
          </CardContent>
          <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
            <Stack direction="column" spacing={2} justifyContent="center" sx={{ width: '100%' }}>
              <Button variant="contained" type="submit" sx={{ width: '100%' }}>
                Login
              </Button>

              {/* <div className="not-registered" style={{ width: '100%', textAlign: 'center' }}>
                Not registered?
                <Button onClick={navigateToRegistration} sx={{ textTransform: 'none' }}>
                  Create an account
                </Button>
              </div> */}
            </Stack>
          </CardActions>
        </form>
      </Card>
    </div>
  );
};

export default Login;

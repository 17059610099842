import { Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js';
import { BarChartModel } from 'models/barChartModel';

interface TotalPnlChartProps {
  data: BarChartModel[];
  title: string;
  height?: number;
  color?: string;
}

const TotalPnlChart: React.FC<TotalPnlChartProps> = ({ data, title, height, color }) => {
  const [chartData, setChartData] = useState<any>(null);

  const pnlValues = data.map((d) => d.value);
  const minPnl = Math.min(...pnlValues);
  const maxPnl = Math.max(...pnlValues);
  const chartHeight = height ? { height: height } : {};

  const backgroundColorPlugin = {
    id: 'custom_canvas_background_color',
    beforeUpdate: (chart: any) => {
      const dataset = chart.data.datasets[0];
      dataset.backgroundColor = dataset.data.map((value: number) => {
        if (color) {
          return color;
        }
        return value < 0 ? 'rgba(234, 60, 83, 0.9)' : 'rgb(41 171 135 / 92%)';
      });
      dataset.borderColor = dataset.data.map((value: number) => {
        if (color) {
          return color;
        }
        return value < 0 ? 'rgba(234, 60, 83, 1)' : 'rgba(41, 171, 135, 1)';
      });
    }
  };

  const options = {
    maintainAspectRatio: false,
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2
      }
    },
    layout: {
      padding: 10
    },
    responsive: true,
    scales: {
      x: {
        min: minPnl - 10,
        max: maxPnl + 10,
        ticks: {
          padding: 10
        },
        grid: {
          color: function (context: any) {
            if (context.tick?.value === 0) {
              return 'black';
            }
            return ChartJS.defaults.borderColor as string;
          },
          lineWidth: 2,
          drawTicks: false
        }
      },
      y: {
        beginAtZero: false,
        ticks: {
          padding: 10
        },
        grid: {
          drawTicks: false
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true,
        text: title
      },
      backgroundColorPlugin: {}
    }
  };

  useEffect(() => {
    if (data) {
      const newChartData = {
        labels: data.map((d) => d.key),
        datasets: [
          {
            data: data.map((d) => d.value),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)'
          }
        ]
      };
      setChartData(newChartData);
    }
  }, [data]);

  return (
    <Paper elevation={2} sx={{ height: '400px' }}>
      {chartData && (
        <Bar
          data={chartData}
          options={options}
          plugins={[backgroundColorPlugin]}
          {...chartHeight}
        />
      )}
    </Paper>
  );
};

export default TotalPnlChart;

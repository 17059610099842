import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import './AuthLayout.scss';
import { useStoreSelector } from 'store/store';
import { selectUser } from 'store/userSlice';

const AuthLayout: React.FC = () => {
  const user = useStoreSelector(selectUser);

  if (user) {
    return <Navigate to="/" />;
  }
  return (
    <div className="auth">
      <Outlet></Outlet>
    </div>
  );
};

export default AuthLayout;

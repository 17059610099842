import { Autocomplete, TextField } from '@mui/material';
import { Tag } from 'models/tag';
import { Controller } from 'react-hook-form';

type AutocompleteProps = {
  tags: Tag[];
  control: any;
  name: string;
  label: string;
  size?: 'small | medium';
  onChange?: (tags: Tag[]) => void;
};

const AppAutocomplete: React.FC<AutocompleteProps> = ({
  tags,
  control,
  name,
  label,
  size = 'medium',
  onChange
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          disableClearable
          disablePortal
          filterSelectedOptions
          sx={{ width: '100%' }}
          multiple
          size={size as 'small' | 'medium'}
          options={tags}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          id="tags-autocomplete"
          onChange={(event, value) => {
            field.onChange(value);
            if (onChange) {
              onChange(value);
            }
          }}
          renderInput={(params) => (
            <TextField
              error={!!error}
              helperText={error?.message}
              label={label}
              name="tags"
              type="search"
              variant="standard"
              inputRef={ref}
              {...params}
            />
          )}
        />
      )}
    />
  );
};

export default AppAutocomplete;

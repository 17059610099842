import { List, ListItemButton, ListItemText } from '@mui/material';
import moment from 'moment';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import {
  selectCurrentWatchlist,
  selectWatchlistList,
  setCurrentWatchlist
} from 'store/watchlistSlice';
import { WatchlistModel } from 'models/watchlist';

const WatchlistList: React.FC = () => {
  const dispatch = useStoreDispatch();
  const watchlistList = useStoreSelector(selectWatchlistList);
  const selectedWatchlist = useStoreSelector(selectCurrentWatchlist);

  const handleSelectWatchlist = (watchlist: WatchlistModel) => {
    dispatch(setCurrentWatchlist(watchlist));
  };

  return (
    <List className="sidebar-list">
      {watchlistList &&
        watchlistList.map((watchlist) => {
          const formattedDate = moment(watchlist.date).format('YYYY-MM-DD');

          return (
            <ListItemButton
              key={watchlist.date.toString()}
              onClick={() => handleSelectWatchlist(watchlist)}
              className={selectedWatchlist?.date === watchlist.date ? 'selected' : ''}
              sx={{ flexGrow: 0 }}>
              <ListItemText
                primary={formattedDate}
                secondary={`Items: ${watchlist.watchlistStocks.length}`}></ListItemText>
            </ListItemButton>
          );
        })}
    </List>
  );
};

export default WatchlistList;

import axios, { AxiosResponse } from 'axios';
import { Execution } from 'models/execution';

class ExecutionsService {
  async loadExecutions(tradeId: string): Promise<Execution[]> {
    try {
      const response: AxiosResponse = await axios.get(
        `${process.env.REACT_APP_API_URL}/trade/${tradeId}/executions`
      );
      return response.data;
    } catch (error) {
      console.log('Failed to load executions');
      throw error;
    }
  }
}

export default new ExecutionsService();

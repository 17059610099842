import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Runner } from 'models/runner';
import setupService from 'services/runnerService';
import { RootState } from './store';

export interface RunnerState {
  runners: Runner[];
  currentRunner: Runner | null;
}

const initialState: RunnerState = {
  runners: [],
  currentRunner: null
};

export const loadRunners = createAsyncThunk('runners/load', async () => {
  const response = await setupService.load();
  return response.data;
});

export const saveRunner = createAsyncThunk<Runner, Runner>('runners/save', async (setup) => {
  const response = await setupService.save(setup);
  return response.data;
});

export const deleteRunner = createAsyncThunk<Runner, Runner>('runners/delete', async (setup) => {
  const response = await setupService.delete(setup);
  if (response.status === 200) {
    return setup;
  }
  throw new Error('Failed to delete runner ' + setup.id);
});

const runnerSlice = createSlice({
  name: 'setupSlice',
  initialState,
  reducers: {
    setCurrentSetup: (state, action) => {
      state.currentRunner = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loadRunners.fulfilled, (state, action) => {
        state.runners = action.payload;
      })
      .addCase(saveRunner.fulfilled, (state, action) => {
        const index = state.runners.findIndex((s) => s.id === action.payload.id);
        if (index > -1) {
          state.runners.splice(index, 1, action.payload);
        } else {
          state.runners.push(action.payload);
        }
      })
      .addCase(deleteRunner.fulfilled, (state, action) => {
        const index = state.runners.findIndex((s) => s.id === action.payload.id);
        if (index > -1) {
          state.runners.splice(index, 1);
        }
      });
  }
});

export const { setCurrentSetup } = runnerSlice.actions;
export const selectRunners = (state: { runner: RunnerState }) => state.runner.runners;
export const selectCurrentRunner = (state: { runner: RunnerState }) => state.runner.currentRunner;

export default runnerSlice.reducer;

import { IChartApi, ISeriesApi } from 'lightweight-charts';
import React, { useEffect, useRef, useState } from 'react';
import chartService from 'services/chartService';
import { ChartUtils } from 'utils.ts/ChartUtils';
import CandlestickDataMapper from './CandlestickDataMapper';
import { ChartDataSeries } from 'models/chart/chartDataSeries';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import './CandlestickChart.scss';
import { DateUtils } from 'utils.ts/DateUtils';
import { LoadingState } from 'models/loadingState';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { Execution } from 'models/execution';
import FullScreenChart from './FullScreenChart';

interface CandleStickChartProps {
  date: Date;
  symbol: string;
  height: string;
  defaultInterval?: '1m' | '5m' | '1d';
  isFullScreenButtonVisible?: boolean;
}

const CandleStickChart: React.FC<CandleStickChartProps> = ({
  date,
  symbol,
  height,
  defaultInterval,
  isFullScreenButtonVisible = true
}) => {
  const [chartData, setChartData] = useState<ChartDataSeries | null>(null);
  const [interval, setInterval] = useState<string>(defaultInterval ? defaultInterval : '1m');
  const [isDrawing, setIsDrawing] = useState<boolean>(false);
  const [lineChartSeries, setLineChartSeries] = useState<ISeriesApi<'Candlestick'> | null>(null);
  const [drawings, setDrawings] = useState<any[]>([]);
  const [state, setState] = useState<LoadingState>(LoadingState.LOADING);
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const isDrawingRef = useRef(isDrawing);

  const chartContainerRef = useRef<HTMLDivElement | null>(null);
  const chart = useRef<IChartApi | null>();
  const volumeContainerRef = useRef<HTMLDivElement | null>(null);
  const volumeChart = useRef<IChartApi | null>();
  const toolTip = useRef<HTMLDivElement | null>(null);

  const chartClickHandler = (param: any) => {
    if (isDrawingRef.current && lineChartSeries && param.point) {
      const priceLine = {
        price: lineChartSeries.coordinateToPrice(param.point?.y) as number,
        color: 'white',
        axisLabelVisible: true,
        lineStyle: 0
      };
      const priceLineRef = lineChartSeries!.createPriceLine(priceLine);
      setDrawings((prevDrawings) => [...prevDrawings, { ...priceLine, ref: priceLineRef }]);
    }
  };

  useEffect(() => {
    if (chart.current) {
      chart.current.subscribeClick(chartClickHandler);

      return () => {
        chart.current?.unsubscribeClick(chartClickHandler);
      };
    }
  }, [chart.current]);

  useEffect(() => {
    isDrawingRef.current = isDrawing;
  }, [isDrawing]);

  useEffect(() => {
    if (chartData && chartData.data && chartData.data.length > 0) {
      const candleData = CandlestickDataMapper.mapCandlestickData(chartData.data);
      const volumeData = CandlestickDataMapper.mapVolumeData(chartData.data);

      if (chartContainerRef.current && volumeContainerRef.current) {
        chartContainerRef.current.innerHTML = '';
        volumeContainerRef.current.innerHTML = '';
        chart.current = ChartUtils.createChart(chartContainerRef);
        volumeChart.current = ChartUtils.createChart(volumeContainerRef, 120);

        const candleSeries = ChartUtils.addCandlestickSeries(chart.current);
        setLineChartSeries(candleSeries);
        candleSeries.setData(candleData);

        chart.current.subscribeCrosshairMove((param) => {
          if (
            param.point === undefined ||
            !param.time ||
            param.point.x < 0 ||
            param.point.x > chartContainerRef!.current!.clientWidth ||
            param.point.y < 0 ||
            param.point.y > chartContainerRef!.current!.clientHeight
          ) {
            if (toolTip.current) {
              toolTip.current.style.display = 'none';
            }
          } else {
            if (toolTip.current) {
              const trade = chartData.trades?.find(
                (t) => DateUtils.isoToTimestamp(t.date) === param.time
              );

              if (trade) {
                toolTip.current.style.display = 'block';
                toolTip.current.innerHTML = `
                <div style="color: rgba(38, 166, 154, 1)">${getSide(trade)}</div>
                <div style="font-size: 12px; margin: 4px 0px; color: black">
                  ${trade.side} ${trade.quantity}@${trade.price}
                </div>
                <div style="color: black">
                ${trade.date}
              </div>
              `;

                let left = param.point.x + 15;
                if (left > chartContainerRef!.current!.clientWidth - 80) {
                  left = param.point.x - 95;
                }

                let top = param.point.y + 15;
                if (top > chartContainerRef!.current!.clientHeight - 80) {
                  top = param.point.y - 95;
                }
                toolTip.current.style.left = left + 'px';
                toolTip.current.style.top = top + 'px';
              } else {
                toolTip.current.style.display = 'none';
              }
            }
          }
        });

        const volumeSeries = ChartUtils.addHistogramSeries(volumeChart.current);
        volumeSeries.setData(volumeData);

        ChartUtils.syncTimeScale(chart.current, volumeChart.current);
        ChartUtils.addWatermark(chart.current, chartData.symbol);
        handleResetScale();

        if (interval !== '1d') {
          const lineData = CandlestickDataMapper.mapExecutions(chartData.trades);
          if (lineData) {
            ChartUtils.addTrades(chart.current, lineData, chartData.trades);

            toolTip.current = document.createElement('div');
            if (toolTip.current) {
              toolTip.current.className = 'tooltip'; // Apply the tooltip class
              chartContainerRef.current.appendChild(toolTip.current);
            }
          }
          ChartUtils.addVWAP(chart.current, chartData.data);
        } else {
          chart.current.timeScale().applyOptions({
            timeVisible: false
          });
        }
      }
    }
  }, [chartData]);

  useEffect(() => {
    setState(LoadingState.LOADING);
    loadData();
  }, [interval]);

  const handleResetScale = () => {
    if (interval === '1d') {
      chart.current?.timeScale().fitContent();
    } else {
      ChartUtils.setVisibleRange(date, chart.current!);
    }
    setAutoScale();
  };

  const setAutoScale = () => {
    chart.current?.priceScale('right').applyOptions({
      autoScale: true
    });
  };

  const loadData = () => {
    if (date && symbol) {
      chartService
        .loadData(date, symbol, interval)
        .then((response) => {
          setState(LoadingState.SUCCEEDED);
          const uniqueExecutions = response?.trades?.filter((item, index, array) => {
            return array.findIndex((t) => t.date === item.date) === index;
          });
          response.trades = uniqueExecutions;
          setChartData(response);
        })
        .catch(() => {
          setState(LoadingState.FAILED);
        });
    }
  };

  const handleIntervalChange = (event: SelectChangeEvent) => {
    setInterval(event.target.value as string);
  };

  const handleDrawingButton = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDrawing((prevIsDrawing) => !prevIsDrawing);
  };

  const handleDeleteDrawingButton = (event: any) => {
    drawings.forEach((drawing) => {
      lineChartSeries?.removePriceLine(drawing.ref);
    });
    setDrawings([]);
  };

  const getSide = (trade: Execution) => {
    switch (trade.side) {
      case 'B':
      case 'Buy':
        return 'Buy';
      case 'S':
      case 'Sell':
        return 'Sell';
      case 'SS':
        return 'Short';
      case 'BC':
        return 'Buy Cover';
    }
  };

  const handleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <React.Fragment>
      {state === LoadingState.LOADING && (
        <Box sx={{ position: 'relative', height: '50px', width: '100%' }}>
          <LoadingSpinner open={state === LoadingState.LOADING} />
        </Box>
      )}

      {state === LoadingState.SUCCEEDED && (!chartData?.data || chartData?.data?.length === 0) && (
        <Box
          sx={{
            position: 'relative',
            height: '50px',
            width: '100%',
            backgroundColor: 'rgba(0,0,0,0.3)',
            padding: '5px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <WarningAmberIcon color="warning" />
          <Typography variant="subtitle1" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
            No chart data available
          </Typography>
        </Box>
      )}

      {chartData && chartData.data && (
        <div
          style={{
            width: '100%',
            height: height,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column'
          }}>
          <div
            className="drawing-panel"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '5px',
              position: 'absolute',
              top: 0,
              right: '52px',
              color: 'white',
              cursor: 'pointer',
              zIndex: 999
            }}>
            <Box sx={{ minWidth: 120, zIndex: 9 }}>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={interval}
                  label="Age"
                  variant="standard"
                  onChange={handleIntervalChange}
                  sx={{ color: 'white' }}>
                  <MenuItem value="1m">1 Minute</MenuItem>
                  <MenuItem value="5m">5 Minutes</MenuItem>
                  <MenuItem value="1d">Daily</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <IconButton
              onClick={handleResetScale}
              style={{
                color: 'white',
                cursor: 'pointer',
                zIndex: 999
              }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                <path
                  fill="currentColor"
                  d="M8.5 6A2.5 2.5 0 0 0 6 8.5V11h1V8.5C7 7.67 7.67 7 8.5 7H11V6H8.5zM6 17v2.5A2.5 2.5 0 0 0 8.5 22H11v-1H8.5A1.5 1.5 0 0 1 7 19.5V17H6zM19.5 7H17V6h2.5A2.5 2.5 0 0 1 22 8.5V11h-1V8.5c0-.83-.67-1.5-1.5-1.5zM22 19.5V17h-1v2.5c0 .83-.67 1.5-1.5 1.5H17v1h2.5a2.5 2.5 0 0 0 2.5-2.5z"></path>
              </svg>
            </IconButton>
            <IconButton
              onClick={handleDrawingButton}
              style={{
                color: 'white',
                cursor: 'pointer',
                zIndex: 999,
                backgroundColor: isDrawing ? '#80808059' : 'transparent',
                boxShadow: isDrawing ? 'inset 1px 1px 1px 1px rgba(0,0,0,0.75)' : 'none'
              }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                <g fill="currentColor" fillRule="nonzero">
                  <path d="M4 15h8.5v-1h-8.5zM16.5 15h8.5v-1h-8.5z"></path>
                  <path d="M14.5 16c.828 0 1.5-.672 1.5-1.5s-.672-1.5-1.5-1.5-1.5.672-1.5 1.5.672 1.5 1.5 1.5zm0 1c-1.381 0-2.5-1.119-2.5-2.5s1.119-2.5 2.5-2.5 2.5 1.119 2.5 2.5-1.119 2.5-2.5 2.5z"></path>
                </g>
              </svg>
            </IconButton>

            <IconButton
              onClick={handleDeleteDrawingButton}
              style={{
                color: 'white',
                cursor: 'pointer',
                zIndex: 999
              }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28">
                <path
                  fill="currentColor"
                  fillRule="evenodd"
                  d="M11.5 6a.5.5 0 0 0-.5.5V8h6V6.5a.5.5 0 0 0-.5-.5h-5zM18 8V6.5c0-.83-.67-1.5-1.5-1.5h-5c-.83 0-1.5.67-1.5 1.5V8H5.5a.5.5 0 0 0 0 1H7v12.5A2.5 2.5 0 0 0 9.5 24h9a2.5 2.5 0 0 0 2.5-2.5V9h1.5a.5.5 0 0 0 0-1H18zm2 1H8v12.5c0 .83.67 1.5 1.5 1.5h9c.83 0 1.5-.67 1.5-1.5V9zm-8.5 3c.28 0 .5.22.5.5v7a.5.5 0 0 1-1 0v-7c0-.28.22-.5.5-.5zm5.5.5a.5.5 0 0 0-1 0v7a.5.5 0 0 0 1 0v-7z"></path>
              </svg>
            </IconButton>

            {isFullScreenButtonVisible && (
              <IconButton
                onClick={handleFullScreen}
                style={{
                  color: 'white',
                  cursor: 'pointer',
                  zIndex: 999
                }}>
                {isFullScreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
              </IconButton>
            )}
          </div>

          <div
            ref={chartContainerRef}
            className="chart-container"
            style={{ width: '100%', height: '90%', position: 'relative' }}></div>
          <div
            className="volume-container"
            ref={volumeContainerRef}
            style={{ marginTop: '-1px', height: '120px', marginBottom: '30px' }}></div>
        </div>
      )}

      <FullScreenChart
        open={isFullScreen}
        symbol={symbol}
        date={date}
        onClose={() => setIsFullScreen(false)}
      />
    </React.Fragment>
  );
};

export default CandleStickChart;

import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { useSnackbar } from 'providers/SnackbarProvider';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddchartIcon from '@mui/icons-material/Addchart';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import './Notebook.scss';
import NotebookSections from './NotebookSections';
import {
  deletePage,
  deleteStock,
  loadNotebook,
  saveNote,
  savePage,
  saveStock,
  selectCurrentPage,
  selectNotebook,
  setEditingPage
} from 'store/notebookSlice';
import NotebookPages from './NotebookPages';
import TextEditor from 'components/shared/text-editor/TextEditor';
import StockFormDialog from './StockFormDialog';
import { Stock } from 'models/stock';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CandleStickChart from 'components/tradingview-charts/CandleStickChart';
import ConfirmationDialog from 'components/shared/confirmation-dialog/ConfirmationDialog';

const Notebook: React.FC = () => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [stockToEdit, setStockToEdit] = useState<Stock | undefined>(undefined);
  const [stockToDelete, setStockToDelete] = useState<Stock | undefined>(undefined);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [isStockDialogOpen, setIsStockDialogOpen] = useState(false);
  const [symbol, setSymbol] = useState<string | null>(null);
  const dispatch = useStoreDispatch();
  const snackbar = useSnackbar();
  const notebook = useStoreSelector(selectNotebook);
  const selectedPage = useStoreSelector(selectCurrentPage);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const textFieldRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    dispatch(loadNotebook({ symbol }));
  }, [symbol]);

  const handleSaveNote = (value: string) => {
    if (selectedPage) {
      dispatch(saveNote(value))
        .unwrap()
        .then(() => {
          snackbar.showSnackbar({ message: 'Page saved successfully', type: 'success' });
        });
    }
  };

  const handleEditingStarted = () => {
    dispatch(setEditingPage(selectedPage));
  };

  const handleFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setSymbol(event.target.value);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleDelete = () => {
    if (selectedPage) {
      dispatch(deletePage(selectedPage));
      handleClose();
    }
  };

  const handleEditItem = (stock: Stock) => {
    setStockToEdit(stock);
    setIsStockDialogOpen(true);
  };

  const handleDeleteItem = (stock: Stock) => {
    setStockToDelete(stock);
    setConfirmDialogOpen(true);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const handleSaveTitle = () => {
    if (textFieldRef.current && selectedPage) {
      dispatch(savePage({ ...selectedPage, title: textFieldRef.current.value }));
      setEditMode(false);
    }
  };

  const handleCancelStockDialog = () => {
    setIsStockDialogOpen(false);
    setStockToEdit(undefined);
  };

  const handleSaveStock = (stock: Stock) => {
    dispatch(saveStock(stock))
      .unwrap()
      .then(() => {
        snackbar.showSnackbar({ message: 'Stock saved successfully', type: 'success' });
        setIsStockDialogOpen(false);
      });
  };

  const handleDeleteCancel = () => {
    setStockToDelete(undefined);
    setConfirmDialogOpen(false);
  };

  const handleAddNewStock = () => {
    setIsStockDialogOpen(true);
    handleClose();
  };

  const handleDeleteConfirm = () => {
    if (stockToDelete) {
      dispatch(deleteStock(stockToDelete))
        .unwrap()
        .then(() => {
          snackbar.showSnackbar({ message: 'Stock successfully deleted', type: 'success' });
        });
    }

    setConfirmDialogOpen(false);
  };

  return (
    <React.Fragment>
      <Paper className="page-header notebook-header" elevation={2}>
        <Typography variant="h6" sx={{ fontWeight: '500', flex: 1 }}>
          Notebook
        </Typography>
        <div className="right-panel">
          <TextField
            id="standard-basic"
            label="Search"
            placeholder="AAPL"
            variant="standard"
            onChange={handleFilter}
          />
        </div>
      </Paper>
      <Box className="notebook page-content">
        <NotebookSections sections={notebook?.sections} />
        <Paper className="main-content">
          <NotebookPages />
          {selectedPage && (
            <div className="notebook-page-content">
              <div className="notebook-page-header">
                <div className="title">
                  {editMode ? (
                    <div className="edit-title">
                      <TextField
                        inputRef={textFieldRef}
                        className="title-field"
                        id="section-title"
                        label="Title"
                        variant="standard"
                        inputProps={{ style: { fontSize: 15 } }}
                        defaultValue={selectedPage?.title}
                      />

                      <div className="buttons">
                        <IconButton aria-label="save" size="small" onClick={handleSaveTitle}>
                          <SaveIcon />
                        </IconButton>

                        <IconButton aria-label="cancel" size="small" onClick={handleCancelEdit}>
                          <CancelIcon />
                        </IconButton>
                      </div>
                    </div>
                  ) : (
                    <div onDoubleClick={toggleEditMode}>
                      <Typography
                        variant="h5"
                        sx={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '10px'
                        }}>
                        {selectedPage?.title}
                        <IconButton onClick={toggleEditMode}>
                          <EditIcon />
                        </IconButton>
                      </Typography>
                    </div>
                  )}

                  <div className="subtitle">
                    <span>
                      Created: {moment(selectedPage?.createdAt).format('DD/MM/YYYY HH:mm')}
                    </span>
                    <span>
                      Last Updated: {moment(selectedPage?.updatedAt).format('DD/MM/YYYY HH:mm')}
                    </span>
                  </div>
                </div>
                <div className="menu">
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleMenuClick}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      'aria-labelledby': 'long-button'
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}>
                    <MenuItem onClick={handleAddNewStock}>
                      <ListItemIcon>
                        <AddchartIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Add Chart</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={handleDelete}>
                      <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>Delete</ListItemText>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
              <div className="notebook-page-body">
                <Box sx={{ marginBottom: '20px', overflow: 'hidden', paddingBottom: '110px' }}>
                  <TextEditor
                    editorKey={selectedPage.id}
                    notes={selectedPage?.note}
                    onSave={handleSaveNote}
                    editingStarted={handleEditingStarted}
                  />
                </Box>

                {selectedPage &&
                  selectedPage.stocks &&
                  selectedPage.stocks.map((stock) => {
                    return (
                      <Accordion key={stock.id} TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary
                          sx={{ flexDirection: 'row-reverse', gap: 1, margin: '-5px 0' }}
                          expandIcon={<ExpandMoreIcon />}>
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'flex-start',
                              justifyContent: 'space-between'
                            }}>
                            <div className="details">
                              <Typography variant="h6">{stock.symbol}</Typography>
                              <Typography sx={{ fontSize: '12px' }}>
                                {moment(stock.date).format('YYYY-MM-DD')}
                              </Typography>
                            </div>
                            <div className="action-buttons">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleEditItem(stock);
                                }}>
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleDeleteItem(stock);
                                }}>
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <CandleStickChart
                            symbol={stock.symbol}
                            date={stock.date}
                            height="500px"
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
              </div>
            </div>
          )}
        </Paper>

        <StockFormDialog
          itemToEdit={stockToEdit}
          onCancel={handleCancelStockDialog}
          onSubmit={handleSaveStock}
          open={isStockDialogOpen}
        />

        <ConfirmationDialog
          open={isConfirmDialogOpen}
          title="Delete image"
          text="Are you sure you want to permanently delete this image? This action cannot be undone."
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
        />
      </Box>
    </React.Fragment>
  );
};

export default Notebook;

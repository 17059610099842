import { List, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material';
import { Playbook } from 'models/playbook';
import moment from 'moment';
import { selectPlaybooks, selectCurrentPlaybook, setCurrentPlaybook } from 'store/playbookSlice';
import { useStoreDispatch, useStoreSelector } from 'store/store';

const PlaybookList: React.FC = () => {
  const dispatch = useStoreDispatch();
  const playbooks = useStoreSelector(selectPlaybooks);
  const selectedPlaybook = useStoreSelector(selectCurrentPlaybook);

  const handleSelectPlaybook = (playbook: Playbook) => {
    dispatch(setCurrentPlaybook(playbook));
  };

  return (
    <List className="sidebar-list">
      {playbooks &&
        playbooks.map((playbook) => {
          const date = moment(playbook.date).format('YYYY-MM-DD');
          return (
            <ListItemButton
              key={date}
              onClick={() => handleSelectPlaybook(playbook)}
              className={selectedPlaybook === playbook ? 'selected' : ''}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                flexGrow: 0,
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
              }}>
              <ListItemText primary={date} secondary={playbook.category}></ListItemText>
              <ListItemAvatar
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  flexGrow: 0
                }}>
                <Typography
                  variant="subtitle1"
                  key={date}
                  sx={{ textAlign: 'center', fontWeight: 'bold' }}>
                  {playbook.items?.length}
                </Typography>
              </ListItemAvatar>
            </ListItemButton>
          );
        })}
    </List>
  );
};

export default PlaybookList;

import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, TextField } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import React, { useRef, useState } from 'react';
import { NotebookPage } from 'models/notebook-page';
import moment from 'moment';

interface PageItemProps {
  page: NotebookPage;
  onSave: (page: NotebookPage) => void;
  onDelete: (page: NotebookPage) => void;
}

const PageItem: React.FC<PageItemProps> = ({ page, onSave, onDelete }) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const textFieldRef = useRef<HTMLInputElement>(null);

  const createdOn = moment(page.createdAt).format('YYYY-MM-DD');

  const secondaryProps = {
    fontSize: 11
  };

  const handleEdit = () => {
    setEditMode(true);
    handleClose();
  };

  const handleDelete = () => {
    onDelete(page);
    handleClose();
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const handleSave = () => {
    if (textFieldRef.current) {
      onSave({ ...page, title: textFieldRef.current.value });
      setEditMode(false);
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <React.Fragment>
      {editMode ? (
        <React.Fragment>
          <TextField
            inputRef={textFieldRef}
            id="section-title"
            label="Title"
            variant="standard"
            inputProps={{ style: { fontSize: 15 } }}
            defaultValue={page?.title}
          />
          <IconButton aria-label="save" size="small" onClick={handleSave}>
            <SaveIcon />
          </IconButton>

          <IconButton aria-label="cancel" size="small" onClick={handleCancelEdit}>
            <CancelIcon />
          </IconButton>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <ListItemText
            primary={page.title}
            secondary={createdOn}
            secondaryTypographyProps={secondaryProps}></ListItemText>
          <div className="menu">
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button'
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}>
              <MenuItem onClick={handleEdit}>
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>
              <MenuItem onClick={handleDelete}>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            </Menu>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PageItem;

import { Button, IconButton, List, ListItemButton } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import AddIcon from '@mui/icons-material/Add';
import { NotebookSection } from 'models/notebook-section';
import {
  createSection,
  deleteSection,
  loadPages,
  saveSection,
  selectCurrentSection,
  setCurrentSection
} from 'store/notebookSlice';
import React, { useEffect, useState } from 'react';
import SectionItem from './SectionItem';

interface NotebookSectionsProps {
  sections: NotebookSection[] | undefined;
}

const NotebookSections: React.FC<NotebookSectionsProps> = ({ sections }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const selectedSection = useStoreSelector(selectCurrentSection);
  const dispatch = useStoreDispatch();

  useEffect(() => {
    dispatch(loadPages());
  }, [selectedSection]);

  const handleSelectSection = (section: NotebookSection) => {
    dispatch(setCurrentSection(section));
  };

  const handleCreateNewSection = () => {
    dispatch(createSection());
  };

  const handleSaveSection = (section: NotebookSection) => {
    dispatch(saveSection(section));
  };

  const handleDeleteSection = (section: NotebookSection) => {
    dispatch(deleteSection(section));
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <List className={`sidebar-list ${collapsed ? 'collapsed' : ''}`}>
      {collapsed ? (
        <React.Fragment>
          <IconButton color="primary" onClick={toggleCollapse}>
            <ArrowForwardIosIcon />
          </IconButton>
          <div className="text">Sections</div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div
            className="sidebar-header"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}>
            <Button
              variant="text"
              size="small"
              startIcon={<AddIcon />}
              onClick={handleCreateNewSection}>
              New Section
            </Button>
            <IconButton color="primary" onClick={toggleCollapse}>
              <ArrowBackIosNewIcon />
            </IconButton>
          </div>
          {sections &&
            sections.map((section) => {
              return (
                <ListItemButton
                  disableRipple
                  key={section.id}
                  onClick={() => handleSelectSection(section)}
                  className={selectedSection === section ? 'selected' : ''}
                  divider={false}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    flexGrow: 0,
                    gap: '15px',
                    padding: '0 6px 0 6px'
                  }}>
                  <SectionItem
                    section={section}
                    onSave={handleSaveSection}
                    onDelete={handleDeleteSection}
                  />
                </ListItemButton>
              );
            })}
        </React.Fragment>
      )}
    </List>
  );
};

export default NotebookSections;

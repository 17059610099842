import { CalendarModel } from 'models/calendar';
import moment from 'moment-timezone';

export class DateUtils {
  static daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  static getDaysInMonth = (year: number, month: number) => {
    return new Date(year, month + 1, 0).getDate();
  };

  static getDaysOfPreviousMonth = (year: number, month: number, count: number) => {
    const prevMonth = month === 0 ? 11 : month - 1;
    const prevYear = month === 0 ? year - 1 : year;
    const daysInPreviousMonth = this.getDaysInMonth(prevYear, prevMonth);

    const dates = Array.from({ length: count }, (_, i) => {
      const day = daysInPreviousMonth - count + i + 1;
      return new Date(prevYear, prevMonth, day);
    });

    return dates;
  };

  static getDaysFromPreviousMonth = (year: number, month: number, firstDayOfMonth: number) => {
    let days: CalendarModel[] = [];
    const daysFromPrevMonth = this.getDaysOfPreviousMonth(year, month, firstDayOfMonth);
    daysFromPrevMonth.forEach((date, idx) => {
      days.push({
        date: date,
        dayNumber: date.getDate(),
        dayOfWeek: this.daysOfWeek[idx],
        isOutside: true,
        weekNumber: moment(date).isoWeek()
      });
    });
    return days;
  };

  static getDaysOfCurrentMonth = (year: number, month: number) => {
    const days: CalendarModel[] = [];
    for (let i = 1; i <= this.getDaysInMonth(year, month); i++) {
      const dayOfWeek = (moment([year, month, i]).isoWeekday() - 1) % 7; // Use modulo 7 to ensure the value is between 0 and 6
      const date = new Date(year, month, i);
      days.push({
        date: date,
        dayNumber: i,
        dayOfWeek: this.daysOfWeek[dayOfWeek],
        isOutside: false,
        weekNumber: moment(date).isoWeek()
      });
    }
    return days;
  };

  static getDaysFromNextMonth = (
    year: number,
    month: number,
    daysFromNextMonthCount: number,
    lastDayOfMonth: number
  ) => {
    const nextMonth = month === 11 ? 0 : month + 1;
    const nextYear = month === 11 ? year + 1 : year;
    const days: CalendarModel[] = [];
    const startIndex = (lastDayOfMonth + 1) % 7;
    const daysFromNextMonth = this.getDaysOfNextMonth(daysFromNextMonthCount);
    const date = daysFromNextMonth.forEach((dayNum, idx) => {
      days.push({
        date: new Date(nextYear, nextMonth, dayNum),
        dayNumber: dayNum,
        dayOfWeek: this.daysOfWeek[(startIndex + idx) % 7], // Use modulo 7 to ensure the value is between 0 and 6
        isOutside: true,
        weekNumber: moment(new Date(nextYear, nextMonth, dayNum)).isoWeek()
      });
    });
    return days;
  };

  static getCurrentWeek(): { start: Date; end: Date } {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    const daysToSubtract = date.getDay() === 0 ? 6 : date.getDay() - 1;

    let startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - daysToSubtract);

    let endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    return {
      start: startOfWeek,
      end: endOfWeek
    };
  }

  static isoToTimestamp(date: string): number {
    const dateInNewYork = moment(date).tz('America/New_York');
    const offsetInHours: number = dateInNewYork.utcOffset() / 60;

    const convertedDate = moment.tz(date, 'America/New_York');
    return convertedDate.utc().unix() + offsetInHours * 60 * 60;
  }

  static toEasternTimeZone(unixTimestamp: number): number {
    const dateInNewYork = moment.unix(unixTimestamp).tz('America/New_York');
    const offsetInHours: number = dateInNewYork.utcOffset() / 60;

    return Number(unixTimestamp) + offsetInHours * 60 * 60;
  }

  static isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }

  private static getDaysOfNextMonth = (count: number) => {
    return Array.from({ length: count }, (_, i) => i + 1);
  };
}

import { Paper } from '@mui/material';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import { LoadingState } from 'models/loadingState';
import { WinLossRatio } from 'models/winLossRatio';
import { useSnackbar } from 'providers/SnackbarProvider';
import React, { CSSProperties, useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import statsService from 'services/statsService';
import { selectCurrentAccount } from 'store/accountSlice';
import { useStoreSelector } from 'store/store';

const WinLossRatioChart: React.FC = () => {
  const [ratio, setRatio] = useState<WinLossRatio | null>(null);
  const [state, setState] = useState<LoadingState>(LoadingState.LOADING);
  const account = useStoreSelector(selectCurrentAccount);
  const snackbar = useSnackbar();
  const cardStyle: CSSProperties = {
    padding: '15px',
    height: '100px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative'
  };

  const dateByPnl = [
    { label: 'Win %', value: ratio?.rate },
    { label: 'Loss %', value: 100 - (ratio?.rate || 0) }
  ];

  const data = {
    labels: [],
    datasets: [
      {
        label: '',
        data: dateByPnl.map((d) => d.value),
        backgroundColor: ['rgb(41 171 135 / 92%)', 'rgba(234, 60, 83, 0.9)'],
        borderColor: ['rgb(41 171 135 / 92%)', 'rgba(234, 60, 83, 0.9)'],
        borderWidth: 1
      }
    ]
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          title: function (context: any) {
            return dateByPnl[context[0].dataIndex]?.label;
          },
          label: function (context: any) {
            return `${Number(context.parsed).toFixed(2)} %`;
          }
        }
      }
    }
  };

  useEffect(() => {
    statsService
      .winLossRatio()
      .then((result) => {
        setState(LoadingState.SUCCEEDED);
        setRatio(result.data);
      })
      .catch((error) => {
        setState(LoadingState.FAILED);
        snackbar.showSnackbar({ message: 'Failed to load win-loss-ratio', type: 'error' });
      });
  }, [account]);

  return (
    <Paper className="win-percent" elevation={2} sx={cardStyle}>
      {state === LoadingState.LOADING && <LoadingSpinner open={state === LoadingState.LOADING} />}
      {state === LoadingState.SUCCEEDED && (
        <React.Fragment>
          <div className="left">
            <div className="title">
              <span className="label">Winning trades Rate: </span>
              <span className="value">{`${ratio ? ratio?.rate.toFixed(2) : 0}%`}</span>
            </div>

            <div className="title">
              <span className="label">Winning/Losing Ratio: </span>
              <span className="value">{ratio ? ratio?.ratio.toFixed(2) : 0}</span>
            </div>
          </div>
          <div className="chart">
            <Doughnut data={data} options={options} width={100} height={100} />
          </div>{' '}
        </React.Fragment>
      )}
    </Paper>
  );
};

export default WinLossRatioChart;

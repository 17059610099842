import { Button } from '@mui/material';
import React from 'react';
import {
  summarize10Bullets,
  summarize8KFiling,
  summarizeCurrentFiling,
  summarizeS3Filing
} from 'store/filingsSlice';
import { useStoreDispatch } from 'store/store';

const DefaultChatActions: React.FC = () => {
  const dispatch = useStoreDispatch();

  const handleSummary = () => {
    dispatch(summarizeCurrentFiling());
  };

  const handle8KSummary = () => {
    dispatch(summarize8KFiling());
  };

  const handleBulletpointsSummary = () => {
    dispatch(summarize10Bullets());
  };

  const handleS3Summary = () => {
    dispatch(summarizeS3Filing());
  };
  return (
    <React.Fragment>
      <Button className="summary-buttons" variant="contained" color="gray" onClick={handleSummary}>
        Summarize 10-Q or 10-K
      </Button>
      <Button
        className="summary-buttons"
        variant="contained"
        color="gray"
        onClick={handle8KSummary}>
        Summarize 8-K
      </Button>

      <Button
        className="summary-buttons"
        variant="contained"
        color="gray"
        onClick={handleBulletpointsSummary}>
        Summarize in 5-10 Bulletpoints
      </Button>

      <Button
        className="summary-buttons"
        variant="contained"
        color="gray"
        onClick={handleS3Summary}>
        Summarize S-3
      </Button>
    </React.Fragment>
  );
};

export default DefaultChatActions;

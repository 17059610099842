import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Tag } from 'models/tag';
import tagsService from 'services/tagsService';

export interface TagsState {
  tags: Tag[];
}

const initialState: TagsState = {
  tags: []
};

export const loadTags = createAsyncThunk<Tag[]>('tags/load', async () => {
  const response = await tagsService.loadAll();
  return response.data;
});

export const saveTag = createAsyncThunk<Tag, Tag>('tags/save', async (tag) => {
  const response = await tagsService.save(tag);
  return response.data;
});

export const deleteTag = createAsyncThunk<{ deletedTagId: number }, Tag>(
  'tags/delete',
  async (tag) => {
    const response = await tagsService.delete(tag);
    if (response.status === 200) {
      return { deletedTagId: tag.id! };
    }
    throw new Error('Failed to delete tag ' + tag.id);
  }
);

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(saveTag.fulfilled, (state, action) => {
        const index = state.tags.findIndex((tag) => tag.id === action.payload.id);
        if (index > -1) {
          state.tags.splice(index, 1, action.payload);
        } else {
          state.tags.push(action.payload);
        }
      })
      .addCase(loadTags.fulfilled, (state, action) => {
        state.tags = action.payload;
      })
      .addCase(deleteTag.fulfilled, (state, action) => {
        const index = state.tags.findIndex((c) => c.id === action.payload.deletedTagId);
        if (index > -1) {
          state.tags.splice(index, 1);
        }
      });
  }
});

export const selectTags = (state: { tag: TagsState }) => state.tag.tags;

export default tagsSlice.reducer;

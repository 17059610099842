import {
  Accordion,
  AccordionDetails,
  Box,
  Chip,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import AppAccordionSummary from 'components/daily-journal/AppAccordionSummary';
import TextEditor from 'components/shared/text-editor/TextEditor';
import CandleStickChart from 'components/tradingview-charts/CandleStickChart';
import { Gapper } from 'models/gapper';
import StarIcon from '@mui/icons-material/Star';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';

interface GappersAccordionProps {
  item: Gapper;
  accordionChange: (item: Gapper) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  markAsFavourite: (item: Gapper) => void;
  deleteGapper: (item: Gapper) => void;
  edit: (item: Gapper) => void;
  saveNotes: (value: string) => void;
  selectedGapper: Gapper | null;
}

const GappersAccordion: React.FC<GappersAccordionProps> = ({
  item,
  selectedGapper,
  accordionChange,
  markAsFavourite,
  deleteGapper,
  edit,
  saveNotes
}) => {
  const date = moment(item.date).format('LL');
  return (
    <Accordion
      key={item.id}
      sx={{ position: 'inherit' }}
      expanded={selectedGapper?.id === item.id}
      onChange={accordionChange(item)}
      TransitionProps={{ unmountOnExit: true }}>
      <AppAccordionSummary
        title={<Typography variant="subtitle1">{item.symbol}</Typography>}
        subTitle={<Typography sx={{ fontSize: '12px' }}>{date}</Typography>}
        rightElement={
          <Box>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                markAsFavourite(item);
              }}>
              <StarIcon sx={{ color: item.isFavorite ? '#FFD700' : '' }} />
            </IconButton>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                edit(item);
              }}>
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                deleteGapper(item);
              }}>
              <DeleteIcon />
            </IconButton>
          </Box>
        }
        tags={
          item.tags &&
          item.tags.map((tag) => {
            return <Chip label={tag.name} size="small" color="secondary" />;
          })
        }
      />
      <AccordionDetails sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <Grid container spacing={2} sx={{ width: '100%', margin: 0, marginBottom: '20px' }}>
          <Grid item xs={12} sx={{ padding: '0 !important' }}>
            <CandleStickChart symbol={item.symbol} date={item.date} height="500px" />
          </Grid>
          <Grid item xs={2}>
            <div className="label">Gap</div>
            <div className="text">{item.gap} %</div>
          </Grid>
          <Grid item xs={2}>
            <div className="label">Float</div>
            <div className="text">{item.float}</div>
          </Grid>
          <Grid item xs={2}>
            <div className="label">Market Cap.</div>
            <div className="text">{item.marketCap}</div>
          </Grid>
          <Grid item xs={2}>
            <div className="label">Sector</div>
            <div className="text">{item.sector}</div>
          </Grid>
          <Grid item xs={2}>
            <div className="label">Inst. Own.</div>
            <div className="text">{item.instOwn} %</div>
          </Grid>
          <Grid item xs={2}>
            <div className="label">Day</div>
            <div className="text">{item.day}</div>
          </Grid>
          <Grid item xs={4}>
            <div className="label">News</div>
            <div className="text">{item.news}</div>
          </Grid>
          <Grid item xs={3}>
            <div className="label">Dilution</div>
            <div className="text">{item.dilution}</div>
          </Grid>
          <Grid item xs={3}>
            <div className="label">Comment</div>
            <div className="text">{item.comment}</div>
          </Grid>
          <Grid item xs={2}>
            <div className="label">Time</div>
            <div className="text">{item.time}</div>
          </Grid>
        </Grid>
        <TextEditor editorKey={item.id} onSave={saveNotes} notes={item.notes} />
      </AccordionDetails>
    </Accordion>
  );
};

export default GappersAccordion;

import { Box, Typography } from '@mui/material';
import React from 'react';

const Imprint: React.FC = () => {
  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h4">Impressum</Typography>
      <span>Farid Rahimi</span>
      <br />
      <span>Margarethen 49a</span>
      <br />
      <span>4020 Linz</span>
      <br />
      <span>info@faridrahimi.at</span>
    </Box>
  );
};

export default Imprint;

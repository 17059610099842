import axios from 'axios';
import { ChartDataSeries } from 'models/chart/chartDataSeries';
import moment from 'moment';

class ChartService {
  async loadData(date: Date, symbol: string, interval: string): Promise<ChartDataSeries> {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/chart/data/${symbol}/${formattedDate}/${interval}`
    );
    return response.data;
  }
}

export default new ChartService();

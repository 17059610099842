import TableRowsIcon from '@mui/icons-material/TableRows';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import WindowIcon from '@mui/icons-material/Window';
import {
  Box,
  FormControl,
  ListItemIcon,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Fab from 'components/shared/fab/Fab';
import { Gapper } from 'models/gapper';
import { useSnackbar } from 'providers/SnackbarProvider';
import React, { useEffect, useState } from 'react';
import gappersService from 'services/gappersService';
import {
  deleteGapper,
  loadGappers,
  saveGapper,
  selectGappers,
  selectPagination
} from 'store/gapperSlice';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import { loadTags } from 'store/tagsSlice';
import { FilterModel } from './FilterModel';
import GapperFilter from './GapperFilter';
import GapperPagination from './GapperPagination';
import './Gappers.scss';
import GappersAccordion from './GappersAccordion';
import GappersDialog from './GappersDialog';
import GappersFormDialog from './GappersFormDialog';
import GappersGrid from './GappersGrid';
import BallotIcon from '@mui/icons-material/Ballot';
import GapperDetails from './GapperDetails';
import { useLocation } from 'react-router-dom';

type Layout = 'details' | 'list' | 'grid' | 'large-grid';

const Gappers: React.FC = () => {
  const [layout, setLayout] = useState<'details' | 'list' | 'grid' | 'large-grid'>('details');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<string>('10');
  const [filter, setFilter] = useState<FilterModel | null>(null);
  const [isGapperDialogOpen, setGapperDialogOpen] = useState(false);
  const [selectedGapper, setSelectedGapper] = useState<Gapper | null>(null);
  const [gapperToEdit, setGapperToEdit] = useState<Gapper | undefined>(undefined);
  const { showSnackbar } = useSnackbar();
  const dispatch = useStoreDispatch();
  const gappers = useStoreSelector(selectGappers);
  const pagination = useStoreSelector(selectPagination);

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const urlPage = parseInt(query.get('page') || '1', 10);

  useEffect(() => {
    dispatch(loadTags());
    dispatch(
      loadGappers({
        filter: filter,
        page: page,
        rowsPerPage: rowsPerPage
      })
    );
  }, [page, rowsPerPage, filter]);

  useEffect(() => {
    setPage(0);
  }, [filter]);

  useEffect(() => {
    setPage(urlPage);
  }, [urlPage]);

  const handleUpload = (files: FileList) => {
    gappersService
      .upload(files[0])
      .then(() => {
        setDialogOpen(false);
        showSnackbar({ message: 'Gappers uploaded successfully.', type: 'success' });
      })
      .catch(() => {
        showSnackbar({ message: 'Failed to upload gappers', type: 'error' });
      });
  };

  const onSaveNotes = (value: string) => {
    const item = { ...selectedGapper, notes: value } as Gapper;
    onSave(item);
  };

  const handleMarkAsFavorite = (gapper: Gapper) => {
    const item = { ...gapper, isFavorite: !gapper?.isFavorite } as Gapper;
    onSave(item);
  };

  const handleDeleteGapper = (gapper: Gapper) => {
    dispatch(deleteGapper({ gapper }));
  };

  const onSave = (item: Gapper) => {
    dispatch(saveGapper({ gapper: item }))
      .unwrap()
      .then(() => {
        setGapperToEdit(undefined);
        setGapperDialogOpen(false);
        setSelectedGapper(item);
        showSnackbar({ message: 'Favorite notes saved successfully', type: 'success' });
      });
  };

  const handleChange = (item: Gapper) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    const newEntry = isExpanded ? item : null;
    setSelectedGapper(newEntry);
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleEditItem = (item: Gapper) => {
    setGapperDialogOpen(true);
    setGapperToEdit(item);
  };

  const onCancelEdit = () => {
    setGapperDialogOpen(false);
    setGapperToEdit(undefined);
  };

  const handleFilterChange = (filter: FilterModel) => {
    setFilter(filter);
  };

  const handleLayoutChange = (event: SelectChangeEvent) => {
    if (event.target.value === 'large-grid') {
      setRowsPerPage('5');
    }
    setLayout(event.target.value as Layout);
  };

  return (
    <React.Fragment>
      <Paper className="page-header gappers-header" elevation={2}>
        <Typography variant="h6" sx={{ fontWeight: '500' }}>
          Gappers
        </Typography>

        <div className="right-panel">
          <GapperFilter onFilter={handleFilterChange} />
        </div>
      </Paper>

      <Box
        className="page-content gappers"
        sx={{
          display: 'flex',
          flexDirection: 'column !important'
        }}>
        <div
          id="scrollableDiv"
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '1px',
            marginLeft: '10px',
            paddingBottom: '50px',
            height: '100%',
            overflowY: 'scroll'
          }}>
          <div
            className="gapper-header"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <span>Total: {pagination?.total}</span>
            <FormControl
              sx={{
                m: 1,
                minWidth: 80,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 0,
                margin: 0
              }}>
              Layout:
              <Select
                id="pagination-rows-per-page"
                value={layout}
                onChange={handleLayoutChange}
                autoWidth
                variant="outlined"
                label="Rows per page"
                sx={{
                  paddingRight: '0 !important',
                  border: 'none',
                  '& fieldset': { border: 'none' },
                  '& .MuiSelect-select': { paddingRight: '0 !important' }
                }}>
                <MenuItem value="details">
                  <ListItemIcon>
                    <BallotIcon />
                  </ListItemIcon>
                </MenuItem>
                <MenuItem value="list">
                  <ListItemIcon>
                    <TableRowsIcon />
                  </ListItemIcon>
                </MenuItem>
                <MenuItem value="grid">
                  <ListItemIcon>
                    <WindowIcon />
                  </ListItemIcon>
                </MenuItem>
                <MenuItem value="large-grid">
                  <ListItemIcon>
                    <ViewAgendaIcon />
                  </ListItemIcon>
                </MenuItem>
              </Select>
            </FormControl>
          </div>

          <Grid2 container spacing={(layout as string) === 'list' ? 0 : 2}>
            {gappers &&
              gappers.map((item) => {
                return (
                  <React.Fragment>
                    {(layout as string) === 'list' && (
                      <Grid2 xs={12} key={item.id}>
                        <GappersAccordion
                          selectedGapper={selectedGapper}
                          accordionChange={handleChange}
                          edit={handleEditItem}
                          markAsFavourite={handleMarkAsFavorite}
                          deleteGapper={handleDeleteGapper}
                          item={item}
                          saveNotes={onSaveNotes}
                        />
                      </Grid2>
                    )}

                    {['grid', 'large-grid'].includes(layout) && (
                      <Grid2 xs={(layout as string) === 'grid' ? 6 : 12} key={item.id}>
                        <GappersGrid
                          selectedGapper={selectedGapper}
                          accordionChange={handleChange}
                          edit={handleEditItem}
                          markAsFavourite={handleMarkAsFavorite}
                          deleteGapper={handleDeleteGapper}
                          item={item}
                          saveNotes={onSaveNotes}
                          gridSize={(layout as string) === 'grid' ? 6 : 12}
                        />
                      </Grid2>
                    )}

                    {(layout as string) === 'details' && (
                      <Grid2 xs={4}>
                        <GapperDetails gapper={item} markAsFavourite={handleMarkAsFavorite} />
                      </Grid2>
                    )}
                  </React.Fragment>
                );
              })}
          </Grid2>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          className="pagination">
          <GapperPagination
            lastPage={pagination?.lastPage}
            defaultRowsPerPage={rowsPerPage}
            pageChanged={(value) => setPage(value)}
            rowsPerPageChanged={(value) => setRowsPerPage(value)}
          />
        </div>
      </Box>

      <Fab onClick={handleOpenDialog}></Fab>
      <GappersDialog
        accept=".csv,.json"
        title="Upload Gappers"
        isOpen={isDialogOpen}
        onUpload={handleUpload}
        onClose={handleCloseDialog}
      />

      <GappersFormDialog
        gapperToEdit={gapperToEdit}
        onCancel={onCancelEdit}
        onSubmit={onSave}
        open={isGapperDialogOpen}
      />
    </React.Fragment>
  );
};

export default Gappers;

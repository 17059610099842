import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';
import { Box, Chip, IconButton, Paper, Typography } from '@mui/material';
import CandleStickChart from 'components/tradingview-charts/CandleStickChart';
import { Gapper } from 'models/gapper';
import moment from 'moment';

interface GappersGridProps {
  item: Gapper;
  accordionChange: (item: Gapper) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  markAsFavourite: (item: Gapper) => void;
  deleteGapper: (item: Gapper) => void;
  edit: (item: Gapper) => void;
  saveNotes: (value: string) => void;
  selectedGapper: Gapper | null;
  gridSize: number;
}

const GappersGrid: React.FC<GappersGridProps> = ({
  item,
  gridSize,
  markAsFavourite,
  deleteGapper,
  edit
}) => {
  const date = moment(item.date).format('LL');
  return (
    <Paper elevation={2} key={item.id + gridSize} sx={{ overflow: 'hidden' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
        className="gapper-grid-header">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingLeft: '5px'
          }}
          className="title">
          <div>
            <Typography variant="subtitle1">{item.symbol}</Typography>
            <Typography sx={{ fontSize: '12px' }}>{date}</Typography>
          </div>

          {item.tags &&
            item.tags.map((tag) => {
              return <Chip label={tag.name} size="small" color="secondary" />;
            })}
        </div>
        <div className="actions">
          <Box>
            {item.float} M
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                markAsFavourite(item);
              }}>
              <StarIcon sx={{ color: item.isFavorite ? '#FFD700' : '' }} />
            </IconButton>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                edit(item);
              }}>
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                deleteGapper(item);
              }}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </div>
      </div>
      <div className="chart">
        <CandleStickChart
          symbol={item.symbol}
          date={item.date}
          height={gridSize === 6 ? '500px' : '800px'}
        />
      </div>
    </Paper>
  );
};

export default GappersGrid;

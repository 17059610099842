import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material';
import React, { useEffect, useState } from 'react';

export interface DropDownOption {
  key: string;
  value: string;
}

interface SelectDropDownProps {
  options: DropDownOption[];
  label: string;
  selectedOption?: DropDownOption | null;
  isRequired?: boolean;
  onSelectChange: (selectedOption: DropDownOption | null) => void;
}

const SelectDropDown: React.FC<SelectDropDownProps> = ({
  options,
  label,
  selectedOption,
  isRequired = false,
  onSelectChange
}) => {
  const [item, setItem] = useState<DropDownOption | null>(null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (selectedOption) {
      setItem(selectedOption);
      onSelectChange(selectedOption);
    }
  }, []);

  const handleChangeCurrentAccount = (event: SelectChangeEvent) => {
    const filteredOptions = options.filter((option) => option.key === event.target.value);
    if (filteredOptions.length > 0) {
      setItem(filteredOptions[0]);
      onSelectChange(filteredOptions[0]);
      setError('');
    } else {
      setItem(null);
      onSelectChange(null);
      if (isRequired) {
        setError('This field is required');
      }
    }
  };

  return (
    <React.Fragment>
      {options && (
        <FormControl variant="standard" error={!!error} fullWidth>
          <InputLabel id="account-select-label">{label}</InputLabel>
          <Select
            labelId="account-select-label"
            id="account-select"
            value={item ? item.key : ''}
            onChange={handleChangeCurrentAccount}
            label={label}>
            {options.map((option) => (
              <MenuItem key={option.key} value={option.key}>
                {option.value}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{error}</FormHelperText>
        </FormControl>
      )}
    </React.Fragment>
  );
};

export default SelectDropDown;

import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Slide,
  Toolbar
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import axios from 'axios';
import MaxHeightTextarea from 'components/textarea/MaxHeightTextarea';
import { Filing } from 'models/filings';
import React, { useEffect, useState } from 'react';
import {
  loadMessages,
  selectFilingDocument,
  selectIsMessagesLoading,
  selectIsTickersLoading,
  selectMessages
} from 'store/filingsSlice';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import DefaultChatActions from './DefaultChatActions';
import { Message } from 'models/message';
import MessageComponent from './MessageComponent';
import RefreshIcon from '@mui/icons-material/Refresh';

interface FilingDialogProps {
  open: boolean;
  filing: Filing | null;
  close: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FilingDialog: React.FC<FilingDialogProps> = ({ open, filing, close }) => {
  const [prompt, setPrompt] = useState<string | null>(null);
  const [sortedMessages, setSortedMessages] = useState<Message[] | null>(null);
  const dispatch = useStoreDispatch();
  const filingDocument = useStoreSelector(selectFilingDocument);
  const isLoading = useStoreSelector(selectIsTickersLoading);
  const isMessageLoading = useStoreSelector(selectIsMessagesLoading);
  const messages = useStoreSelector(selectMessages);

  useEffect(() => {
    const sorted = [...messages].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    setSortedMessages(sorted);
  }, [messages]);

  useEffect(() => {
    dispatch(loadMessages());
  }, [filing]);

  const handleChatChange = (value: string) => {
    setPrompt(value);
  };

  const refresh = () => {
    dispatch(loadMessages());
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={close}
      TransitionComponent={Transition}
      sx={{ zIndex: '9999999999' }}>
      <AppBar sx={{ position: 'relative', height: '50px', minHeight: '50px !important' }}>
        <Toolbar
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            minHeight: '50px !important'
          }}>
          <IconButton edge="start" color="inherit" onClick={close} aria-label="close">
            <CloseIcon />
          </IconButton>

          <IconButton edge="start" color="inherit" onClick={refresh} aria-label="close">
            <RefreshIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <div
        className="content"
        style={{
          display: 'flex',
          flexDirection: 'row',
          overflow: 'auto',
          width: '100%',
          height: '100%'
        }}>
        {isLoading && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%'
            }}>
            <CircularProgress />
          </Box>
        )}
        {!isLoading && (
          <React.Fragment>
            <div
              className="filing"
              style={{
                flex: 1,
                borderRight: '1px solid black',
                padding: '10px',
                overflow: 'auto'
              }}>
              {filing && (
                <div dangerouslySetInnerHTML={{ __html: filingDocument?.document || '' }}></div>
              )}
            </div>
            <div
              className="chat"
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                padding: '10px'
              }}>
              <div
                className="content"
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '15px',
                  paddingBottom: '15px',
                  overflow: 'hidden'
                }}>
                <div
                  className="messages"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    overflow: 'auto',
                    width: '100%'
                  }}>
                  <DefaultChatActions />
                  {sortedMessages &&
                    sortedMessages.map((message) => (
                      <MessageComponent
                        user={message.role}
                        text={message.content}
                        position={message.role === 'user' ? 'right' : 'left'}
                      />
                    ))}
                </div>
              </div>
              <div
                className="chat-input"
                style={{
                  flex: 0,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end'
                }}>
                <MaxHeightTextarea key="chat-prompt" onChange={handleChatChange} />

                <Button
                  variant="contained"
                  disabled={isMessageLoading}
                  endIcon={isMessageLoading ? <CircularProgress size={'25px'} /> : <SendIcon />}
                  sx={{ height: '50px' }}>
                  Send
                </Button>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </Dialog>
  );
};

export default FilingDialog;

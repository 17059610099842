import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Ticker } from 'models/ticker';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useStoreSelector } from 'store/store';
import { selectSectors } from 'store/watchlistSlice';
import * as Yup from 'yup';

interface TickerDialogProps {
  open: boolean;
  onSubmit: (item: string) => void;
  onCancel: () => void;
}

interface TickerForm {
  ticker: string;
}

const TickerDialog: React.FC<TickerDialogProps> = ({ open, onSubmit, onCancel }) => {
  const validationSchema = Yup.object().shape({
    ticker: Yup.string().required('Ticker is required')
  });

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<TickerForm>({
    resolver: yupResolver(validationSchema)
  });

  const textFieldStyle = {
    marginTop: '0px',
    marginBottom: '0px'
  };

  useEffect(() => {
    resetForm();
  }, [reset, open]);

  const submitForm = (data: TickerForm) => {
    const item = data.ticker;
    resetForm();
    onSubmit(item);
  };

  const resetForm = () => {
    reset({
      ticker: undefined
    });
  };

  return (
    <Dialog open={open} disableEscapeKeyDown>
      <form noValidate onSubmit={handleSubmit(submitForm)}>
        <DialogTitle>New Ticker</DialogTitle>
        <DialogContent>
          <Grid2 container columnSpacing={2}>
            <Grid2 xs={12}>
              <TextField
                placeholder="AAPL"
                label="Ticker"
                type="text"
                variant="standard"
                fullWidth
                {...register('ticker')}
                error={errors.ticker ? true : false}
                helperText={errors.ticker && errors.ticker?.message}
                style={textFieldStyle}
                autoFocus
                inputProps={{ style: { textTransform: 'uppercase' } }}
              />
            </Grid2>
          </Grid2>
        </DialogContent>

        <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              onClick={onCancel}
              variant="contained"
              color="gray"
              fullWidth
              sx={{ flexGrow: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" fullWidth sx={{ flexGrow: 1 }}>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default TickerDialog;

import axios, { AxiosResponse } from 'axios';
import { Journal } from 'models/journal';
import { JournalEntry } from 'models/journalEntry';

class JournalService {
  async loadJournals(): Promise<AxiosResponse<Journal[]>> {
    try {
      return await axios.get(`${process.env.REACT_APP_API_URL}/journal`);
    } catch (error) {
      console.log('Failed to load journals', error);
      throw error;
    }
  }

  async saveJournalNotes(
    notes: string,
    journalEntry: JournalEntry
  ): Promise<AxiosResponse<JournalEntry>> {
    try {
      return await axios.put(`${process.env.REACT_APP_API_URL}/journal-entry/${journalEntry.id}`, {
        notes
      });
    } catch (error) {
      throw error;
    }
  }
}

export default new JournalService();

import axios from 'axios';
import { resetUser } from 'store/userSlice';

let store: any;

export const injectStore = (_store: any) => {
  store = _store;
};

axios.defaults.withCredentials = true;

const redirectToLogin = () => {
  store.dispatch(resetUser());
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      redirectToLogin();
    }
    return Promise.reject(error);
  }
);

export default axios;

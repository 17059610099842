import { Fab as FabProps, Zoom, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface FabProps {
  onClick: () => void;
}

const Fab: React.FC<FabProps> = ({ onClick }) => {
  const theme = useTheme();

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen
  };

  return (
    <Zoom
      in={true}
      timeout={transitionDuration}
      style={{
        transitionDelay: `${transitionDuration.exit}ms`
      }}
      unmountOnExit>
      <FabProps
        color="primary"
        aria-label="add"
        onClick={onClick}
        sx={{ position: 'absolute', bottom: '40px', right: '20px' }}>
        <AddIcon />
      </FabProps>
    </Zoom>
  );
};

export default Fab;

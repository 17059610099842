import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import Fab from 'components/shared/fab/Fab';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import {
  loadWatchlist,
  selectCurrentWatchlist,
  loadSectors,
  createWatchlist,
  setSymbol,
  setDate,
  selectSymbol,
  selectDate,
  selectCurrentStock,
  createNewWatchlistItem,
  saveWatchlistStock,
  setEditingStock
} from 'store/watchlistSlice';
import React, { useEffect, useRef, useState } from 'react';
import WatchlistList from './WatchlistList';
import './DailyWatchlist.scss';
import WatchlistItemFormDialog from './WatchlistItemFormDialog';
import { WatchlistEntry } from 'models/watchlistEntry';
import { useSnackbar } from 'providers/SnackbarProvider';
import ConfirmationDialog from 'components/shared/confirmation-dialog/ConfirmationDialog';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { useController, useForm } from 'react-hook-form';
import DatePicker from 'components/shared/datepicker/DatePicker';
import moment from 'moment';
import WatchlistStocks from './WatchlistStocks';
import TextEditor from 'components/shared/text-editor/TextEditor';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddchartIcon from '@mui/icons-material/Addchart';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

const DailyWatchlist: React.FC = () => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const dispatch = useStoreDispatch();
  const snackbar = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const symbol = useStoreSelector(selectSymbol);
  const date = useStoreSelector(selectDate);
  const selectedStock = useStoreSelector(selectCurrentStock);
  const selectedWatchlist = useStoreSelector(selectCurrentWatchlist);

  const textFieldRef = useRef<HTMLInputElement>(null);

  const { control, setValue } = useForm();
  const {
    fieldState: { error }
  } = useController({
    name: 'date',
    control
  });

  useEffect(() => {
    dispatch(loadSectors());
    dispatch(loadWatchlist());
  }, []);

  useEffect(() => {
    if (date) {
      setValue('date', new Date(date));
    }
  }, [date]);

  const handleCreateWatchlistItem = (item: string) => {
    if (selectedWatchlist) {
      dispatch(createNewWatchlistItem({ item: item }))
        .unwrap()
        .then(() => {
          snackbar.showSnackbar({ message: 'Watchlist was successfully saved', type: 'success' });
          setDialogOpen(false);
        })
        .catch(() => {
          snackbar.showSnackbar({ message: 'Failed to save watchlist', type: 'error' });
        });
    }
  };

  const openDialog = () => {
    // setItemToEdit(null);
    return setDialogOpen(true);
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNewWatchlist = () => {
    dispatch(createWatchlist());
    setAnchorEl(null);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleSetDate = (date: Date | null) => {
    const dateString = date ? moment(date).format('YYYY-MM-DD') : '';
    dispatch(setDate(dateString));
  };

  const handleSetSymbol = (event: any) => {
    dispatch(setSymbol(event.target.value));
  };

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const handleSaveNote = (value: string) => {
    if (selectedStock) {
      dispatch(saveWatchlistStock({ ...selectedStock, note: value }))
        .unwrap()
        .then(() => {
          snackbar.showSnackbar({ message: 'Page saved successfully', type: 'success' });
        });
    }
  };

  const handleEditingStarted = () => {
    dispatch(setEditingStock(selectedStock));
  };

  const handleSaveStock = () => {
    // if (textFieldRef.current && selectedPage) {
    //   dispatch(savePage({ ...selectedPage, title: textFieldRef.current.value }));
    //   setEditMode(false);
    // }
  };

  const handleAddNewStock = () => {};

  return (
    <React.Fragment>
      <Paper className="page-header watchlist-header" elevation={2}>
        <Typography variant="h6" sx={{ fontWeight: '500', flex: 1 }}>
          Daily Watchlist
        </Typography>

        <Grid2
          container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flex: 3,
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '10px'
          }}>
          <Grid2 xs={2} display="flex" justifyContent="center" alignItems="flex-start" padding={0}>
            <TextField
              id="standard-basic"
              label="Symbol"
              variant="standard"
              placeholder="Filter by symbol"
              defaultValue={symbol}
              onChange={(event) => handleSetSymbol(event)}
            />
          </Grid2>
          <Grid2 container xs={2}>
            <DatePicker
              control={control}
              name="date"
              errors={{ birthdate: error }}
              label="Date"
              placeholder="Filter by date"
              onChange={(date: Date | null) => handleSetDate(date)}
            />
          </Grid2>
        </Grid2>

        <div className="menu">
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button'
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}>
            <MenuItem onClick={handleNewWatchlist}>New Watchlist</MenuItem>
            <MenuItem onClick={openDialog}>New Stock</MenuItem>
          </Menu>
        </div>
      </Paper>

      <Box className="watchlist page-content" sx={{ display: 'flex', flexDirection: 'row' }}>
        <WatchlistList />
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            overflow: 'hidden',
            gap: 2
          }}>
          <WatchlistStocks />
          {selectedStock && (
            <div className="watchlist-content">
              <div className="watchlist-content-header">
                <div className="title">
                  {editMode ? (
                    <div className="edit-title">
                      <TextField
                        inputRef={textFieldRef}
                        className="title-field"
                        id="section-title"
                        label="Title"
                        variant="standard"
                        inputProps={{ style: { fontSize: 15 } }}
                        defaultValue={selectedStock?.ticker}
                      />

                      <div className="buttons">
                        <IconButton aria-label="save" size="small" onClick={handleSaveStock}>
                          <SaveIcon />
                        </IconButton>

                        <IconButton aria-label="cancel" size="small" onClick={handleCancelEdit}>
                          <CancelIcon />
                        </IconButton>
                      </div>
                    </div>
                  ) : (
                    <div onDoubleClick={toggleEditMode}>
                      <Typography
                        variant="h5"
                        sx={{
                          textAlign: 'center',
                          fontWeight: 'bold',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: '10px'
                        }}>
                        {selectedStock?.ticker}
                        <IconButton onClick={toggleEditMode}>
                          <EditIcon />
                        </IconButton>
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="notebook-page-body"
                style={{ overflow: 'hidden', flex: '1', paddingBottom: '75px' }}>
                <Box
                  sx={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                  }}>
                  <TextEditor
                    editorKey={selectedStock.id}
                    notes={selectedStock?.note}
                    onSave={handleSaveNote}
                    editingStarted={handleEditingStarted}
                  />
                </Box>
              </div>
            </div>
          )}
        </Paper>

        <WatchlistItemFormDialog
          open={isDialogOpen}
          onSubmit={handleCreateWatchlistItem}
          onCancel={handleCancel}></WatchlistItemFormDialog>

        <Fab onClick={openDialog}></Fab>
      </Box>
    </React.Fragment>
  );
};

export default DailyWatchlist;

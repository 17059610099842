import { Action, Middleware, ThunkDispatch, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import User from 'models/user';
import UserRegistration from 'models/userRegistration';
import authService from 'services/authService';
import userService from 'services/userService';
import { RootState } from './store';
import { loadAccounts } from './accountSlice';

export interface UserState {
  user?: User;
}

const initialState: UserState = {
  user: undefined
};

interface LoginAttributes {
  email: string;
  password: string;
}

export const login = createAsyncThunk<User, LoginAttributes>('user/login', async (login) => {
  const response = await authService.login(login.email, login.password);
  return response.data;
});

export const logout = createAsyncThunk('user/logout', async () => {
  const response = await authService.logout();
  return response.data;
});

export const registerUser = createAsyncThunk<User, UserRegistration>(
  'user/register',
  async (user) => {
    const response = await userService.register(user);
    return response.data;
  }
);

export const loadCurrentUser = createAsyncThunk('user/current', async () => {
  const response = await authService.getCurrentUser();
  return response.data;
});

export const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUser: (state) => {
      state.user = undefined;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(registerUser.fulfilled, (state) => {
        state.user = undefined;
      })
      .addCase(login.fulfilled, (state, action) => {
        // state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {})
      .addCase(logout.fulfilled, (state, action) => {
        state.user = undefined;
      })
      .addCase(loadCurrentUser.fulfilled, (state, action) => {
        state.user = action.payload;
      });
  }
});

export const userMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  let result = next(action);

  if (action.type === login.fulfilled.type) {
    (storeAPI.dispatch as ThunkDispatch<RootState, void, Action>)(loadCurrentUser());
    (storeAPI.dispatch as ThunkDispatch<RootState, void, Action>)(loadAccounts());
  }

  return result;
};

export const selectUser = (state: { user: UserState }) => state.user.user;
export const { resetUser } = userSlice.actions;

export default userSlice.reducer;

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import DatePicker from 'components/shared/datepicker/DatePicker';
import { Runner } from 'models/runner';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';

interface SetupForm {
  id: number | undefined | null;
  symbol: string;
  name: string | undefined;
  date: Date;
  news: string | undefined;
  float: number;
  dilution: string | undefined;
  day: number;
  marketCycle: string | undefined;
  isPumpAndDump: boolean | undefined;
  marketCap: number | undefined;
}

interface SetupsDialogProps {
  itemToEdit: Runner | null;
  open: boolean;
  onSubmit: (item: Runner) => void;
  onCancel: () => void;
}

const SetupsDialog: React.FC<SetupsDialogProps> = ({ itemToEdit, open, onSubmit, onCancel }) => {
  const validationSchema = Yup.object().shape({
    id: Yup.number().nullable(),
    symbol: Yup.string().required('Symbol is required'),
    name: Yup.string().optional(),
    date: Yup.date().required('Date is required'),
    news: Yup.string().optional(),
    float: Yup.number().required('Float is required'),
    marketCap: Yup.number(),
    dilution: Yup.string().optional(),
    day: Yup.number().required('Day is required'),
    marketCycle: Yup.string().optional(),
    isPumpAndDump: Yup.boolean()
  });

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<SetupForm>({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    if (itemToEdit) {
      const defaultValues = {
        id: itemToEdit.id,
        symbol: itemToEdit.symbol,
        date: new Date(itemToEdit.date),
        name: itemToEdit.name,
        news: itemToEdit.news,
        float: itemToEdit.float,
        dilution: itemToEdit.dilution,
        day: itemToEdit.day,
        marketCycle: itemToEdit.marketCycle,
        isPumpAndDump: itemToEdit.isPumpAndDump,
        marketCap: itemToEdit.marketCap
      };
      reset(defaultValues);
    } else {
      resetForm();
    }
  }, [itemToEdit, open]);

  const submitForm = (data: SetupForm) => {
    const setup = {
      ...data
    } as Runner;

    onSubmit(setup);
    resetForm();
  };

  const resetForm = () => {
    reset({
      symbol: '',
      date: undefined
    });
  };

  return (
    <Dialog maxWidth={'sm'} open={open} disableEscapeKeyDown>
      <form noValidate onSubmit={handleSubmit(submitForm)}>
        <DialogTitle>New Setup</DialogTitle>
        <DialogContent>
          <Grid2 container columnSpacing={2}>
            <Grid2 xs={6}>
              <TextField
                placeholder="AAPL"
                label="Symbol"
                type="text"
                variant="standard"
                fullWidth
                {...register('symbol')}
                error={errors.symbol ? true : false}
                helperText={errors.symbol && errors.symbol?.message}
              />
            </Grid2>
            <Grid2 xs={6}>
              <DatePicker
                control={control}
                name="date"
                errors={errors}
                label="Select date"
                placeholder="Select date"
              />
            </Grid2>

            <Grid2 xs={6}>
              <TextField
                label="Float"
                type="number"
                variant="standard"
                fullWidth
                {...register('float')}
                error={errors.float ? true : false}
                helperText={errors.float && errors.float?.message}
                InputProps={{
                  endAdornment: <InputAdornment position="end">in millions</InputAdornment>,
                  sx: {
                    '& input': {
                      textAlign: 'right'
                    }
                  }
                }}
              />
            </Grid2>
            <Grid2 xs={6}>
              <TextField
                label="Market Cap"
                type="number"
                variant="standard"
                fullWidth
                {...register('marketCap')}
                error={errors.marketCap ? true : false}
                helperText={errors.marketCap && errors.marketCap?.message}
                InputProps={{
                  endAdornment: <InputAdornment position="end">in millions</InputAdornment>,
                  sx: {
                    '& input': {
                      textAlign: 'right'
                    }
                  }
                }}
              />
            </Grid2>
            <Grid2 xs={12}>
              <TextField
                placeholder="ATM: $69M"
                label="Dilution"
                type="text"
                variant="standard"
                fullWidth
                {...register('dilution')}
                error={errors.dilution ? true : false}
                helperText={errors.dilution && errors.dilution?.message}
              />
            </Grid2>
            <Grid2 xs={12}>
              <TextField
                placeholder="positive Phase 2"
                label="News"
                type="text"
                variant="standard"
                fullWidth
                {...register('news')}
                error={errors.news ? true : false}
                helperText={errors.news && errors.news?.message}
              />
            </Grid2>
            <Grid2 xs={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel id="day-select-label">Day</InputLabel>
                <Controller
                  name="day"
                  control={control}
                  render={({ field }) => (
                    <Select
                      labelId="day-select-label"
                      id="day-select"
                      label="Day"
                      {...field}
                      error={!!errors.day}>
                      <MenuItem value="">Select Day</MenuItem>
                      <MenuItem value="1">Day 1</MenuItem>
                      <MenuItem value="2">Day 2</MenuItem>
                      <MenuItem value="3">Day 3</MenuItem>
                    </Select>
                  )}
                />
                {errors.day && <FormHelperText>{errors.day.message}</FormHelperText>}
              </FormControl>
            </Grid2>
            <Grid2 xs={6}>
              <TextField
                placeholder="Hot Market"
                label="Market Cycle"
                type="text"
                variant="standard"
                fullWidth
                {...register('marketCycle')}
                error={errors.marketCycle ? true : false}
                helperText={errors.marketCycle && errors.marketCycle?.message}
              />
            </Grid2>

            <Grid2 xs={6}>
              <TextField
                placeholder="Gap and Crap"
                label="Name"
                type="text"
                variant="standard"
                fullWidth
                {...register('name')}
                error={errors.name ? true : false}
                helperText={errors.name && errors.name?.message}
              />
            </Grid2>

            <Grid2 xs={6} sx={{ display: 'flex', alignItems: 'end' }}>
              <FormControlLabel
                className="checkbox-label"
                control={
                  <Controller
                    {...register('isPumpAndDump')}
                    defaultValue={false}
                    control={control}
                    render={({ field }) => <Checkbox checked={field.value} {...field} />}
                  />
                }
                label="Pump and Dump"
                labelPlacement="start"
              />
              {errors.isPumpAndDump && (
                <FormHelperText>{errors.isPumpAndDump?.message}</FormHelperText>
              )}
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              onClick={onCancel}
              variant="contained"
              color="gray"
              fullWidth
              sx={{ flexGrow: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" fullWidth sx={{ flexGrow: 1 }}>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SetupsDialog;

import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Markdown from 'react-markdown';

interface MessageProps {
  user: string;
  text: string;
  position: 'left' | 'right';
}

const MessageComponent: React.FC<MessageProps> = ({ user, text, position }) => {
  return (
    <div
      className="message"
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        justifyContent: position === 'left' ? 'flex-start' : 'flex-end',
        borderBottom: '1px solid #80808057',
        borderRadius: '5px'
      }}>
      <Accordion
        sx={{ backgroundColor: position === 'left' ? '#f6f6f6' : '#e3effd' }}
        defaultExpanded={position === 'right'}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {user} {position === 'right' && text}
        </AccordionSummary>
        {position === 'left' && (
          <AccordionDetails>
            <Markdown unwrapDisallowed={true}>{text}</Markdown>
          </AccordionDetails>
        )}
      </Accordion>
    </div>
  );
};

export default MessageComponent;

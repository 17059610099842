import {
  ColorType,
  CrosshairMode,
  HistogramSeriesPartialOptions,
  IChartApi,
  ISeriesApi,
  LineData,
  SeriesMarkerPosition,
  SeriesMarkerShape,
  Time,
  UTCTimestamp,
  createChart
} from 'lightweight-charts';
import { Execution } from 'models/execution';
import moment from 'moment-timezone';
import { DateUtils } from './DateUtils';
import { ChartData } from 'models/chart/chartData';

const getChartOptions = (width: number, height: number) => {
  return {
    width: width,
    height: height,
    layout: {
      background: { type: ColorType.Solid, color: '#253248' },
      textColor: 'rgba(255, 255, 255, 0.9)'
    },
    grid: {
      vertLines: {
        color: '#334158'
      },
      horzLines: {
        color: '#334158'
      }
    },
    crosshair: {
      mode: CrosshairMode.Normal
    },
    timeScale: {
      borderColor: '#485c7b',
      timeVisible: true,
      secondsVisible: false
    },
    localization: {
      dateFormat: 'dd MMM yyyy'
    }
  };
};

const volumeOptions: HistogramSeriesPartialOptions = {
  priceFormat: {
    type: 'volume'
  },
  priceLineVisible: false,
  lastValueVisible: false
};

export class ChartUtils {
  static createChart(
    container: React.MutableRefObject<HTMLDivElement | null>,
    height?: number,
    width?: number
  ): IChartApi {
    const chartWidth = width ? width : container.current!.clientWidth;
    const chartWeight = height ? height : container.current!.clientHeight;
    const chartOptions = getChartOptions(chartWidth, chartWeight);
    return createChart(container.current!, chartOptions);
  }

  static addCandlestickSeries(chart: IChartApi): ISeriesApi<'Candlestick'> {
    const candleSeries = chart.addCandlestickSeries();
    return candleSeries;
  }

  static addHistogramSeries(chart: IChartApi): ISeriesApi<'Histogram'> {
    const volumeSeries = chart.addHistogramSeries(volumeOptions);
    return volumeSeries;
  }

  static addWatermark(chart: IChartApi, text: string) {
    chart.applyOptions({
      watermark: {
        visible: true,
        fontSize: 35,
        horzAlign: 'center',
        vertAlign: 'center',
        color: 'rgba(128, 128, 128, 0.6)',
        text: text
      }
    });
  }

  static syncTimeScale(candles: IChartApi, volume: IChartApi) {
    candles.timeScale().subscribeVisibleLogicalRangeChange((range) => {
      if (range) {
        volume.timeScale().setVisibleLogicalRange(range);
      }
    });

    volume.timeScale().subscribeVisibleLogicalRangeChange((range) => {
      if (range) {
        candles.timeScale().setVisibleLogicalRange(range);
      }
    });
  }

  static setVisibleRange(date: Date, candles: IChartApi): void {
    const fromDate = moment(date).clone().hour(10).minute(0).second(0).millisecond(0);
    const toDateTime = moment(date).clone().hour(22).minute(0).second(0).millisecond(0);

    candles.timeScale().setVisibleRange({
      from: DateUtils.toEasternTimeZone(fromDate.unix()) as Time,
      to: DateUtils.toEasternTimeZone(toDateTime.unix()) as Time
    });
  }

  static addTrades(chart: IChartApi, lineData: LineData[], executions: Execution[]) {
    const lineSeries = chart.addLineSeries({
      color: 'rgba(0, 0, 0, 0)',
      crosshairMarkerVisible: false,
      priceLineVisible: false
    });
    lineSeries.setData(lineData);
    const markers = executions?.map((execution) => {
      const isBuy = ChartUtils.isBuy(execution);
      return {
        time: DateUtils.isoToTimestamp(execution.date) as UTCTimestamp,
        position: isBuy ? 'belowBar' : ('aboveBar' as SeriesMarkerPosition),
        color: isBuy ? 'white' : '#FFA500',
        shape: isBuy ? 'arrowUp' : ('arrowDown' as SeriesMarkerShape)
      };
    });
    if (markers) {
      lineSeries.setMarkers(markers);
    }
  }

  static addVWAP(chart: IChartApi, data: ChartData[]) {
    const vwapData = ChartUtils.computeVWAP(data);
    const vwapSeries = chart.addLineSeries({
      color: '#f39c12',
      lineWidth: 1,
      priceLineVisible: false,
      crosshairMarkerVisible: false
    });
    vwapSeries.setData(vwapData);
  }

  private static isBuy(execution: Execution) {
    return (
      execution.side.toLocaleLowerCase() === 'buy' ||
      execution.side === 'B' ||
      execution.side === 'BC'
    );
  }

  private static computeVWAP(stockData: ChartData[]) {
    let cumulativeVolume = 0;
    let cumulativePriceVolume = 0;
    let currentDay: Date | null = null;

    return stockData.map((data) => {
      const time = DateUtils.toEasternTimeZone(data.time);
      const currentDataDay = new Date(time * 1000);
      const avgPrice = (data.high + data.low + data.close) / 3;

      if (currentDay != null && !DateUtils.isSameDay(currentDay, currentDataDay)) {
        cumulativeVolume = 0;
        cumulativePriceVolume = 0;
      }

      cumulativePriceVolume += avgPrice * data.volume;
      cumulativeVolume += data.volume;
      currentDay = currentDataDay;

      return {
        time: time,
        value: cumulativePriceVolume / (cumulativeVolume > 0 ? cumulativeVolume : 1)
      } as LineData;
    });
  }
}

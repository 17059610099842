import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import AppAutocomplete from 'components/shared/autocomplete/Autocomplete';
import { Gapper } from 'models/gapper';
import { Tag } from 'models/tag';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useStoreSelector } from 'store/store';
import { selectTags } from 'store/tagsSlice';
import * as Yup from 'yup';

interface GappersFormDialogProps {
  gapperToEdit?: Gapper;
  open: boolean;
  onSubmit: (gapper: Gapper) => void;
  onCancel: () => void;
}

interface GapperForm {
  id: number | undefined;
  tags: Tag[] | undefined;
}

const GappersFormDialog: React.FC<GappersFormDialogProps> = ({
  gapperToEdit,
  open,
  onSubmit,
  onCancel
}) => {
  const tags: Tag[] = useStoreSelector(selectTags);
  const validationSchema = Yup.object().shape({
    id: Yup.number(),
    tags: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().required(),
        name: Yup.string().required()
      })
    )
  });

  const textFieldStyle = {
    marginTop: '0px',
    marginBottom: '0px'
  };

  const {
    reset,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<GapperForm>({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    if (gapperToEdit) {
      const newDefaultValues = {
        id: gapperToEdit.id,
        tags: gapperToEdit.tags
      };

      reset(newDefaultValues);
    } else {
      resetForm();
    }
  }, [gapperToEdit, reset]);

  const resetForm = () => {
    reset({
      id: undefined,
      tags: []
    });
  };

  const submitForm = (data: GapperForm) => {
    const item = { ...gapperToEdit, tags: data.tags } as Gapper;
    resetForm();
    onSubmit(item);
  };

  return (
    <Dialog open={open} className="playbook-form" disableEscapeKeyDown>
      <form noValidate onSubmit={handleSubmit(submitForm)} style={{ minWidth: '300px' }}>
        <DialogTitle>{gapperToEdit?.symbol}</DialogTitle>
        <DialogContent sx={{ paddingTop: '20px !important' }}>
          <Grid2 container spacing={2}>
            <Grid2 xs={12}>
              <AppAutocomplete name="tags" label="Tags" control={control} tags={tags} />
            </Grid2>
          </Grid2>
        </DialogContent>

        <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button onClick={onCancel} variant="contained" color="gray" fullWidth>
              Cancel
            </Button>
            <Button variant="contained" type="submit" fullWidth>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default GappersFormDialog;

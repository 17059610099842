import {
  Box,
  Card,
  CardContent,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import CardHeader from 'components/shared/CardHeader';
import { HeaderCell, TableHeader } from 'components/table-header/tableHeader';
import { useSnackbar } from 'providers/SnackbarProvider';
import React, { useEffect, useState } from 'react';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import TagsFormDialog from './TagsFormDialog';
import './Tags.scss';
import ConfirmationDialog from 'components/shared/confirmation-dialog/ConfirmationDialog';
import { loadAccounts } from 'store/accountSlice';
import { deleteTag, loadTags, saveTag, selectTags } from 'store/tagsSlice';
import { Tag } from 'models/tag';

const Tags: React.FC = () => {
  const tags = useStoreSelector(selectTags);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
  const [tagToEdit, setTagToEdit] = useState<Tag | null>(null);
  const [tagToDelete, setTagToDelete] = useState<Tag | null>(null);
  const { showSnackbar } = useSnackbar();
  const dispatch = useStoreDispatch();
  const headerCells: readonly HeaderCell[] = [
    {
      id: 'name',
      disablePadding: false,
      label: 'Name',
      numeric: false
    },
    {
      id: 'id',
      disablePadding: false,
      label: 'ID',
      numeric: false
    }
  ];

  useEffect(() => {
    dispatch(loadTags());
  }, []);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCancel = () => {
    setDialogOpen(false);
    setTagToEdit(null);
  };

  const handleSaveAccount = (tag: Tag) => {
    setDialogOpen(false);
    setTagToEdit(null);
    dispatch(saveTag(tag))
      .unwrap()
      .then(() => {
        showSnackbar({ message: 'Tag saved successfully', type: 'success' });
      })
      .catch(() => {
        showSnackbar({ message: 'Failed to save tag', type: 'error' });
      });
  };

  const handleEdit = (tag: Tag) => {
    setTagToEdit(tag);
    setDialogOpen(true);
  };

  const handleDelete = (tag: Tag) => {
    setTagToDelete(tag);
    setConfirmationDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setTagToDelete(null);
    setConfirmationDialogOpen(false);
  };

  const handleDeleteConfirm = () => {
    if (tagToDelete) {
      dispatch(deleteTag(tagToDelete));
    }
    setConfirmationDialogOpen(false);
    setTagToDelete(null);
  };

  return (
    <React.Fragment>
      <Paper className="page-header" elevation={2}>
        <Typography variant="h6" sx={{ fontWeight: '500' }}>
          Tags
        </Typography>
      </Paper>

      <Box className="page-content profile">
        <Grid2 container>
          <Grid2 xs={8}>
            <Card>
              <CardHeader>
                <Typography variant="body1">Accounts</Typography>
                <IconButton onClick={handleOpenDialog}>
                  <AddIcon />
                </IconButton>
              </CardHeader>
              <CardContent>
                <TableContainer>
                  <Table size="medium">
                    <TableHeader headerCells={headerCells} hasActionColumn={true} />
                    <TableBody>
                      {tags &&
                        tags.map((tag) => {
                          return (
                            <TableRow key={tag.name}>
                              <TableCell>{tag.id}</TableCell>
                              <TableCell>{tag.name}</TableCell>
                              <TableCell align="right" style={{ width: '20%' }}>
                                <IconButton onClick={() => handleEdit(tag)} title="Edit">
                                  <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleDelete(tag)} title="Delete">
                                  <DeleteIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>
      </Box>

      <TagsFormDialog
        open={isDialogOpen}
        tagToEdit={tagToEdit}
        onSubmit={handleSaveAccount}
        onCancel={handleCancel}
      />

      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Delete Tag"
        text="Are you sure you want to deactivate this tag? The account will not be deleted, and you can always activate it again."
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      />
    </React.Fragment>
  );
};

export default Tags;

import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <div className="footer" style={{ zIndex: 9999 }}>
      <Link style={{ color: 'white' }} to={'/privacy'}>
        Privacy Policy
      </Link>
      |
      <Link style={{ color: 'white', marginRight: '10px' }} to={'/imprint'}>
        Imprint
      </Link>
    </div>
  );
};

export default Footer;

import React from 'react';
import Sidebar from 'components/sidebar/Sidebar';
import { Outlet } from 'react-router-dom';
import './MainLayout.scss';
import Header from 'components/header/Header';
import Footer from 'components/Footer/Footer';

const MainLayout: React.FC = () => {
  return (
    <div className="container">
      <Header></Header>
      <Sidebar />
      <div className="page">
        <Outlet></Outlet>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default MainLayout;

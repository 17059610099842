import { IconButton, Paper, Rating, TextField, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import {
  saveTicker,
  saveTickerSymbol,
  selectCurrentTicker,
  setEditingTicker,
  updateRating
} from 'store/tickersSlice';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import TextEditor from 'components/shared/text-editor/TextEditor';

const TickerNotes: React.FC = () => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const selectedTicker = useStoreSelector(selectCurrentTicker);
  const textFieldRef = useRef<HTMLInputElement>(null);
  const dispatch = useStoreDispatch();

  const handleCancelEdit = () => {
    setEditMode(false);
  };

  const handleSaveTicker = () => {
    if (textFieldRef.current && selectedTicker) {
      dispatch(saveTickerSymbol({ ...selectedTicker, symbol: textFieldRef.current.value }));
      setEditMode(false);
    }
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const handleSaveNote = (value: string) => {
    if (selectedTicker) {
      dispatch(saveTicker({ ...selectedTicker, note: value }));
    }
  };

  const handleEditingStarted = () => {
    dispatch(setEditingTicker(selectedTicker));
  };

  const handleRatingChanged = (event: any, value: number | null) => {
    if (selectedTicker) {
      dispatch(updateRating(value));
    }
  };

  return (
    <Paper className="main-content" sx={{ height: '95%', overflow: 'hidden' }}>
      {selectedTicker && (
        <div
          className="notebook-page-content"
          style={{ height: '100%', overflow: 'hidden', paddingBottom: '111px' }}>
          <div className="title" style={{ display: 'flex', alignItems: 'center' }}>
            {editMode ? (
              <div
                className="edit-title"
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <TextField
                  inputRef={textFieldRef}
                  className="title-field"
                  id="section-title"
                  label="Title"
                  variant="standard"
                  inputProps={{ style: { fontSize: 15, textTransform: 'uppercase' } }}
                  defaultValue={selectedTicker?.ticker}
                />

                <div className="buttons">
                  <IconButton aria-label="save" size="small" onClick={handleSaveTicker}>
                    <SaveIcon />
                  </IconButton>

                  <IconButton aria-label="cancel" size="small" onClick={handleCancelEdit}>
                    <CancelIcon />
                  </IconButton>
                </div>
              </div>
            ) : (
              <div onDoubleClick={toggleEditMode}>
                <Typography
                  variant="h5"
                  sx={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '10px'
                  }}>
                  {selectedTicker?.ticker}
                  <IconButton onClick={toggleEditMode}>
                    <EditIcon />
                  </IconButton>
                </Typography>
              </div>
            )}

            <Rating
              value={selectedTicker.rating}
              size="medium"
              onChange={handleRatingChanged}></Rating>
          </div>
          <div className="subtitle">
            <span>Created: {moment(selectedTicker?.createdAt).format('DD/MM/YYYY HH:mm')}</span>
            <span>
              Last Updated: {moment(selectedTicker?.updatedAt).format('DD/MM/YYYY HH:mm')}
            </span>
          </div>

          <div
            style={{ marginTop: '15px', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <TextEditor
              editorKey={selectedTicker?.id}
              notes={selectedTicker?.note}
              onSave={handleSaveNote}
              editingStarted={handleEditingStarted}
            />
          </div>
        </div>
      )}
    </Paper>
  );
};

export default TickerNotes;

import { BarChartModel } from 'models/barChartModel';
import { TabPanelProps } from 'models/props/tabPanelProp';
import React, { useEffect, useState } from 'react';
import statsService from 'services/statsService';
import TotalPnlChart from './TotalPnlChart';
import { selectCurrentAccount } from 'store/accountSlice';
import { useStoreSelector } from 'store/store';

const PriceVolumeTab: React.FC<TabPanelProps> = ({ value, index, ...other }) => {
  const [pnlByPriceRange, setPnlByPriceRange] = useState<BarChartModel[]>([]);
  const [tradesPnlByPriceRange, setTradesPnlByPriceRange] = useState<BarChartModel[]>([]);
  const account = useStoreSelector(selectCurrentAccount);

  useEffect(() => {
    statsService.pnlByPriceRange().then((response) => {
      const data = response.data.map((d) => {
        return { key: d.timePeriod, value: d.pnl };
      });
      const trades = response.data.map((d) => {
        return { key: d.timePeriod, value: d.numberOfTrades };
      });
      setPnlByPriceRange(data);
      setTradesPnlByPriceRange(trades);
    });
  }, [account]);

  return (
    <div
      className={value === index ? 'report-tab' : ''}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ paddingTop: '10px' }}>
      {value === index && (
        <React.Fragment>
          <div>
            {tradesPnlByPriceRange.length > 0 && (
              <TotalPnlChart
                data={tradesPnlByPriceRange}
                title="Trade Distribution by Price"
                color="#0197f6b3"
              />
            )}
          </div>
          <div>
            {pnlByPriceRange.length > 0 && (
              <TotalPnlChart data={pnlByPriceRange} title="Performance by Price" />
            )}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default PriceVolumeTab;

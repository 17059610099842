import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useStoreSelector } from 'store/store';
import { selectUser } from 'store/userSlice';

interface AuthContextType {
  isLoggedIn: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const user = useStoreSelector(selectUser);
  const [isLoggedIn, setIsLoggedIn] = useState(user !== undefined);

  useEffect(() => {
    if (!user) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  });

  return <AuthContext.Provider value={{ isLoggedIn }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

import axios, { AxiosResponse } from 'axios';
import { FilingDocument } from 'models/filingDocument';
import { Filing } from 'models/filings';
import { FilingsOverview } from 'models/filingsOverview';
import { Message } from 'models/message';
import { SecTicker } from 'models/secTickers';

class FilingsService {
  async loadFilingFromSec(url: string): Promise<AxiosResponse<string>> {
    const headers = {
      'User-Agent': 'farid.rahimi@outlook.de'
    };
    return await axios.get(url, { headers });
  }

  async loadTickers(filter: string): Promise<AxiosResponse<SecTicker[]>> {
    const queryParams = {
      filter: filter
    };

    return await axios.get(`${process.env.REACT_APP_API_URL}/filings/tickers`, {
      params: queryParams
    });
  }

  async loadMessages(ticker: string): Promise<AxiosResponse<Message[]>> {
    const queryParams = {
      ticker: ticker
    };

    return await axios.get(`${process.env.REACT_APP_API_URL}/filings/messages`, {
      params: queryParams
    });
  }

  async loadFilings(ticker: SecTicker): Promise<AxiosResponse<FilingsOverview>> {
    return await axios.get(`${process.env.REACT_APP_API_URL}/filings/tickers/${ticker.id}`);
  }

  async getFiling(ticker: SecTicker, filing: Filing): Promise<AxiosResponse<FilingDocument>> {
    const queryParams = {
      tickerId: ticker.id,
      form: filing.form,
      date: filing.filingDate,
      document: filing.primaryDocument,
      accessionNumber: filing.accessionNumber
    };

    return await axios.get(`${process.env.REACT_APP_API_URL}/filings/tickers/${ticker.id}/filing`, {
      params: queryParams
    });
  }

  async summarize(filingId: string): Promise<AxiosResponse<Message[]>> {
    const encodedFilingId = encodeURIComponent(filingId);
    return await axios.get(`${process.env.REACT_APP_API_URL}/filings/${encodedFilingId}/summarize`);
  }

  async summarize8K(filingId: string): Promise<AxiosResponse<Message[]>> {
    const encodedFilingId = encodeURIComponent(filingId);
    return await axios.get(
      `${process.env.REACT_APP_API_URL}/filings/${encodedFilingId}/summarize8K`
    );
  }
  async summarize10Bullets(filingId: string): Promise<AxiosResponse<Message[]>> {
    const encodedFilingId = encodeURIComponent(filingId);
    return await axios.get(
      `${process.env.REACT_APP_API_URL}/filings/${encodedFilingId}/summarize10Bullets`
    );
  }
  async summarizeS3Filing(filingId: string): Promise<AxiosResponse<Message[]>> {
    const encodedFilingId = encodeURIComponent(filingId);
    return await axios.get(
      `${process.env.REACT_APP_API_URL}/filings/${encodedFilingId}/summarizeS3`
    );
  }

  // async sendPrompt(prompt: string): Promise<AxiosResponse<FilingSection[]>> {
  // const payload = {
  //   prompt
  // };
  // const source = axios.CancelToken.source();

  // return await axios.post(`${process.env.REACT_APP_API_URL}/filings/tickers/prompt`, payload, {
  //   responseType: 'stream',
  //   cancelToken: source.token,
  //   onDownloadProgress: (progressEvent) => {
  //     const chunk = progressEvent.event.target.response;
  //     if (chunk) {
  //       setResponse((prevResponse) => prevResponse + chunk);
  //     }
  //   },
  // });
  // }
}

export default new FilingsService();

import axios, { AxiosResponse } from 'axios';
import { Notebook } from 'models/notebook';
import { NotebookPage } from 'models/notebook-page';
import { NotebookSection } from 'models/notebook-section';
import { Playbook } from 'models/playbook';
import { Stock } from 'models/stock';

class NoteBookService {
  async loadNotebook(symbol: string | null): Promise<AxiosResponse<Notebook>> {
    const queryParams = {
      symbol: symbol
    };
    return await axios.get(`${process.env.REACT_APP_API_URL}/notebook`, {
      params: queryParams
    });
  }

  async updateSection(section: NotebookSection): Promise<AxiosResponse<NotebookSection>> {
    return await axios.put(`${process.env.REACT_APP_API_URL}/notebook/section/${section.id}`, {
      section: section
    });
  }

  async createSection(): Promise<AxiosResponse<NotebookSection>> {
    return await axios.post(`${process.env.REACT_APP_API_URL}/notebook/section`);
  }

  async deleteSection(section: NotebookSection): Promise<AxiosResponse<void>> {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/notebook/section/${section.id}`);
  }

  async createPage(sectionId: number): Promise<AxiosResponse<NotebookPage>> {
    return await axios.post(`${process.env.REACT_APP_API_URL}/notebook/section/${sectionId}/page`);
  }

  async savePage(page: NotebookPage): Promise<AxiosResponse<NotebookPage>> {
    return await axios.put(`${process.env.REACT_APP_API_URL}/notebook/page/${page.id}`, {
      page: page
    });
  }

  async deletePage(pageId: number): Promise<AxiosResponse<void>> {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/notebook/page/${pageId}`);
  }

  async loadPages(sectionId: number): Promise<AxiosResponse<NotebookPage[]>> {
    return await axios.get(`${process.env.REACT_APP_API_URL}/notebook/section/${sectionId}/page`);
  }

  async saveStock(stock: Stock, pageId: number): Promise<AxiosResponse<Stock>> {
    if (stock.id) {
      return await axios.put(`${process.env.REACT_APP_API_URL}/notebook/page/${pageId}/stock`, {
        stock: stock
      });
    } else {
      return await axios.post(`${process.env.REACT_APP_API_URL}/notebook/page/${pageId}/stock`, {
        stock: stock
      });
    }
  }

  async deleteStock(stockId: number): Promise<AxiosResponse<void>> {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/notebook/page/stock/${stockId}`);
  }
}

export default new NoteBookService();

import { AxiosResponse } from 'axios';
import axios from '../config/axiosConfig';
import User from 'models/user';

class AuthService {
  login(email: string, password: string): Promise<AxiosResponse<User>> {
    const payload = { email, password };
    return axios.post(`${process.env.REACT_APP_API_URL}/login`, payload, {
      withCredentials: true
    });
  }

  logout(): Promise<AxiosResponse<User>> {
    return axios.post(`${process.env.REACT_APP_API_URL}/logout`);
  }

  getCurrentUser(): Promise<AxiosResponse<User>> {
    return axios.get(`${process.env.REACT_APP_API_URL}/currentUser`);
  }
}

export default new AuthService();

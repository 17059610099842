import { ThemeProvider } from '@mui/material';
import { RouterProvider } from 'react-router-dom';
import theme from './theme/Theme';
import './App.scss';
import { SnackbarProvider } from 'providers/SnackbarProvider';
import router from 'routes/Routes';
import 'react-datepicker/dist/react-datepicker.css';
import AuthProvider from 'providers/AuthProvider';
import { useStoreDispatch } from 'store/store';
import { loadCurrentUser } from 'store/userSlice';
import { loadAccounts } from 'store/accountSlice';
import { useEffect } from 'react';
import { loadTags } from 'store/tagsSlice';

function App() {
  const dispatch = useStoreDispatch();
  useEffect(() => {
    dispatch(loadCurrentUser());
    dispatch(loadAccounts());
    dispatch(loadTags());
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <SnackbarProvider>
          <RouterProvider router={router}></RouterProvider>
        </SnackbarProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;

import { Autocomplete, Box, CircularProgress, Paper, TextField, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React, { useEffect, useState } from 'react';
import {
  loadFilings,
  loadTickers,
  selectFilings,
  selectIsTickersLoading,
  setSelectedTicker
} from 'store/filingsSlice';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import './Filings.scss';
import FilingsTable from './FilingsTable';
import { SecTicker } from 'models/secTickers';

const Filings: React.FC = () => {
  const [filter, setFilter] = useState<string | undefined>(undefined);
  const [options, setOptions] = useState<SecTicker[]>([]);
  const [open, setOpen] = useState(false);
  const isLoading = useStoreSelector(selectIsTickersLoading);
  const dispatch = useStoreDispatch();
  const filings = useStoreSelector(selectFilings);

  useEffect(() => {
    if (filter) {
      dispatch(loadTickers({ filter: filter }))
        .unwrap()
        .then((tickers) => {
          setOptions(tickers);
        });
    }
  }, [filter]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleChangeTicker = (event: any, value: SecTicker | null) => {
    if (value) {
      dispatch(setSelectedTicker(value));
      dispatch(loadFilings({ ticker: value }));
    }
  };

  return (
    <React.Fragment>
      <Paper className="page-header filings-header" elevation={2}>
        <Autocomplete
          id="asynchronous-demo"
          sx={{ width: 300 }}
          getOptionLabel={(option) => option.ticker}
          options={options}
          loading={isLoading}
          isOptionEqualToValue={(option, value) => value === undefined || option?.id === value?.id}
          onChange={handleChangeTicker}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start !important' }}
              {...props}>
              <Typography variant="subtitle1">{option.ticker}</Typography>
              <Typography sx={{ fontSize: '12px' }}>{option.title}</Typography>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Tickers"
              variant="standard"
              onChange={(event) => setFilter(event.target.value)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />
      </Paper>
      <Box className="page-content filings">
        {filings && (
          <Grid2 container spacing={2}>
            <Grid2 xs={4}>
              <FilingsTable title="Chronological" filings={filings?.chronological}></FilingsTable>
            </Grid2>

            <Grid2 xs={4}>
              <FilingsTable title="Financials" filings={filings?.financials}></FilingsTable>
            </Grid2>

            <Grid2 xs={4}>
              <FilingsTable title="News" filings={filings?.news}></FilingsTable>
            </Grid2>

            <Grid2 xs={4}>
              <FilingsTable
                title="Prospectuses and Registrations"
                filings={filings?.prospectuses}></FilingsTable>
            </Grid2>

            <Grid2 xs={4}>
              <FilingsTable title="Proxies" filings={filings?.proxies}></FilingsTable>
            </Grid2>

            <Grid2 xs={4}>
              <FilingsTable title="Ownerships" filings={filings?.ownerships}></FilingsTable>
            </Grid2>

            <Grid2 xs={4}>
              <FilingsTable title="Other" filings={filings?.others}></FilingsTable>
            </Grid2>
          </Grid2>
        )}
      </Box>
    </React.Fragment>
  );
};

export default Filings;

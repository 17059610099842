import axios, { AxiosResponse } from 'axios';
import { Runner } from 'models/runner';

class RunnerService {
  async load(): Promise<AxiosResponse<Runner[]>> {
    return await axios.get(`${process.env.REACT_APP_API_URL}/runners`);
  }

  async save(setup: Runner): Promise<AxiosResponse<Runner>> {
    const payload = {
      setup
    };
    if (setup.id) {
      return await axios.put(`${process.env.REACT_APP_API_URL}/runners/${setup.id}`, payload);
    } else {
      return await axios.post(`${process.env.REACT_APP_API_URL}/runners`, payload);
    }
  }

  async delete(setup: Runner): Promise<AxiosResponse<void>> {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/runners/${setup.id}`);
  }
}

export default new RunnerService();

import { Divider, List, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import Currency from 'components/currency/currency';
import { Journal } from 'models/journal';
import { TradePerformance } from 'models/tradePerformance';
import React from 'react';
import { useStoreDispatch, useStoreSelector } from 'store/store';
import { selectCurrentJournal, selectJournals, setCurrentJournal } from 'store/tradingJournalSlice';

const JournalList: React.FC = () => {
  const dispatch = useStoreDispatch();
  const journals = useStoreSelector(selectJournals);
  const selectedJournal = useStoreSelector(selectCurrentJournal);

  const handleSelectJournal = (journal: Journal) => {
    dispatch(setCurrentJournal(journal));
  };

  return (
    <List className="sidebar-list">
      {journals &&
        journals.map((journal) => {
          const symbols = journal.journalEntries.map((entry) => entry.trade.symbol).join(', ');
          const tradePerformances = ([] as TradePerformance[]).concat(
            ...journal.journalEntries.map((entry) => entry.trade.tradePerformances)
          );
          const pnl = tradePerformances.reduce((acc, tradePerformance) => {
            return (acc += tradePerformance.netPnl);
          }, 0);
          return (
            <ListItemButton
              key={journal.date}
              onClick={() => handleSelectJournal(journal)}
              className={selectedJournal === journal ? 'selected' : ''}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                flexGrow: 0,
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
              }}>
              <ListItemText primary={journal.date} secondary={symbols}></ListItemText>
              <ListItemAvatar
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  flexGrow: 0
                }}
                className={`pnl ${pnl > 0 ? 'green' : 'red'}`}>
                <Currency price={pnl}></Currency>
              </ListItemAvatar>
            </ListItemButton>
          );
        })}
    </List>
  );
};

export default JournalList;

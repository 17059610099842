import React, { useRef } from 'react';
import { Line } from 'react-chartjs-2';
import { IconButton, Paper, Tooltip } from '@mui/material';
import { CumulativePnl } from 'models/cumulativePnl';
import { Chart as ChartJS } from 'chart.js';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';

interface AreaChartProps {
  data: CumulativePnl[];
  maintainAspectRatio?: boolean;
}

const AreaChart: React.FC<AreaChartProps> = ({ data, maintainAspectRatio = true }) => {
  const chartRef = useRef<ChartJS<'line', number[], unknown> | null>(null);
  const pnlValues = data.map((stat) => stat.pnl);
  const minPnl = Math.min(...pnlValues);
  const maxPnl = Math.max(...pnlValues);

  const chartData = {
    labels: ['0', ...data.map((stat) => stat.date)],
    datasets: [
      {
        fill: {
          target: 'origin',
          above: 'rgb(41 171 135 / 71%)',
          below: 'rgb(234 60 83 / 71%)'
        },
        data: [0, ...data.map((stat) => stat.pnl)]
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: maintainAspectRatio,
    layout: {
      padding: 10
    },
    animation: {
      duration: 100,
      easing: 'easeOutQuart' as const
    },
    scales: {
      y: {
        border: {
          display: false
        },
        ticks: {
          padding: 10
        },
        grid: {
          color: function (context: any) {
            if (context.tick.value === 0) {
              return 'black';
            }
            return ChartJS.defaults.borderColor as string;
          },
          lineWidth: 2,
          drawTicks: false
        }
      },
      x: {
        beginAtZero: true
      }
    },
    transitions: {
      zoom: {
        animation: {
          duration: 500,
          easing: 'easeOutCubic' as const
        }
      }
    },
    plugins: {
      legend: {
        position: 'top' as const,
        display: false
      },
      title: {
        display: true,
        text: 'Cumulative Net P&L'
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'xy' as const
        },
        zoom: {
          wheel: {
            enabled: true
          },
          pinch: {
            enabled: true
          },
          drag: {
            enabled: true,
            borderColor: 'rgb(54, 162, 235)',
            borderWidth: 1,
            backgroundColor: 'rgba(54, 162, 235, 0.3)',
            modifierKey: 'shift' as const
          },
          mode: 'x' as const
        }
      }
    }
  };

  const handleResetZoom = () => {
    if (chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  return (
    <Paper className="area-chart" elevation={3} sx={{ position: 'relative' }}>
      <Tooltip title="Reset Zoom">
        <IconButton onClick={handleResetZoom} sx={{ position: 'absolute', right: 0, top: 0 }}>
          <CenterFocusStrongIcon />
        </IconButton>
      </Tooltip>

      <Line ref={chartRef} options={options} data={chartData} />
    </Paper>
  );
};

export default AreaChart;

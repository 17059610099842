import axios, { AxiosResponse } from 'axios';
import Trade from 'models/trade';
import { TradesByMonth } from 'models/tradeByMonth';

class TradeService {
  async uploadTrades(file: File, accountId: number): Promise<AxiosResponse<Trade[]>> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('accountId', String(accountId));
    return await axios.post(`${process.env.REACT_APP_API_URL}/trade/upload`, formData);
  }

  async update(trade: Trade): Promise<AxiosResponse<Trade>> {
    return await axios.put(`${process.env.REACT_APP_API_URL}/trade`, trade);
  }

  async loadTrades(
    symbol?: string,
    sortBy?: string,
    sortOrder?: string
  ): Promise<AxiosResponse<Trade[]>> {
    const queryParams = {
      filter: symbol,
      sortBy: sortBy,
      sortOrder: sortOrder
    };
    return await axios.get(`${process.env.REACT_APP_API_URL}/trade`, {
      params: queryParams
    });
  }

  async loadTradeById(tradeId: number): Promise<AxiosResponse<Trade>> {
    return await axios.get(`${process.env.REACT_APP_API_URL}/trade/${tradeId}`);
  }

  async loadTradesByMonth(year: number, month: number): Promise<AxiosResponse<TradesByMonth[]>> {
    const queryParams = {
      month: month,
      year: year
    };
    return await axios.get(`${process.env.REACT_APP_API_URL}/trade/by-month`, {
      params: queryParams
    });
  }
}

export default new TradeService();

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Playbook } from 'models/playbook';
import { PlaybookItem } from 'models/playbookItem';
import playbookItemService from 'services/playbookItemService';
import playbookService from 'services/playbookService';

export interface PlaybookState {
  playbooks: Playbook[];
  currentPlaybook?: Playbook;
  currentPlaybookItem?: PlaybookItem;
}

const initialState: PlaybookState = {
  playbooks: [],
  currentPlaybook: undefined,
  currentPlaybookItem: undefined
};

export const loadPlaybooks = createAsyncThunk<Playbook[], { symbol: string | null }>(
  'playbook/load',
  async ({ symbol }) => {
    const response = await playbookService.loadPlaybooks(symbol);
    return response.data;
  }
);

export const savePlaybook = createAsyncThunk<Playbook, { playbook: Playbook }>(
  'playbook/save',
  async ({ playbook }) => {
    const response = await playbookService.save(playbook);
    return response.data;
  }
);

export const savePlaybookItem = createAsyncThunk<
  PlaybookItem,
  { item: PlaybookItem; playbookId: number }
>('playbookItem/save', async ({ item, playbookId }) => {
  const response = await playbookItemService.save(item, playbookId);
  return response.data;
});

export const deletePlaybookItem = createAsyncThunk<
  { deletedItemId: number },
  { item: PlaybookItem }
>('playbookItem/delete', async ({ item }) => {
  const response = await playbookItemService.deleteItem(item);
  if (response.status === 200) {
    return { deletedItemId: item.id! };
  }
  throw new Error('Failed to delete playbook item ' + item.id);
});

export const playbookSlice = createSlice({
  name: 'playbook',
  initialState,
  reducers: {
    setCurrentPlaybook: (state, action) => {
      state.currentPlaybook = action.payload;
    },
    setCurrentPlaybookItem: (state, action) => {
      state.currentPlaybookItem = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(savePlaybook.fulfilled, (state, action) => {
        state.playbooks.splice(0, 0, action.payload);
        state.currentPlaybook = action.payload;
      })
      .addCase(loadPlaybooks.fulfilled, (state, action) => {
        state.playbooks = action.payload;
        if (state.playbooks && state.playbooks.length > 0) {
          state.currentPlaybook = state.playbooks[0];
          if (state.currentPlaybook.items.length > 0) {
            state.currentPlaybookItem = state.currentPlaybook.items[0];
          }
        } else {
          state.currentPlaybook = undefined;
        }
      })
      .addCase(deletePlaybookItem.fulfilled, (state, action) => {
        const playbook = state.playbooks.find((c) => c.id === state.currentPlaybook?.id);
        if (playbook) {
          const itemIndex = playbook.items.findIndex((i) => i.id == action.payload.deletedItemId);
          if (itemIndex > -1) {
            playbook.items.splice(itemIndex, 1);
          }
        }

        if (state.currentPlaybook?.id === playbook?.id) {
          state.currentPlaybook = playbook;
        }
      })
      .addCase(savePlaybookItem.fulfilled, (state, action) => {
        const playbook = state.playbooks.find((c) => c.id === state.currentPlaybook?.id);
        if (playbook) {
          const itemIndex = playbook.items.findIndex((i) => i.id == action.payload.id);
          if (itemIndex > -1) {
            playbook.items.splice(itemIndex, 1, action.payload);
          } else {
            playbook.items.push(action.payload);
          }
        }

        if (state.currentPlaybook?.id === playbook?.id) {
          state.currentPlaybook = playbook;
        }
      });
  }
});

export const selectPlaybooks = (state: { playbook: PlaybookState }) => state.playbook.playbooks;
export const selectCurrentPlaybook = (state: { playbook: PlaybookState }) =>
  state.playbook.currentPlaybook;
export const selectCurrentPlaybookItem = (state: { playbook: PlaybookState }) =>
  state.playbook.currentPlaybookItem;

export const { setCurrentPlaybook, setCurrentPlaybookItem } = playbookSlice.actions;

export default playbookSlice.reducer;

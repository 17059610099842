import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  TextField
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Account } from 'models/account';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';

interface ProfileFormDialogProps {
  accountToEdit?: Account | null;
  open: boolean;
  onSubmit: (account: Account) => void;
  onCancel: () => void;
}

interface AccountForm {
  id: number | undefined;
  name: string;
  description: string | undefined;
  isActive: boolean;
  isDefault: boolean;
}

const ProfileFormDialog: React.FC<ProfileFormDialogProps> = ({
  accountToEdit,
  open,
  onSubmit,
  onCancel
}) => {
  const validationSchema = Yup.object().shape({
    id: Yup.number(),
    name: Yup.string().required('Name is required'),
    description: Yup.string(),
    isActive: Yup.boolean().required('Is active is required'),
    isDefault: Yup.boolean().required('Is default is required')
  });

  const textFieldStyle = {
    marginTop: '0px',
    marginBottom: '0px'
  };

  useEffect(() => {
    if (accountToEdit) {
      const defalutValues = {
        id: accountToEdit.id,
        name: accountToEdit.name,
        description: accountToEdit.description,
        isDefault: accountToEdit.isDefault,
        isActive: accountToEdit.isActive
      };
      reset(defalutValues);
    } else {
      resetForm();
    }
  }, [accountToEdit]);

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<AccountForm>({
    resolver: yupResolver(validationSchema)
  });

  const submitForm = (data: AccountForm) => {
    const item = { ...data } as Account;
    resetForm();
    onSubmit(item);
  };

  const resetForm = () => {
    reset({
      id: undefined,
      name: '',
      description: '',
      isActive: false,
      isDefault: false
    });
  };
  return (
    <Dialog open={open} className="profile-form" disableEscapeKeyDown>
      <form noValidate onSubmit={handleSubmit(submitForm)}>
        <DialogTitle>Account</DialogTitle>
        <DialogContent>
          <Grid2 container columnSpacing={2}>
            <Grid2 xs={12}>
              <TextField
                placeholder="Name"
                label="Name"
                type="text"
                variant="standard"
                fullWidth
                {...register('name')}
                error={errors.name ? true : false}
                helperText={errors.name && errors.name?.message}
                style={textFieldStyle}
              />
            </Grid2>
            <Grid2 xs={12}>
              <TextField
                placeholder="Description"
                label="Description"
                type="text"
                variant="standard"
                fullWidth
                {...register('description')}
                error={errors.description ? true : false}
                helperText={errors.description && errors.description?.message}
                style={textFieldStyle}
              />
            </Grid2>
            <Grid2 xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Controller
                      name="isDefault"
                      control={control}
                      render={({ field }) => <Switch {...field} checked={field.value} />}
                    />
                  }
                  label="Default"
                  labelPlacement="start"
                />
              </FormGroup>
            </Grid2>

            <Grid2 xs={12} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Controller
                      name="isActive"
                      control={control}
                      render={({ field }) => <Switch {...field} checked={field.value} />}
                    />
                  }
                  label="Active"
                  labelPlacement="start"
                />
              </FormGroup>
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              onClick={onCancel}
              variant="contained"
              color="gray"
              fullWidth
              sx={{ flexGrow: 1 }}>
              Cancel
            </Button>
            <Button variant="contained" type="submit" fullWidth sx={{ flexGrow: 1 }}>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ProfileFormDialog;

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import DatePicker from 'components/shared/datepicker/DatePicker';
import { Stock } from 'models/stock';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

interface StockDialogProps {
  itemToEdit?: Stock;
  open: boolean;
  onSubmit: (stock: Stock) => void;
  onCancel: () => void;
}

interface StockForm {
  id: number | undefined;
  symbol: string;
  date: Date;
}

const StockFormDialog: React.FC<StockDialogProps> = ({ itemToEdit, open, onSubmit, onCancel }) => {
  const validationSchema = Yup.object().shape({
    id: Yup.number(),
    symbol: Yup.string().required('Symbol is required'),
    date: Yup.date().required('Date is required')
  });

  const textFieldStyle = {
    marginTop: '0px',
    marginBottom: '0px'
  };

  const {
    reset,
    register,
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<StockForm>({
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    if (itemToEdit) {
      const newDefaultValues = {
        id: itemToEdit.id,
        symbol: itemToEdit.symbol,
        date: new Date(itemToEdit.date)
      };

      reset(newDefaultValues);
    } else {
      resetForm();
    }
  }, [itemToEdit, reset]);

  const resetForm = () => {
    reset({
      id: undefined,
      symbol: '',
      date: new Date()
    });
  };

  const submitForm = (data: StockForm) => {
    const item = { ...data } as Stock;
    resetForm();
    onSubmit(item);
  };

  return (
    <Dialog open={open} className="stock-form" disableEscapeKeyDown>
      <form noValidate onSubmit={handleSubmit(submitForm)}>
        <DialogTitle>Stock</DialogTitle>
        <DialogContent sx={{ paddingTop: '20px !important' }}>
          <Grid2 container spacing={2}>
            <Grid2 xs={12}>
              <TextField
                placeholder="AAPL"
                label="Symbol"
                type="text"
                variant="standard"
                fullWidth
                {...register('symbol')}
                error={errors.symbol ? true : false}
                helperText={errors.symbol && errors.symbol?.message}
                style={textFieldStyle}
                autoFocus
              />
            </Grid2>

            <Grid2 xs={12}>
              <DatePicker
                control={control}
                name="date"
                errors={errors}
                label="Select date"
                placeholder="Select date"
              />
            </Grid2>
          </Grid2>
        </DialogContent>

        <DialogActions style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button onClick={onCancel} variant="contained" color="gray" fullWidth>
              Cancel
            </Button>
            <Button variant="contained" type="submit" fullWidth>
              Save
            </Button>
          </Stack>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default StockFormDialog;
